import {
    ADDRESS_PENDING,
    ADDRESS_FULFILL,
    ADDRESS_REJECT,
    ADDRESS_CUSTOMER_UPDATE,
    ADDRESS_DELIVERY_CUSTOMER_UPDATE,
    ADDRESS_STATES_UPDATE,
    ADDRESS_CUSTOMER_INFO_UPDATE,
    SUBSCRIBE_NEWSLETTER,
    ADDRESS_CUSTOMER_UPDATE_CONTACT_PERMISSION,
    ADDRESS_SAME_AS_BILLING,
    CONTACT_OPTIONS
} from 'Shared/action-types';
import { defaultLanguageCode } from 'Shared/GlobalConstants';
import {
    PENDING,
    FULFILLED,
    REJECTED,
} from '../../reducer';

export const initialState = {
    status: FULFILLED,
    errors: [],
    subscribeForNewsletter: false,
    data: {
        countries: 'COUNTRIES' in window && COUNTRIES || null,
        states: [],
        selectedAddress: window.CHECKOUT_INFO
            && window.CHECKOUT_INFO.checkoutInfo.selectedAddress
            ||
            window.CHECKOUT_INFO
            && window.CHECKOUT_INFO.checkoutInfo.customerAddresses
            && window.CHECKOUT_INFO.checkoutInfo.customerAddresses.find(x => x.isPrimary)
            || {
                country: defaultLanguageCode,
                collectionIndex: '-1',
                state: '',
                city: '',
                name: '',
                buildingComplement: '',
                street: '',
                streetNumber: '',
                zipCode: '',
                email: window.CHECKOUT_INFO && window.CHECKOUT_INFO.checkoutInfo.customerInfo ? window.CHECKOUT_INFO.checkoutInfo.customerInfo.email : '',
                phone: window.CHECKOUT_INFO && window.CHECKOUT_INFO.checkoutInfo.customerInfo ? window.CHECKOUT_INFO.checkoutInfo.customerInfo.phone : '',
                alternativePhone: window.CHECKOUT_INFO && window.CHECKOUT_INFO.checkoutInfo.customerInfo ? window.CHECKOUT_INFO.checkoutInfo.customerInfo.alternativePhone : '',
                titleId: window.CHECKOUT_INFO && window.CHECKOUT_INFO.checkoutInfo.customerInfo ? window.CHECKOUT_INFO.checkoutInfo.customerInfo.titleId : '',
            },
        deliveryAddress: window.CHECKOUT_INFO
            && window.CHECKOUT_INFO.checkoutInfo.deliveryAddress
            && Object.assign({}, window.CHECKOUT_INFO.checkoutInfo.deliveryAddress,{
                name: 'Delivery address'
            })
            ||
            window.CHECKOUT_INFO
            && window.CHECKOUT_INFO.checkoutInfo.customerAddresses
            && window.CHECKOUT_INFO.checkoutInfo.customerAddresses.find(x => x.isPrimary)
            && Object.assign({}, window.CHECKOUT_INFO.checkoutInfo.customerAddresses.find(x => x.isPrimary),{
                name: 'Delivery address'
            })
            || {
                country: defaultLanguageCode,
                collectionIndex: '-1',
                state: '',
                city: '',
                name: '',
                buildingComplement: '',
                street: '',
                streetNumber: '',
                zipCode: '',
                email: window.CHECKOUT_INFO && window.CHECKOUT_INFO.checkoutInfo.customerInfo ? window.CHECKOUT_INFO.checkoutInfo.customerInfo.email : '',
                phone: window.CHECKOUT_INFO && window.CHECKOUT_INFO.checkoutInfo.customerInfo ? window.CHECKOUT_INFO.checkoutInfo.customerInfo.phone : '',
                alternativePhone: window.CHECKOUT_INFO && window.CHECKOUT_INFO.checkoutInfo.customerInfo ? window.CHECKOUT_INFO.checkoutInfo.customerInfo.alternativePhone : '',
                titleId: window.CHECKOUT_INFO && window.CHECKOUT_INFO.checkoutInfo.customerInfo ? window.CHECKOUT_INFO.checkoutInfo.customerInfo.titleId : '',
            },
        deliverySameAsBilling:  window.CHECKOUT_INFO
            && window.CHECKOUT_INFO.checkoutInfo.deliverySameAsBilling,
        customAddresses: window.CHECKOUT_INFO
            && window.CHECKOUT_INFO.checkoutInfo.customerAddresses
            || [],
        customerInfo: window.CHECKOUT_INFO
            && window.CHECKOUT_INFO.checkoutInfo.customerInfo
            || {
                firstName: '',
                lastName: '',
                newsletter: '',
                email: '',
                phone: '',
                alternativePhone: '',
                titleId: ''
            },
        availableTitles: window.CHECKOUT_INFO
            && window.CHECKOUT_INFO.checkoutInfo.availableTitles
            || [],
        contactPermission: window.CHECKOUT_INFO
            && window.CHECKOUT_INFO.checkoutInfo.contactPermission
            || [],
        contactOptions: window.CHECKOUT_INFO
            && window.CHECKOUT_INFO.checkoutInfo.contactOptions
            || '',
        shareMyData:  window.CHECKOUT_INFO
            && window.CHECKOUT_INFO.checkoutInfo.shareMyData
            || false
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case ADDRESS_PENDING:
            return Object.assign({}, state, {
                status: PENDING,
            });
        case ADDRESS_FULFILL:
            return Object.assign({}, state, {
                status: FULFILLED,
            });
        case ADDRESS_REJECT:
            return Object.assign({}, state, {
                status: REJECTED,
                errors: !action.error.errors ? [...action.error] : [...action.error.errors],
            });
        case ADDRESS_CUSTOMER_UPDATE:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {
                    selectedAddress: Object.assign({}, state.data.selectedAddress, action.data),
                }),
            });
        case ADDRESS_DELIVERY_CUSTOMER_UPDATE:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {
                    deliveryAddress: Object.assign({}, state.data.deliveryAddress, action.data),
                }),
            });
        case ADDRESS_CUSTOMER_INFO_UPDATE:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {
                    customerInfo: Object.assign({}, state.data.customerInfo, action.data),
                }),
            });
        case ADDRESS_STATES_UPDATE:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {
                    states: action.data.states,
                }),
            });
        case SUBSCRIBE_NEWSLETTER:
            return Object.assign({}, state, {
                subscribeForNewsletter: action.isChecked,
            });
        case ADDRESS_SAME_AS_BILLING:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {
                    deliverySameAsBilling: action.data,
                }),
            });
        case ADDRESS_CUSTOMER_UPDATE_CONTACT_PERMISSION:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {
                    contactPermission: action.data,
                }),
            });
        case CONTACT_OPTIONS:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {
                    contactOptions: action.data,
                }),
            });
        default:
            return state;
    }
}
