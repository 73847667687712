import { matches } from './dom';

const captureEvents = [
    'focus',
    'blur',
];

export function on(event, target, callback) {
    if (arguments.length === 2) { // eslint-disable-line no-magic-numbers
        [target, callback] = [callback, target];
    }

    const handler = e => {
        const el = e.target;
        if (typeof target !== 'string' || matches(el, target)) {
            callback(e);
        }
    };

    document.addEventListener(event, handler, captureEvents.indexOf(event) !== -1);

    // Returns a function which removes the event listener
    return () => document.removeEventListener(event, handler);
}

export function one(event, target, callback) {
    if (arguments.length === 2) { // eslint-disable-line no-magic-numbers
        [target, callback] = [callback, target];
    }

    const unsubscribe = on(event, target, e => {
        unsubscribe();
        callback(e);
    });

    return unsubscribe;
}
