import {
    REGISTER_PENDING,
    REGISTER_FULFILL,
    REGISTER_REJECT,
} from 'Shared/action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

const initialState = {
    status: FULFILLED,
    message: '',
    selectedAddress: {},
};

export default function register(state = initialState, action) {
    switch (action.type) {
    case REGISTER_PENDING:
        return Object.assign({}, state, {
            status: PENDING,
        });
    case REGISTER_FULFILL:
        return Object.assign({}, state, {
            status: FULFILLED,
        });
    case REGISTER_REJECT:
        return Object.assign({}, state, {
            status: REJECTED,
            message: action.data
        });
    default:
        return state;
    }
}
