import { postJson } from '../Shared/server.js';

export function fetchMarket(url, value) {
    postJson(url, { marketId: value }).then(data => {
        if (data.returnUrl) {
            document.location = data.returnUrl;
        }
    });
}

export function fetchLanguage(url, value) {
    postJson(url, { language: value }).then(data => {
        if (data.returnUrl) {
            document.location = data.returnUrl;
        }
    });
}

export function fetchCurrency(url, value) {
    // Unhandled
    postJson(url, { currencyCode: value });
}
