import * as actionTypes from './action-types';
import * as localcartActions from 'Cart/Browser/LocalCart/action-types';

export function onUpdatedArea(area) {
    return (dispatch) => {
        dispatch({ type: localcartActions.UPDATE_AREA, area: area });
    };
}

export const addItem = (code, quantity, relatedTo, context, price) => {
    return {
        type: actionTypes.LOCALCART_ADD_ITEM,
        data: {
            code,
            quantity,
            relatedTo,
            context,
            price,
        },
    };
};

export const removeItem = (code, relatedTo) => {
    return {
        type: actionTypes.LOCALCART_REMOVE_ITEM,
        data: {
            code,
            relatedTo,
        },
    };
};
export const updateItem = (code, quantity, relatedTo, context, price) => {
    return {
        type: actionTypes.LOCALCART_UPDATE_ITEM,
        data: {
            code,
            quantity,
            relatedTo,
            context,
            price,
        },
    };
};


export const setItemQuantity = (code, quantity, attributes) => {
    const action = {
        type: actionTypes.SET_ITEM_QUANTITY,
        data: { code, quantity, attributes },
    };

    if (attributes) {
        action.attributes = attributes;
    }

    return action;
};

export const emptyItems = () => {
    return {
        type: actionTypes.LOCALCART_EMPTY,
    };
};
