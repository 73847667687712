import React from 'react';
import { translate } from 'Shared/translate';
import { calculateArea } from 'Shared/customfunctions';
import { EnumCatType, Measurements } from 'Shared/CategoryType';

export default ({ item }) => (
    <React.Fragment>
        {item.color && <div><label>{translate('/Cart/Attributes/ColorShade')}:</label><span>{item.color}</span></div>}
        {item.size &&
            <div><label>{translate('/Cart/Attributes/Size')} :
            </label><span>{item.size}&nbsp;x&nbsp;{item.quantity}m</span> = 
            {calculateArea( Number(item.size.replace(/[^0-9\.]+/g,"")), item.quantity )} <span dangerouslySetInnerHTML={{__html: Measurements[EnumCatType.Carpets]}} /></div>}
    </React.Fragment>
);
