import { DISPLAYMENU_UPDATE } from '../action-types';

const initialState = false;

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case DISPLAYMENU_UPDATE:
        return action.display;
    default:
        return state;
    }
}
