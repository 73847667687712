import { postJson } from '../Shared/server';

export default function specificRequestAction(actionTypes) {
    
    const { pending, update, fulfill, reject } = actionTypes;
    
    let queue = [];

    return (url, body, get = d => d) => {
        return dispatch => {
            if (pending) {
                dispatch({ type: pending });
            }

            const promise = postJson(url, body).then(get).then(data => {
                if (update && data && data.shoppingCart) {
                    dispatch({
                        type: update,
                        data: data.shoppingCart,
                    });
                }
                return data.shoppingCart;
            });

            const currentQueue = [...queue, promise];
            queue = currentQueue;

            Promise.all(queue).then(
                () => {
                    if (queue === currentQueue) {
                        queue.length = 0; // Empty queue
                        if (fulfill) {
                            dispatch({ type: fulfill });
                        }
                    }

                },
                error => {
                    if (queue === currentQueue) {
                        queue.length = 0; // Empty queue
                        if (reject) {
                            dispatch({ type: reject, error });
                        }
                    }
                },
            );

            return promise;
        };
    };
}
