import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './lowstocknotification.scss';

export const LowStockNotification = () => (
    <div className={styles.infoBlock}>
        <div className={styles.lowstockWrapper}>
            <div className={styles.lowstock}>
                <div className={styles.stocklabel}>{translate('/Checkout/CartItemList/LowStock')}</div>
                <div className={styles.stocktext}>
                    {translate('/Checkout/CartItemList/LowStockText')}<b>{translate('/Shared/Number')}</b>&nbsp;{translate('/Checkout/CartItemList/LowStockAvailability')}
                </div> 
            </div>
        </div>
    </div>
);
