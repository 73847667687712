import {
    DELIVERY_PENDING,
    DELIVERY_UPDATE,
    DELIVERY_FULFILL,
    DELIVERY_REJECT,
} from 'Shared/action-types';
import genericRequestAction from 'Shared/generic-request-action';
import { request as cartRequest } from 'Cart/Browser/actions';
import { deliveryOptionRequest } from 'Cart/Browser/actions';
import { initialState } from './reducer';
import { DELIVERY_SHIPPING, DELIVERY_PICKUP } from './index';

const request = genericRequestAction({
    pending: DELIVERY_PENDING,
    update: DELIVERY_UPDATE,
    fulfill: DELIVERY_FULFILL,
    reject: DELIVERY_REJECT,
});

export function updateDeliveryPreference(preference) {
    return dispatch => {
        const { modeId, storeId } = initialState.data;

        dispatch({
            type: DELIVERY_UPDATE,
            data: {
                preference,
                modeId,
                storeId,
                errorMessage: '',
            },
        });
    };
}

export function updateDeliveryPickupOnly(modeId) {
    return dispatch => {
        dispatch({
            type: DELIVERY_UPDATE,
            data: {
                modeId,
                preference: DELIVERY_PICKUP,
            },
        });
    };
}

function updateDeliveryOption(params) {
    return dispatch => {
        const {
            storeId = initialState.data.storeId,
            modeId = initialState.data.modeId,
            preference = initialState.data.preference,
        } = params;

        dispatch({
            type: DELIVERY_UPDATE,
            data: {
                modeId,
                storeId,
                preference,
            },
        });

        return dispatch(deliveryOptionRequest('/Checkout/SetOrderDeliveryOption', params.option));
    };
}

export function setShippingOption(modeId) {
    return (dispatch, getState) => {        
        let deliveryItemCodeShipping = null;
        let modeIdGetState = JSON.parse(localStorage.getItem('_modeIdState'));
        if (modeIdGetState != null && modeId == null) {
            modeId = modeIdGetState;
        };
        let shippingGetState = JSON.parse(localStorage.getItem('_shippingOptionsState'));
        if (shippingGetState != null && modeId != null && shippingGetState.find(x => x.code === modeId) != null) {
            deliveryItemCodeShipping = shippingGetState.find(x => x.code === modeId).deliveryItemCode;           
        };
        if (deliveryItemCodeShipping == null &&  modeId != "") {
            let shippingState = getState().checkout.delivery.data.shippingOptions.find(x => x.code === modeId);
            deliveryItemCodeShipping = shippingState != null ? shippingState.deliveryItemCode : null;
        };
        const deliveryOption = {
            customAddress: getState().checkout.address.data.deliverySameAsBilling ?
                getState().cart.data.isSampleOnly ? getState().checkout.address.data.deliveryAddress :
                    getState().checkout.address.data.selectedAddress :
                getState().checkout.address.data.deliveryAddress,
            deliveryModeId: modeId, // Shipping method OR store id
            deliveryPreferenceId: DELIVERY_SHIPPING, // The selected delivery preference 
            deliveryItemCode: deliveryItemCodeShipping
        };
        return dispatch(
            updateDeliveryOption({
                option: deliveryOption,
                modeId,
                preference: DELIVERY_SHIPPING,
            })
        );
    };
}

export function resetDeliveryOptions() {
    return (dispatch) => {

        dispatch({
            type: DELIVERY_UPDATE,
            data: {
                shippingOptions: [],
                storeId: null,
                modeId: null,
            },
        });
    };
}

export function setPickUpStore(orgUnitNumber) {
    return (dispatch, getState) => {
        let pickUpStoresOption = getState().checkout.delivery.data.shippingOptions.find((o) => o.hasStores);
        let shippingOptions = JSON.parse(localStorage.getItem('_shippingOptionsState'));
        if(shippingOptions && !pickUpStoresOption){
            pickUpStoresOption = shippingOptions.find((o) => o.hasStores);
        }
        if(pickUpStoresOption){
            const selectedStore = getState().checkout.delivery.data.pickUpStores.find((o) => o.orgUnitNumber === orgUnitNumber);
            if (selectedStore) {
                selectedStore.storeId = selectedStore.inventoryLocationId;
            }
    
            const deliveryOption = {
                storeAddress: selectedStore,
                deliveryModeText: 'Customer pickup',
                deliveryModeId: pickUpStoresOption.code, // Shipping method OR store id
                deliveryPreferenceId: DELIVERY_PICKUP, // The selected delivery preference
                deliveryItemCode : pickUpStoresOption.deliveryItemCode
            };
    
            return dispatch(
                updateDeliveryOption({
                    option: deliveryOption,
                    modeId: pickUpStoresOption.code,
                    storeId: orgUnitNumber,
                    preference: DELIVERY_PICKUP,
                })
            );
        }
        
    };
}

export function setDeliveryOption() {
    return (dispatch, getState) => {
        const deliveryState = getState().checkout.delivery.data;
        if (deliveryState.preference === DELIVERY_SHIPPING) {
            return dispatch(setShippingOption(deliveryState.modeId));
        } else if (deliveryState.preference === DELIVERY_PICKUP) {
            return dispatch(setPickUpStore(deliveryState.storeId));
        }
    };
}

export function fetchShippingOptions(address) {
    return (dispatch, getState) => {
        dispatch(
            request(
                '/Checkout/GetOrderDeliveryOptionsForShipping',
                address,
                data => ({ shippingOptions: data.deliveryOptions, isQuote : data.isQuote, errorMessage : data.errorMessage, customDisplayMessages: data.customDisplayMessages })
            )
        ).then(r => {
            getState().checkout.delivery.data.errorMessage = r.errorMessage;
            getState().checkout.delivery.data.customDisplayMessages = r.customDisplayMessages;
            const selectedId = getState().checkout.delivery.data.modeId;
            if(r.shippingOptions && r.shippingOptions.length == 1 && selectedId != r.shippingOptions[0]){
                if(r.shippingOptions[0].hasStores){
                    dispatch(setPickUpStore(r.shippingOptions[0].storeId));
                } else
                {
                    dispatch(setShippingOption(r.shippingOptions[0].code));
                }
            }
            else if (selectedId) {
                const currentOption = getState().checkout.delivery.data.shippingOptions.find(o => o.code === selectedId);
                const newOption = r.shippingOptions.find(o => o.code === selectedId);
                const isSameLabel = newOption && currentOption.description === newOption.description;
                dispatch(setShippingOption(isSameLabel ? newOption.code : r.shippingOptions[0].code));
            }
        }).catch(() => {
            dispatch({
                type: DELIVERY_UPDATE,
                data: {
                    shippingOptions: [],
                },
            });
        });
    };
}

export function fetchPickUpStores(address) {
    return (dispatch, getState) => {
        dispatch(
            request('/Checkout/GetStores', address, data => ({ pickUpStores: data }))
        ).then(r => {
            const deliveryState = getState().checkout.delivery.data;
            const selectedId = deliveryState.storeId;
            if (selectedId) {
                const currentOption = deliveryState.pickUpStores.find(o => o.inventoryLocationId === selectedId);
                const newOption = r.pickUpStores.find(o => o.inventoryLocationId === selectedId);
                const isSameLabel = newOption && currentOption.orgUnitName === newOption.orgUnitName;
                dispatch(
                    setPickUpStore(isSameLabel ? newOption.inventoryLocationId : r.pickUpStores[0].inventoryLocationId)
                );
            }
        });
    };
}

export function fetchDeliveryOptions(address) {
    return dispatch => {
        if (!address.zipCode) {
            dispatch({
                type: DELIVERY_UPDATE,
                data: {
                    shippingOptions: [],
                },
            });
        } else {
            dispatch(fetchShippingOptions(address));
            dispatch(fetchPickUpStores(address));
        }
    };
}

export function setErrorMessage(errorMessage){
    return dispatch => {
        dispatch({
            type: DELIVERY_UPDATE, 
            data: {
                errorMessage
            }
        });
    }
}