import cloneDeep from 'lodash/cloneDeep';
import QuantityCalculatorFactory from './Services/QuantityCalculatorFactory';

import {
    LOCALCART_ADD_ITEM,
    LOCALCART_REMOVE_ITEM,
    LOCALCART_UPDATE_ITEM,
    LOCALCART_EMPTY,
    SET_ITEM_QUANTITY,
    REMOVE_ITEMS,
    UPDATE_AREA
} from './action-types';

export const initialState = {
    items: [],
    addToBasketEnabled: false,
    area: 0
};

export default (state = initialState, action) => {
    
    const clone = cloneDeep;

    const findItemByCode = (itemCode, clonedState) => clonedState.items.find((item) => item.code === itemCode);

    const getItemsRelatedTo = (itemCode, clonedState) => clonedState.items.filter((item) => !!item.attributes && !!item.attributes.relatedTo && item.attributes.relatedTo === itemCode);

    const itemNotFound = (itemCode, clonedState) => {
        const item = findItemByCode(itemCode, clonedState);
        return !!!item;
    };
    

    switch (action.type) {
        case LOCALCART_ADD_ITEM: {
    
            const clonedState = clone(state);

            const { code, quantity, relatedTo, context, price } = action.data;

            const isMainItem = !!!relatedTo;

            if (isMainItem) {

                const cartItem = findItemByCode(code, clonedState);
                
                if (cartItem) {
                    cartItem.quantity += 1;
                } else {
                    clonedState.items.push({ code, quantity, price });
                }
                if (Number(quantity) <= 0) {
                    clonedState.addToBasketEnabled = false;
                } else {
                    clonedState.addToBasketEnabled = true;
                }
            } else {

                const mainItemExists = findItemByCode(relatedTo, clonedState);

                if (!mainItemExists) {
                    clonedState.items.push({ code: relatedTo });
                }

                // REFACTOR Possible bug - no support for adding more than once?
                const relatedItem = findItemByCode(code, clonedState);

                if (!relatedItem) {
                    clonedState.items.push({ code, quantity, relatedTo, context, price });
                }
            }
            return clonedState;
        }

        case LOCALCART_REMOVE_ITEM: {

            const code = action.data.code;
            const clonedState = clone(state);

            const index = clonedState.items.findIndex((item) => {
                return item.code === code;
            });

            if (index !== -1) {
                clonedState.items.splice(index, 1);
            }

            return clonedState;
        }

        case REMOVE_ITEMS: {

            const clonedState = clone(state);
            const localCartItems = clonedState.items;

            const itemsToRemoveFromLocalCart = action.items || [];

            itemsToRemoveFromLocalCart.filter((itemCode) => {

                const clonedStateItemIndex = localCartItems.findIndex((item) => {
                    return item.code === itemCode;
                });

                const itemFound = clonedStateItemIndex > -1;

                if (itemFound) {
                    localCartItems.splice(clonedStateItemIndex, 1);
                }
            });

            return clonedState;
        }

        case LOCALCART_UPDATE_ITEM: 
            return {
                ...state,
                items: state.items.map(item => {
                    if(item.code === action.data.code || (!item.relatedTo && !action.data.relatedTo)) {
                        return {
                            ...item,
                            code: action.data.code,
                            quantity: action.data.quantity,
                            relatedTo: action.data.relatedTo,
                            context: action.data.context,
                            price: action.data.price,
                        }
                    } else {
                        if(!action.data.relatedTo) {
                            return {
                                ...item,
                                relatedTo: action.data.code
                            }
                        } else {
                            return item;
                        }
                    }
                }),
                ...(!action.data.relatedTo ?
                    (Number(action.data.quantity) <= 0) ? {addToBasketEnabled : false} : {addToBasketEnabled : true} :
                    {}
                ),
            }
    
        case LOCALCART_EMPTY: 
            return initialState;

        case SET_ITEM_QUANTITY: {

            const clonedState = clone(state);

            const quantity = Number(action.data.quantity);

            if (quantity < 0) return clonedState;

            const isRelatedItem = !!action.data.attributes && !!action.data.attributes.relatedTo;

            if (isRelatedItem) {

                const mainItemCode = action.data.attributes.relatedTo;

                const mainItemNotFound = itemNotFound(mainItemCode, clonedState);

                if (mainItemNotFound) return clonedState;

                const relatedItem = findItemByCode(itemCode, clonedState);

                const relatedItemNotFound = !!!relatedItem;

                if (relatedItemNotFound) return clonedState;

                relatedItem.quantity = quantity;

            } else {

                let mainItem = findItemByCode(action.data.code, clonedState);

                const mainItemFound = !!mainItem;

                if (mainItemFound) {

                    mainItem.quantity = quantity;

                    if (action.data.attributes) {
                        mainItem.attributes = action.data.attributes;
                    }

                    const quantityCalculators = {
                        'UNDERLAY': QuantityCalculatorFactory.createInstance('UNDERLAY'),
                        'GRIPPER': QuantityCalculatorFactory.createInstance('GRIPPER'),
                    };

                    const relatedItems = getItemsRelatedTo(mainItem.code, clonedState);

                    relatedItems.forEach((relatedItem) => {

                        const requiresQuantityCalculation = !!quantityCalculators[relatedItem.attributes.relationType];
                        const widthDimensionNotSpecified = !!!mainItem.attributes.dimensions || !!!mainItem.attributes.dimensions.width;

                        if (requiresQuantityCalculation) {

                            if (widthDimensionNotSpecified) {
                                throw `width dimension not specified for ${relatedItem.code}`;
                            }

                            const calculator = quantityCalculators[relatedItem.attributes.relationType];

                            const length = mainItem.quantity;
                            const width = mainItem.attributes.dimensions.width;

                            relatedItem.quantity = calculator.calculate(length, width);
                        }
                    });
                } else {
                    mainItem = {
                        code: action.data.code,
                        quantity,
                        attributes: action.data.attributes,
                    };
                }

                clonedState.items.push(mainItem);
            }

            return clonedState;
        }
        case UPDATE_AREA:{
            return Object.assign({}, state, {
                area: action.area,
            });
        }
        default:
            return state;
    }
};
