export default class QuantityCalculatorFactory {

    static createInstance(relationshipType) {

        switch (relationshipType) {
            case 'UNDERLAY':
                return new AreaQuantityCalculator();
            case 'GRIPPER':
                return new CircumferenceQuantityCalculator();
            default:
                throw `Unsupported relationship type '${relationshipType}'`;
        }
    }
}

class QuantityCalculator {
    calculate() {
        throw new Error('Not implemented');
    }
}

class CircumferenceQuantityCalculator extends QuantityCalculator {
    calculate(length, width) {
        return length * 2 + width * 2;
    }
}

class AreaQuantityCalculator extends QuantityCalculator {
    calculate(length, width) {
        return length * width;
    }
}