import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import classnames from 'classnames';
import { Icon } from '../../Shared/icon';
import * as styles from './base.scss';

Modal.setAppElement('#app-container');

const ReactModal = ({ isOpen, toggleModal, fullScreen, isRequestCallback, customStyles: userStyles, hideCloseButton, isLogin, children }) => {
    // Custom styles defined within the component
    const customStyles = {
        overlay: {
            zIndex: '4',
            background: 'rgba(000,000,000,0.2)',
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            borderRadius: 0,
            border: 'none',
            padding: '0',
            width: fullScreen ? '100vw' : 'auto',
            height: fullScreen ? '100vh' : 'auto',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            style={!isRequestCallback ? customStyles : userStyles}
        >
            {children}
            {!hideCloseButton &&
                <button aria-label="Modal"
                    className={classnames(
                        styles.closeButton, {
                        [styles.requestCallback]: isRequestCallback,
                        [styles.login]: isLogin
                    }
                    )}
                    type="button"
                    onClick={toggleModal}
                >
                    <Icon
                        size={'md-24'}
                        className={'ion-android-close'}
                    />
                </button>
            }
        </Modal>
    );
}

export default ReactModal;