import React from 'react';
import ReactModal from 'Shared/Modal';
import * as styles from './genericErrorModal.scss';
import { translate } from 'Shared/translate';

const GenericErrorModal = ({ isOpen, hideGenericError }) => {

    return (
        <ReactModal
            isOpen={isOpen}
            toggleModal={hideGenericError}
        >
            <div className={styles.genericErrorModal}>
                {translate('/GenericErrorModal/CommonError')}
            </div>
        </ReactModal>
    );
}

export default GenericErrorModal;