import { connect } from 'react-redux';
import { toggleQuickSearch, reset } from 'Search/actions';
import { PENDING } from '../../reducer';
import {
    update,
    removeItem,
    updateCartInfo,
    toggleMiniCart,
    getCartItems,
} from '../../actions';
import {
    toggleExpandSortArea,
} from '../../../../Category/Products/SortArea/actions';
import { toggleOverlay } from 'Shared/Overlay/actions';
import MiniCart from './MiniCart';

export default connect(
    state => ({
        status: state.cart.status,
        cart: state.cart.data,
        isPending: state.cart.status === PENDING,
        breakpoint: state.breakpoint,
        miniCartVisible: state.cart.miniCartVisible,
        searchVisible: state.searchResults.show,
        isAuthenticated: state.profile.isAuthenticated,
    }),
    dispatch => ({
        update: (...args) => dispatch(update(...args)),
        remove: (...args) => dispatch(removeItem(...args)),
        toggleQuickSearch: (show) => dispatch(toggleQuickSearch(show)),
        toggleMiniCart: (displayMiniCart) => dispatch(toggleMiniCart(displayMiniCart)),
        resetQuickSearch: () => dispatch(reset()),
        updateCartInfo: (data) => dispatch(updateCartInfo(data)),
        toggleExpandSortArea: (...args) => dispatch(toggleExpandSortArea(...args)),
        toggleOverlay: (display) => dispatch(toggleOverlay(display)),
        getCartItems: () => dispatch(getCartItems()),
    })
)(MiniCart);
