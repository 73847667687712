import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from '../../RelatedProducts.scss';
import { FITTING_SECTION_ID, FITTINGADMIN_SECTION_ID } from '../Fitting'
import { UPLIFT_SECTION_ID } from '../Uplift';
import { priceFormat } from 'Shared/GlobalConstants';
import Section from '../../../Section/index';

// Following component contains - Fitting section & Uplift section
export default function ServiceSection(props) {

    const prevProps = useRef(props);
    useEffect(() => {
        if (props.products && (prevProps.current.calculatedArea != props.calculatedArea)) {
            props.products.forEach(product => {
                props.onQuantityChange(product.code, props.quantity, product.price, product.type);
                if (props.selectedItems && props.products && containsAny(props.selectedItems, props.products)) {
                    deselectMultipleItems(props.products, props.onItemDeselect);
                    selectMultipleItems(props.products, props.calculatedArea, props.onItemSelect, props.sectionId);
                }
            });
        }
        prevProps.current = props;
    }, [props])


    const selectMultipleItems = (products, quantity, onItemSelect, sectionId) => {
        switch (sectionId) {
            case FITTING_SECTION_ID:
                let fittingItem = products.find(x => x.type == 'fitting');
                let fittingMinSku = products.find(x => x.type == 'fittingminsku');
                const comparisonPrice = fittingItem ? parseFloat(fittingItem.nowPrice * quantity) : 0;
                const fittingMinPrice = fittingMinSku ? parseFloat(fittingMinSku.nowPrice) : 0;

                let fittingAdminItem = products.find(x => x.type == 'fittingadmin');
                let fittingAdminMinSku = products.find(x => x.type == 'fittingadminminsku');
                const fittingAdminComparisonPrice = fittingAdminItem ? parseFloat(fittingAdminItem.price * quantity) : 0;
                const fittingAdminMinPrice = fittingAdminMinSku ? parseFloat(fittingAdminMinSku.price) : 0;

                //Fitting fee - payable to 3rd party
                if (comparisonPrice < fittingMinPrice) {
                    onItemSelect(fittingMinSku.code, 1, fittingMinPrice, fittingMinSku.type);
                }
                else {
                    onItemSelect(fittingItem.code, quantity, fittingItem.nowPrice, fittingItem.type);
                }

                //Fitting admin/arrangement fee
                if (fittingAdminComparisonPrice < fittingAdminMinPrice) {
                    onItemSelect(fittingAdminMinSku.code, 1, fittingAdminMinPrice, fittingAdminMinSku.type);
                }
                else {
                    onItemSelect(fittingAdminItem.code, quantity, fittingAdminItem.price, fittingAdminItem.type);
                }
                break;

            case UPLIFT_SECTION_ID: {
                let upliftItem = products.find(x => x.type == 'uplift');
                let disposalItem = products.find(x => x.type == 'disposal');
                let upliftAndDisposalMinSku = products.find(x => x.type == 'upliftanddisposalminsku');
                const comparisonPrice = upliftItem.nowPrice * quantity + disposalItem.price * quantity;
                if (comparisonPrice < upliftAndDisposalMinSku.nowPrice) {
                    onItemSelect(upliftAndDisposalMinSku.code, 1, upliftAndDisposalMinSku.nowPrice, upliftAndDisposalMinSku.type);
                }
                else {
                    onItemSelect(upliftItem.code, quantity, upliftItem.nowPrice, upliftItem.type);
                    onItemSelect(disposalItem.code, quantity, disposalItem.price, disposalItem.type);
                }
            }
                break;
            default:
                break;
        }
    }

    const deselectMultipleItems = (products, onItemDeselect) => {
        products.forEach(product => {
            onItemDeselect(product.code);
        });
    }

    const containsAny = (list1, list2) => {
        let occurance = false;
        list1.forEach(x => {
            list2.forEach(y => {
                if (x.code === y.code) {
                    occurance = true;
                }
            })
        });
        return occurance;
    }


    const {
        sectionId,
        title,
        description,
        products,
        expanded,
        visible,
        selectedItems,
        discardSectionButtonLabel,
        confirmButtonLabel,
        onItemSelect,
        onItemDeselect,
        onSectionToggle,
        onSectionDiscard,
        onSectionConfirm,
        disabled,
        calculatedArea,
        onQuantityChange
    } = props;

    const shouldNotDisplay = !!!visible;
    if (shouldNotDisplay) return null;
    const product = products[0];
    const isSelected = selectedItems && products && containsAny(selectedItems, products);
    const totalPrice = isSelected ? parseFloat(product.nowPrice * calculatedArea) : 0;
    const localeFormattedTotalPrice = totalPrice.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
    const quantity = calculatedArea;
    let formattedTotalPrice = 0;

    switch (sectionId) {
        case FITTING_SECTION_ID:
            let fittingItem = products.find(x => x.type == 'fitting');
            let fittingMinSku = products.find(x => x.type == 'fittingminsku');
            const fittingComparisonPrice = fittingItem ? parseFloat(fittingItem.nowPrice * quantity) : 0;
            const fittingMinPrice = fittingMinSku ? parseFloat(fittingMinSku.nowPrice) : 0;

            let fittingAdminItem = products.find(x => x.type == 'fittingadmin');
            let fittingAdminMinSku = products.find(x => x.type == 'fittingadminminsku');
            const fittingAdminComparisonPrice = fittingAdminItem ? parseFloat(fittingAdminItem.price * quantity) : 0;
            const fittingAdminMinPrice = fittingAdminMinSku ? parseFloat(fittingAdminMinSku.price) : 0;

            let fittingFee = 0;
            let fittingArrangementFee = 0;
            //Fitting fee - payable to 3rd party
            if (fittingComparisonPrice < fittingMinPrice) {
                fittingFee = fittingMinPrice
            } else {
                fittingFee = fittingComparisonPrice
            }

            //Fitting admin/arrangement fee
            if (fittingAdminComparisonPrice < fittingAdminMinPrice) {
                fittingArrangementFee = fittingAdminMinPrice
            } else {
                fittingArrangementFee = fittingAdminComparisonPrice
            }
            formattedTotalPrice = fittingFee + fittingArrangementFee;
            break;
        case UPLIFT_SECTION_ID: {
            let upliftItem = products.find(x => x.type == 'uplift');
            let disposalItem = products.find(x => x.type == 'disposal');
            let upliftAndDisposalMinSku = products.find(x => x.type == 'upliftanddisposalminsku');
            const comparisonPrice = upliftItem.nowPrice * quantity + disposalItem.price * quantity;
            if (comparisonPrice < upliftAndDisposalMinSku.nowPrice) {
                formattedTotalPrice = upliftAndDisposalMinSku.nowPrice
            }
            else {
                formattedTotalPrice = comparisonPrice
            }
        }
            break;
        default:
            break;
    }


    return (
        <Section
            id={sectionId}
            title={title}
            totalPrice={localeFormattedTotalPrice}
            expanded={expanded}
            disabled={disabled}
            onSectionToggle={onSectionToggle}
            onQuantityChange={onQuantityChange}
            products={products}
            quantity={quantity}
        >
            <div className={classnames(styles.variantWrapper, styles.services)} key={product.code}>
                <div className={styles.itemService}>
                    <div className={styles.leftContent} dangerouslySetInnerHTML={{ __html: description }}></div>
                    <div className={styles.rightContent}>
                        <div className={styles.priceAndButtonWrapper}>
                            <span className={styles.price}>{priceFormat.CurrencySymbol}{Number(formattedTotalPrice).toFixed(2)}</span>
                            {isSelected ?
                                <button aria-label="Remove"
                                    className={styles.btnRemove}
                                    onClick={() => deselectMultipleItems(products, onItemDeselect)}
                                >
                                    <span className={styles.removeIcon} />
                                </button>
                                : <button aria-label="Add"
                                    className={classnames('btnprimary', styles.btnCustom)}
                                    onClick={() => selectMultipleItems(products, quantity, onItemSelect, sectionId)}
                                >
                                    Add
                                </button>
                            }
                        </div>
                    </div>
                </div>
                {isSelected ?
                    <div className={styles.buttonWrapper}>
                        <button aria-label="Confirm" onClick={() => onSectionConfirm(sectionId)} className={classnames(styles.btnSecondaryCustom, 'btnsecondary')}>
                            {confirmButtonLabel}
                        </button>
                    </div> :
                    <div className={styles.buttonWrapper}>
                        <button aria-label="Discard" onClick={() => onSectionDiscard(sectionId)} className={classnames(styles.btnSecondaryCustom, 'btnsecondary')}>
                            {discardSectionButtonLabel}
                        </button>
                    </div>
                }
            </div>
        </Section>
    );
}


ServiceSection.propTypes = {
    products: PropTypes.array.isRequired,
    description: PropTypes.string,
    expanded: PropTypes.bool,
    visible: PropTypes.bool,
    selectedItems: PropTypes.array.isRequired,
    discardSectionButtonLabel: PropTypes.string.isRequired,
    confirmButtonLabel: PropTypes.string.isRequired,
    onItemSelect: PropTypes.func.isRequired,
    onItemDeselect: PropTypes.func.isRequired,
    onSectionToggle: PropTypes.func.isRequired,
    onSectionDiscard: PropTypes.func.isRequired,
    onSectionConfirm: PropTypes.func.isRequired,
};