import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import * as styles from './ServiceDisplay.scss';
import { miniCartServiceItems, localCartServiceItems, checkoutCartServiceItems, localCartDisposalItems, localCartFittingAdminItems, localCartFittingItems } from '../cartHelper';
import { priceFormat } from 'Shared/GlobalConstants';


export default function ServiceDisplay(props) {

    const renderServices = (items) => {
        let total = 0;
        let filterItems = items.filter(item => item.name != 'Fitting arrangement fee');
        return (
            <div className={styles.area}>
                <div className={styles.header}>{translate('/ProductPage/RelatedProducts/Service/Payable')}</div>
                <div className={styles.table}>
                    <div>
                        <span>{translate('/ProductPage/RelatedProducts/Service/Name')}</span>
                        <span>{translate('/ProductPage/RelatedProducts/Service/Cost')}</span>
                    </div>
                    {filterItems.map((x, i) => {
                        total += Number(x.price);
                        return (
                            <div key={i}>
                                <span>{x.name}</span>
                                <span>{priceFormat.CurrencySymbol}{Number(x.price).toFixed(2)}</span>
                            </div>
                        )
                    })}
                    <div className={styles.total}>
                        <span>{translate('/ProductPage/RelatedProducts/Service/Total')}</span>
                        <span>{priceFormat.CurrencySymbol}{Number(total).toFixed(2)}</span>
                    </div>
                </div>
                <div className={styles.information}>{translate('/ProductPage/RelatedProducts/Fitting/Information')}</div>
            </div>
        )
    }

    const getLocalLocalCartArea = () => {
        const items = localCartServiceItems(props.serviceItems);
        if (items != undefined && items.length > 0) {
            return (renderServices(items));
        }
    }

    const getMiniCartServiceTotal = () => {
        const items = miniCartServiceItems(props.serviceItems);
        if (items != undefined && items.length > 0) {
            const total = items.map(item => item.price).reduce((prev, next) => prev + next);
            return (total.toFixed(2));
        }
    }

    const getCheckoutCartServiceArea = () => {
        const items = checkoutCartServiceItems(props.serviceItems);
        if (items.length == 0) {
            return <div className={styles.checkoutCart}></div>
        }
        return (
            <div className={styles.checkoutCart}>
                {renderServices(items)}
            </div>
        );
    }

    const getDisposalInfo = () => {
        const item = localCartDisposalItems(props.serviceItems);
        if (item) {
            return (
                <div className={styles.disposal}>{item.name}:&nbsp;{priceFormat.CurrencySymbol}{item.price.toFixed(2)}</div>
            )
        }
    }

    const getFittingInfo = () => {
        const item1 = localCartFittingAdminItems(props.serviceItems);
        const item2 = localCartFittingItems(props.serviceItems);
        if (item1) {
            return (
                <div className={styles.disposal}>{item1.name}:&nbsp;{priceFormat.CurrencySymbol}{item1.price.toFixed(2)}</div>
            )
        }
        if (item2) {
            return (
                <div className={styles.disposal}>{item2.name}:&nbsp;{priceFormat.CurrencySymbol}{item2.price.toFixed(2)}</div>
            )
        }
    }

    const miniCartDisplay = () => {
        const total = getMiniCartServiceTotal();
        if (total) {
            return (
                <div className={classnames(styles.miniCart, styles.miniCartServiceInfo)}>
                    <span>{translate('/ProductPage/RelatedProducts/Service/Payable')}</span>
                    <span>{priceFormat.CurrencySymbol}{total}</span>
                </div>
            );
        }
    }


    return (
        <div className={classnames(styles.ServiceDisplay)}>
            {props.isMiniCart && miniCartDisplay()}
            {props.isLocalCart && props.isFitting ?
                getFittingInfo()
                : null
            }
            {props.isLocalCart && props.isDisposal ?
                getDisposalInfo()
                : null
            }
            {props.isLocalCart && !props.isDisposal && !props.isFitting ?
                <div className={styles.localCart}>
                    {getLocalLocalCartArea()}
                </div> : null
            }
            {props.isCheckout &&
                getCheckoutCartServiceArea()
            }
        </div>
    );
}