import { changePassword } from './password.js';
import { on } from '../Shared/events';
import store from '../Shared/store';
import { outerHTML } from '../Shared/dom';
import { invokeWhenUpdated } from '../Shared/state';
import { emailValidation } from '../Shared/customfunctions';
import serialize from 'form-serialize';
import { GA4FormTracking } from 'Shared/GA4/site-wide';
import * as types from 'Shared/GA4/constants';
import { validateInputs, validateSingleInput, passwordValidation } from 'Shared/customfunctions';

function render(state, previousState) {
    if (state.data !== previousState.data) {
        const changePasswordBlock = document.getElementById('ChangePasswordWrapper');
        outerHTML(changePasswordBlock, state.data);
    }
}

on('submit', '#LostPasswordForm', e => {
    e.preventDefault();
    const inputBox = document.querySelector('.jsForgotPasswordInput');
    if(emailValidation(inputBox.value))  {
        const element = document.querySelector('.jsForgotPasswordButton');
        const form = document.querySelector('#LostPasswordForm');
        const errorMessage = document.querySelector('.jsEmptyOrWrongEmail');
        GA4FormTracking(types.LABEL_FORM_FORGOTPASSWORD, types.LABEL_FORM_SUCCESS, errorMessage.innerHTML);
        element.disabled = true;
        element.classList.add('loadingSpinner');
        errorMessage.classList.remove('show');
        form.submit();
    }
    else{
        const errorMessage = document.querySelector('.jsEmptyOrWrongEmail');
        GA4FormTracking(types.LABEL_FORM_FORGOTPASSWORD, types.LABEL_FORM_ERROR, errorMessage.innerHTML);
        errorMessage.classList.add('show');
    }
});

on('submit', '#ResetPasswordForm', e => {
    e.preventDefault();
    const element = document.querySelector('.restPasswordButton');
    const form = document.querySelector('#ResetPasswordForm');
    element.disabled = true;
    element.classList.add('loadingSpinner');
    form.submit();
});




on('blur', 'input.form-control', e => {
    validateSingleInput(e.target);
});

// URL: my-pages/change-password/
on('submit', '#ChangePasswordForm', e => {
    e.preventDefault();
    const allInputs = document.getElementById("ChangePasswordForm").getElementsByTagName('input');
    const isValidForm = validateInputs(allInputs);
    var currentPageObj = JSON.parse(document.getElementById("CmsNavigation").getAttribute('data-currentPageId'));
    var pageId = currentPageObj.currentPageId;

    if(isValidForm) {
        store.dispatch(changePassword(e.target.action, serialize(e.target, true), pageId));
    }
});


// The function `invokeWhenUpdated` only invokes `render` if the returned state has
// changed from the previous invokation. It passes the returned state as first argument and
// the returned state from the previous invokation as second argument. Then you have the
// ability to check diffs by compering those objects before you perform an update on the DOM.
//
// Note: The first time render will be invoked here is when a `store.dispatch` has been triggered
// and the returned state has been changed. At that first invokation, previous state will be the
// initial state.
store.subscribe(invokeWhenUpdated(state => state.password)(render));
