import * as types from './constants';
//pageview
export function GA4PageView(page_type, user_id, isLoggedin, user_email, user_hashedemail) {
    window.dataLayer.push({
        event: types.ACTION_PAGEVIEW,
        page_type: page_type,
        user: {
            ...((user_id) && { id: user_id }),
            ...((isLoggedin) && { logged_in: isLoggedin }),
            ...((user_email) && { email: user_email }),                        
        },
        site: {
            environment: window.environment,
            name: window.affiliation
        }
    });
}
//header_click
export function GA4HeaderClick(header_element, header_level) {
    window.dataLayer.push({
        event: types.ACTION_HEADERCLICK,
        header: {
            element: header_element,
            level: header_level
        }
    });
}
//footer_click
export function GA4FooterClick(element, url, social_icons) {
    window.dataLayer.push({
        event: types.ACTION_FOOTERCLICK,
        footer: {
            element: element,
            url: url,
            social_icons: social_icons
        }
    });
}
//form_tracking
export function GA4FormTracking(name, status, error_message) {
    window.dataLayer.push({
        event: types.ACTION_FORMTRACKING,
        form: {
            name: name,
            status: status,
            error_message: error_message
        }
    });
}
//button_click
export function GA4ButtonClick(name, section) {
    window.dataLayer.push({
        event: types.ACTION_BUTTONCLICK,
        button: {
            name: name,
            section: section
        }
    });
}
//Booking appointment
//select_appointment_method
export function GA4SelectAppointmentMethod(appointment_method) {
    window.dataLayer.push({
        event: types.ACTION_BOOKING_APPOINTMENTMETHOD,
        appointment: {
            method: appointment_method
        }
    });
}
//in_home_appointment_step
export function GA4BookInHomeAppointment(appointment_step, jobId) {
    window.dataLayer.push({
        event: types.ACTION_BOOKING_STEP,
        appointment: {
            step: appointment_step,
            jobId: jobId
        }
    });
}
//virtual_appointment
export function GA4BookVirtualAppointment(service, isComplete) {
    window.dataLayer.push({
        event: types.ACTION_BOOKING_VIRTUAL,
        appointment: {
            virtual_service: service,
            virtual_complete: isComplete
        }
    });
}
//form_complete
export function GA4AppointmentConfirmation(email) {
    window.dataLayer.push({
        "event": types.ACTION_FORMCOMPLETE,
        "enhanced_conversion_data": {
            "email": email
        }
    });
}
//plp filters
//plp_filters
export function GA4Filter(filter, currentFilters) {
    window.dataLayer.push({
        event: types.ACTION_PLP_FILTERS,
        "filter": filter,
        "current_filters": currentFilters
    });
}

//plp_clear_filter
export function GA4ClearFilter(filterGroup, filterKey) {
    let filterString = filterGroup + "-" + filterKey;
    window.dataLayer.push({
        event: types.ACTION_PLP_CLEARFILTERS,
        "filter": filterString
    });
}

//plp_clear_all_filters
export function GA4ClearAllFilter(filter) {
    window.dataLayer.push({
        event: types.ACTION_PLP_CLEARALLFILTERS,
        "current_filters": filter
    });
}

//custom_search
export function GA4CustomSearch(searchText) {
    window.dataLayer.push({
        event: "custom_search",
        "search_text": searchText
    });
}