import React from 'react';
import { render } from 'react-dom';
import Header from './genericheader';
import store from '../../Shared/store';
import { Provider } from 'react-redux';

export function embed(container) {
    const mainMenuJson = JSON.parse(container.dataset.mainmenujson);
    const defaultImageUrl = container.dataset.defaultImageUrl;
    let refSrc = JSON.parse(localStorage.getItem('_refSrc'));
    if (mainMenuJson && mainMenuJson.referenceSource && refSrc == null) {
        localStorage.setItem('_refSrc', JSON.stringify(mainMenuJson.referenceSource));
    }
    const searchurl = container.dataset.searchurl;
    const quicksearchurl = container.dataset.quicksearchurl;
    const checkouturl = container.dataset.checkouturl;
    const loginUrl = container.dataset.loginurl;
    const isShoppingEnabled = container.dataset.isshoppingenabled.toLowerCase()=="true";
    const storesLinkUrl = container.dataset.mainmenujson.storeslinkurl;

    const breadcrumbs = JSON.parse(container.dataset.breadcrumbs);
    
    render((
        <Provider store={store}>
            <Header
                defaultImageUrl={defaultImageUrl}
                mainMenuJson={mainMenuJson}
                quicksearchurl={quicksearchurl}
                searchurl={searchurl}
                checkouturl={checkouturl}
                breadcrumbs={breadcrumbs}
                loginUrl={loginUrl}
                isShoppingEnabled={isShoppingEnabled}
                storesLinkUrl={storesLinkUrl}
            />
        </Provider>
    ), container);
}
