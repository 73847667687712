import React from 'react';
import classnames from 'classnames';

export const Icon = ({ children, className, size }) => {
    return (
        <div className={classnames('icon', size, className)}>
            {children}
        </div>
    );
};
