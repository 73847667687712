import { connect } from 'react-redux';
import { LoginForm } from './LoginForm';
import { changeEmail, changePassword, ajaxLogin } from 'Login/actions';

export default connect(
    ({ login }) => ({
        forgotPasswordPageUrl: login.forgotPasswordPageUrl,
        registrationPageUrl: login.registrationPageUrl,
        email: login.email,
        password: login.password,
        error: login.error,
        isMigratedUser: login.isMigratedUser,
        status: login.status,
        message: login.message,
        isNewsLetter: login.isNewsLetter,
        invalid : login.invalid
    }),
    dispatch => ({
        onChangeEmail: (email) => dispatch(changeEmail(email)),
        onChangePassword: (password) => dispatch(changePassword(password)),
        onLogin: (url, body, isCheckout, isRegistration, isNewsLetter) => dispatch(ajaxLogin(url, body, isCheckout, isRegistration, isNewsLetter))
    })
)(LoginForm);