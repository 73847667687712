import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import MinimalNavMenu from './../../Navigation/Menu/minimalnavmenu';
import Breadcrumbs from './../breadcrumbs';
import { translate } from './../translate';
import * as styles from './genericheader.scss';
import { MEDIUM, TINY } from 'Shared/breakpoints';
import { toggleOverlay } from 'Shared/Overlay/actions';
import { toggleMenu } from 'Shared/Header/actions';
import GenericErrorModal from '../GeneralErrorModal/index';
import { hideGenericErrorPopup } from './../GeneralErrorModal/actions';

const MinimalHeader = ({ breakpoint, menu, overlay, genericErrorModalActive, mainMenuJson, toggleOverlay,
    toggleMenu, onHideGenericErrorPopup, breadcrumbs
}) => {
    const [actionToggled, setActionToggled] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const [anchorLink, setAnchorLink] = useState(false);

    const topHeaderRef = useRef(null);
    const headerLogoRef = useRef(null);
    const instanceRef = useRef(null);

    const updateHeaderPosition = () => {
        const isFixed = window.pageYOffset > headerLogoRef.current.scrollHeight;
        setIsFixed(isFixed);
    };

    const checkAnchorLink = () => {
        const anchorLink = document.querySelector('.carpetright-anchor-link') == null;
        setAnchorLink(anchorLink);
    };

    const splitHtml = (fullString) => {
        const scriptStart = '<script>';
        const scriptEnd = '</script>';
        const parts = fullString.split(/(<script>|<\/script>)/);

        let stylePart = '';
        let scriptPart = '';

        for (let i = 0; i < parts.length; i++) {
            if (parts[i] === scriptStart) {
                scriptPart += parts[i + 1];
                i += 2;
            } else {
                stylePart += parts[i];
            }
        }
        return {
            stylePart: stylePart,
            scriptPart: scriptPart,
        };
    };

    useEffect(() => {
        window.addEventListener('scroll', updateHeaderPosition, { passive: true });
        checkAnchorLink();
        const insertedHTML = mainMenuJson.menuSticker;

        if (insertedHTML) {
            let splitParts = splitHtml(insertedHTML);

            const d = document.createElement('div');
            d.innerHTML = splitParts.stylePart;
            instanceRef.current.appendChild(d);

            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.defer = true;
            s.innerHTML = splitParts.scriptPart;
            instanceRef.current.appendChild(s);
        }

        const favIcon = document.getElementById("favicon");
        favIcon.href = mainMenuJson.favIcon;

        return () => {
            window.removeEventListener('scroll', updateHeaderPosition);
        };
    }, []);

    useEffect(() => {
        if (overlay && !menu) {
            handleActionToggle(false);
        }
    }, [overlay, menu]);

    const handleToggleMenu = () => {
        const menuOpen = !menu;
        toggleMenu(menuOpen);
        toggleOverlay(menuOpen);
    };

    const handleActionToggle = (toggled) => {
        if (breakpoint < MEDIUM) {
            if (actionToggled !== toggled) {
                setActionToggled(toggled);
            }
        }
    };

    const hideGenericError = () => {
        onHideGenericErrorPopup();
    };

    const isMobileDevice = breakpoint < MEDIUM;
    const microSiteHeaderBgColor = mainMenuJson.microSiteHeaderBgColor;
    const microSiteHeaderHeight = mainMenuJson.microSiteHeaderHeight;
    const microSiteMobileHamburgerIconColor = mainMenuJson.microSiteMobileHamburgerIconColor;
    const siteName = mainMenuJson.siteName;
    let app_container = document.getElementById("app-container");
    app_container.classList.add(siteName);

    return (
        <React.Fragment>
            <div
                id="topHeader"
                ref={topHeaderRef}
                className={classnames('header', styles.genericHeader, {
                    [styles.menuOpened]: isMobileDevice && menu,
                    ["anchorLink"]: !anchorLink
                })}
            >
                <div className={classnames("header__toparea", styles.toparea)} style={{ backgroundColor: microSiteHeaderBgColor }}>
                    <div className="header-container" style={{ height: microSiteHeaderHeight + `px` }}>
                        <div id="headerlogo" ref={headerLogoRef} className={classnames("leftarea")}>
                            <div className="logo">
                                <a href="/">
                                    <img height="auto" width="100%" class="lazyload" data-src={mainMenuJson.logoSrc} alt={!siteName ? "" : siteName.charAt(0).toUpperCase() + siteName.slice(1)} />
                                </a>
                            </div>
                        </div>
                        <div className={classnames("rightarea", {
                            ["fixed"]: isMobileDevice && isFixed
                        })}>
                            <div className="actions">
                                {mainMenuJson.menuItems &&
                                    <div className="action" id="menu-burger">
                                        <div className="mobile-burger">
                                            <button aria-label={!isMobileDevice ? "Menu" : "mobileMenu"} style={{ backgroundColor: microSiteHeaderBgColor }} type="button"
                                                className={`mobile-burger-btn btn ${!menu ?
                                                    'openMenuButton' :
                                                    'closeMenuButton'}
                                                    `}
                                                onClick={handleToggleMenu}
                                            >
                                                <span style={{ backgroundColor: microSiteMobileHamburgerIconColor }} />
                                                <span style={{ backgroundColor: microSiteMobileHamburgerIconColor }} />
                                                <span style={{ backgroundColor: microSiteMobileHamburgerIconColor }} />
                                            </button>
                                            <label>{translate('/Header/Menu')}</label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={classnames("menuarea", {
                            [styles.bottomwrapper]: menu,
                            [styles.menuFixed]: !isMobileDevice && isFixed,
                            ["fixed"]: isMobileDevice && isFixed,
                            ["fixed"]: !isMobileDevice && isFixed
                        })}>
                            {mainMenuJson.menuItems &&
                                <div id="menuScroll" className={classnames("header__bottomarea", { [styles.open]: menu })}>
                                    <div className={classnames(styles.navContainer)}>
                                        <div className={classnames(`navigation ${menu && 'mainMenu--open'} newStyleWrapper`)} style={{ backgroundColor: microSiteHeaderBgColor }}>
                                            <MinimalNavMenu
                                                menuItems={mainMenuJson.menuItems}
                                                menuIsOpen={menu}
                                                microSiteHeaderLinkColor={mainMenuJson.microSiteHeaderLinkColor}
                                                handleToggleMenu={handleToggleMenu}
                                                handleToggleOverlay={toggleOverlay}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
                {mainMenuJson.menuSticker && <div className={classnames("menuSticker", {
                    [styles.menuStickerFixed]: !isMobileDevice && isFixed,
                    [styles.menuStickerHidden]: isMobileDevice && menu
                })}>
                    <div ref={instanceRef} />
                </div>}
                {breadcrumbs && breadcrumbs.length > 0 &&
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                }
            </div>
            {genericErrorModalActive &&
                <GenericErrorModal isOpen={genericErrorModalActive} hideGenericError={hideGenericError} />
            }
        </React.Fragment>
    );
};

export default connect(
    ({ breakpoint, profile, cart, menu, overlay, genericErrorModal }) => ({
        breakpoint,
        isAuthenticated: profile.isAuthenticated,
        miniCartVisible: cart.miniCartVisible,
        menu,
        overlay,
        genericErrorModalActive: genericErrorModal.active,
    }),
    dispatch => ({
        toggleOverlay: (display) => dispatch(toggleOverlay(display)),
        toggleMenu: (display) => dispatch(toggleMenu(display)),
        onHideGenericErrorPopup: () => dispatch(hideGenericErrorPopup())
    })
)(MinimalHeader);