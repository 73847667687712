import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import storage from 'local-storage-fallback';
import NavMenu from 'Navigation/Menu';
import SearchBar from 'Search/SearchBar';
import MiniCart from 'Cart/Browser/Components/MiniCart';
import Overlay from './../Overlay';
import Breadcrumbs from './../breadcrumbs';
import { translate } from './../translate';
import StoresLocator from '../../StoreLocator/Browser/Components/LocatorArea/LocatorArea.js';
import LoginPopup from 'Login/Components/LoginPopup';
import * as styles from './genericheader.scss';
import { MEDIUM } from 'Shared/breakpoints';
import { toggleOverlay } from 'Shared/Overlay/actions';
import { toggleMenu, setCookie } from 'Shared/Header/actions';
import { ACTION_HELP } from 'Shared/GA/constants';
import { GACustomEvent } from 'Shared/GA/helpers';
import * as ga4types from 'Shared/GA4/constants';
import { GA4SelectAppointmentMethod, GA4HeaderClick } from 'Shared/GA4/site-wide';
import GenericErrorModal from '../GeneralErrorModal/index';
import { hideGenericErrorPopup } from './../GeneralErrorModal/actions';
import { CountDown } from './Components/CountDownTimerBlock/CountDown';
import { siteTheme, isTheme, selectedThemeName } from 'Shared/customfunctions';
import { WishListToolTip } from '../../../Features/WishList/WishListTooltip';
import * as styles1 from '../../../Features/Product/Browser/Components/Shared/ContentArea/ContentArea.scss';


const Header = (props) => {
    const [searchMinimize, setSearchMinimize] = useState(true);
    const [actionToggled, setActionToggled] = useState(false);
    const [isSlideUp, setIsSlideUp] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const [discountCheck, setDiscountCheck] = useState(false);
    const [anchorLink, setAnchorLink] = useState(false);
    const [tooltip, setTooltip] = useState(false);
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const ref = useRef();
    const prevOverlay = useRef(props.overlay);
    const discountCheckRef = useRef(null);
    const topHeader = useRef(null);
    const headerLogo = useRef(null);
    const headerHeightRef = useRef(0);

    const isMobileDevice = props.breakpoint < MEDIUM;
    const mainMenuJson = props.mainMenuJson;
    const headerCenterMessage = mainMenuJson ? mainMenuJson.headerCenterMessage : null;
    const topSticker = mainMenuJson ? mainMenuJson.topSticker : null;
    const displayOnceAnnouncement = mainMenuJson ? mainMenuJson.displayOnceAnnouncement : null;
    const MainPopupBoxSeen = localStorage.getItem("MainPopupBox") == null;
    const countDown = mainMenuJson ? mainMenuJson.countDown : null;
    const discountCode = mainMenuJson ? mainMenuJson.discountCode : null;
    const discountInfo = mainMenuJson ? mainMenuJson.discountInfo : null;
    const logoSrc = mainMenuJson ? mainMenuJson.logoSrc : null;
    const isShoppingEnabled = props.isShoppingEnabled;
    const cmstheme = selectedThemeName();
    const isThemeCRT = isTheme(siteTheme.CARPETRIGHT);
    const isThemeTFR = isTheme(siteTheme.TFR);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setTooltip(false);
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (prevOverlay.current && !props.overlay) {
            handleActionToggle(false);
        }
        prevOverlay.current = props.overlay;
    }, [props.overlay]);

    useEffect(() => {
        topHeader.current = document.getElementById('topHeader');
        headerLogo.current = document.getElementById('headerlogo');
        headerHeightRef.current = topHeader.current.offsetHeight;

        const discountWrapper = document.querySelector('.discountWrapper');
        if (discountWrapper) {
            discountCheckRef.current = discountWrapper;
        }

        const updateHeaderPosition = () => {
            let isFixedVar = window.pageYOffset > headerLogo.scrollHeight;
            if (!isFixed === isFixedVar) {
                setIsFixed(isFixedVar);
            }
        };

        const discountWrapperCheck = () => {
            let discountCheckVar = discountCheck == null;
            let discountInfoWrapper = document.querySelector('.discountInfoWrapper');
            let discountCheckDiv = document.querySelector('.discountCheck');
            let clone;
            if (!discountCheck === discountCheckVar) {
                setDiscountCheck(discountCheckVar);
            }
            if (discountCheckDiv != null) {
                if (discountCheckDiv.scrollWidth < discountCheckDiv.parentNode.clientWidth) {
                    discountCheckDiv.cloneNode(false);
                    discountCheckDiv.style.webkitAnimationPlayState = "paused";
                } else {
                    clone = discountCheckDiv.cloneNode(true);
                    discountInfoWrapper.append(clone);
                    discountInfoWrapper.style.webkitAnimationPlayState = "running";
                    discountInfoWrapper.addEventListener("mouseover", function () { discountCheckDiv.style.webkitAnimationPlayState = "paused"; });
                    discountInfoWrapper.addEventListener("mouseleave", function () { discountCheckDiv.style.webkitAnimationPlayState = "running"; });
                }
            }
        };

        const marqueeText = () => {
            let marqueeWrapper = document.querySelector('.marqueeWrapper');
            let marquee = document.querySelector('.topStickerMarquee');
            let clone;
            if (marquee != null) {
                clone = marquee.cloneNode(true);
                if (marquee.scrollWidth < marquee.parentNode.clientWidth) {
                    marquee.cloneNode(false);
                    marquee.style.webkitAnimationPlayState = "paused";
                } else {
                    marqueeWrapper.append(clone);
                    marquee.style.webkitAnimationPlayState = "running";
                    marquee.addEventListener("mouseover", function () { marquee.style.webkitAnimationPlayState = "paused"; });
                    marquee.addEventListener("mouseleave", function () { marquee.style.webkitAnimationPlayState = "running"; });
                }
            }
        };

        const checkAnchorLink = () => {
            let anchorLinkVar = document.querySelector('.carpetright-anchor-link') == null;
            if (!anchorLink === anchorLinkVar) {
                setAnchorLink(anchorLinkVar)
            }
        };

        const handleAddClassToBody = () => {
            let path = window.location.pathname.replace(/(?!\w|\s)./g, '');
            path = path[0] == '/' ? path.substring(1) : path;
            if (path != null) {
                document.body.classList.add(path ? path + "_page" : 'homepage');
            }
        };

        const handleGA4ButtonLinks = () => {
            const ga4CustomerJrniPageLink = document.querySelector("#ga4CustomerJrniPageLink");
            if (ga4CustomerJrniPageLink != null) {
                document.addEventListener("click", (event) => {
                    if (ga4CustomerJrniPageLink.contains(event.target)) {
                        GA4SelectAppointmentMethod(ga4types.LABEL_BOOKING_VIRTUAL_SERVICE_CONSULTATION);
                    }
                });
            }
        };

        const insertedHTML = mainMenuJson ? mainMenuJson.menuSticker : null;
        if (insertedHTML) {
            let splitParts = splitHtml(insertedHTML);

            const d = document.createElement('div');
            d.innerHTML = splitParts.stylePart;
            document.body.appendChild(d);

            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.defer = true;
            s.innerHTML = splitParts.scriptPart;;
            document.body.appendChild(s);
        }

        document.addEventListener('click', handleClickOutside, true);
        window.addEventListener('CookieInformationConsentGiven', function (event) {
            let mrkt_cookie = CookieInformation.getConsentGivenFor('cookie_cat_marketing');
            if (mrkt_cookie != null && mrkt_cookie != undefined) {
                setCookie(mrkt_cookie.toString());
            }
        }, false);

        window.addEventListener('scroll', updateHeaderPosition, { passive: true });
        discountWrapperCheck();
        marqueeText();
        checkAnchorLink();
        handleAddClassToBody();
        handleGA4ButtonLinks();

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            window.removeEventListener('scroll', updateHeaderPosition);
        };
    }, [props.mainMenuJson && props.mainMenuJson.menuSticker]);

    const splitHtml = (fullString) => {
        const scriptStart = '<script>';
        const scriptEnd = '</script>';
        var parts = fullString.split(/(<script>|<\/script>)/)

        let stylePart = '';
        let scriptPart = '';

        for (let i = 0; i < parts.length; i++) {
            if (parts[i] == scriptStart) {
                scriptPart += parts[i + 1];
                i += 2;
            }
            else {
                stylePart += parts[i];
            }
        }
        return {
            stylePart: stylePart,
            scriptPart: scriptPart,
        };
    };

    const hideGenericError = () => {
        props.onHideGenericErrorPopup();
    }

    const handleToggleMenu = () => {
        const menuOpen = !props.menu;
        let gisLogo = document.getElementById('gis-cta-container');
        let coiLogo = document.getElementById('Coi-Renew');
        if (menuOpen) {
            gisLogo ? gisLogo.style.display = 'none' : null;
            coiLogo ? coiLogo.style.display = 'none' : null;
        }
        else {
            gisLogo ? gisLogo.style.display = 'block' : null;
            coiLogo ? coiLogo.style.display = 'block' : null;
        }
        props.toggleMenu(menuOpen);
        props.toggleOverlay(menuOpen);
    }

    const handleActionToggle = (toggled) => {
        if (props.breakpoint < MEDIUM) {
            if (actionToggled !== toggled) {
                setActionToggled(toggled);
            }
        }
    }

    const handleActionUspClick = (e, text) => {
        var topHeader = topHeader;
        if (topHeader && topHeader.id.includes('topHeader')) {
            GA4HeaderClick(text, ga4types.LABEL_HEADER_LEVEL4);
        }
        window.location = e;
    }

    const handleHideMainPopupBox = (e) => {
        const MainPopupBoxSeen = storage.getItem("MainPopupBox") == null;

        if (MainPopupBoxSeen) {
            storage.setItem("MainPopupBox", "seen");
        }
    }
    
    const handlerImgLoader = ({ target: img }) => {
        setWidth(img.width);
        setHeight(img.height);
    }
    
    const handleTooltip = () => {
        setTooltip(true);
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setTooltip(false);
        }
    };

    return (
        <React.Fragment>
            {topSticker &&
                <div id="topSticker"
                    className={classnames('topSticker', 'generic', styles.topSticker, {
                        [styles.menuOpened]: isMobileDevice && props.menu,
                        ["slideup"]: isSlideUp && !props.storeLocatorVisible,
                        ["fixed"]: isFixed
                    })}
                >
                    <div className={`marqueeWrapper container`}>
                        <div class={`topStickerMarquee`} dangerouslySetInnerHTML={{ __html: mainMenuJson.topSticker }} />
                    </div>
                </div>
            }
            <div
                style={{ minHeight: (isSlideUp || isMobileDevice || displayOnceAnnouncement || countDown) ? '' : `${headerHeightRef.current}px` }}
                id="topHeader"
                className={classnames('header', 'generic', 'new-menu-is-on', styles.genericHeader, {
                    [styles.menuOpened]: isMobileDevice && props.menu,
                    ["slideup"]: isSlideUp && !props.storeLocatorVisible,
                    ["fixed"]: isMobileDevice && isFixed,
                    ["anchorLink"]: !anchorLink
                })}
            >
                <div className={classnames("header__toparea", styles.toparea)}>
                    <div className="container header-container">
                        <div id="headerlogo" className={classnames("leftarea")}>
                            {isThemeCRT && !isMobileDevice ? <div className={classnames("toparea", "left")}>{mainMenuJson.headerLeftMessage ? <span dangerouslySetInnerHTML={{ __html: mainMenuJson.headerLeftMessage }} /> : <span></span>}</div> : null}
                            {isThemeTFR && !isMobileDevice ? <div className={classnames("toparea", "left")}>{mainMenuJson.headerLeftMessage ? <span dangerouslySetInnerHTML={{ __html: mainMenuJson.headerLeftMessage }} /> : <span></span>}</div> : null}

                            <div className="logo">
                                <a href="/"
                                    onClick={(e) => { e.preventDefault, GA4HeaderClick(ga4types.Label_LOGO, ga4types.LABEL_HEADER_LEVEL1) }}
                                >
                                    <img
                                        width={width}
                                        height={height}
                                        onLoad={handlerImgLoader}
                                        class="lazyload"
                                        data-src={mainMenuJson ? mainMenuJson.logoSrc : null}
                                        src={mainMenuJson ? mainMenuJson.logoSrc : null}
                                        alt={cmstheme} />
                                </a>
                            </div>
                        </div>
                        <div className={classnames("midarea", {

                        })}>
                            {headerCenterMessage && (isThemeCRT || isThemeTFR) &&
                                <div className="toparea" dangerouslySetInnerHTML={{ __html: mainMenuJson.headerCenterMessage }} />
                            }

                            <div className={classnames("bottomarea")}>
                                <SearchBar
                                    quicksearchurl={props.quicksearchurl}
                                    searchurl={props.searchurl}
                                />
                            </div>
                        </div>
                        <div className={classnames("rightarea", {
                            ["fixed"]: isMobileDevice && isFixed
                        })}>
                            <div className="actions">
                                {isMobileDevice &&
                                    <SearchBar
                                        quicksearchurl={props.quicksearchurl}
                                        searchurl={props.searchurl}
                                        minimize={searchMinimize}
                                        allowMinimize={true}
                                    />
                                }

                                {!isMobileDevice && mainMenuJson && mainMenuJson.wishListUrl ?
                                    <div className="action" id="shortlistwrapperDesktop" ref={ref}>
                                        {props.isAuthenticated ?
                                            <div className={styles.addToShortList}>
                                                <div id={'shortlist'} className={classnames('action', styles1.addToShortList, styles.addToShortList)}>
                                                    <a href={mainMenuJson.wishListUrl}>
                                                        <i className={classnames(styles.faRegular, styles1.faRegular, styles1.faHeartO)}></i>
                                                        <span>{translate('/ProductPage/ShortList')}</span>
                                                    </a>
                                                </div>
                                                {tooltip ? <WishListToolTip id={styles.WishListToolTip} /> : null}
                                            </div>
                                            :
                                            <div className={styles.addToShortList}>
                                                <div id={'shortlist'} className={classnames('action', styles1.addToShortList, styles.addToShortList)}>
                                                    <div onClick={() => { handleTooltip() }}>
                                                        <i className={classnames(styles.faRegular, styles1.faRegular, styles1.faHeartO)}></i>
                                                        <span>{translate('/ProductPage/ShortList')}</span>
                                                    </div>
                                                </div>
                                                {tooltip ? <WishListToolTip id={styles.WishListToolTip} /> : null}
                                            </div>
                                        }
                                    </div> : null
                                }

                                <div className="action" id="locations">
                                    {mainMenuJson && 
                                        <StoresLocator storesLinkUrl={mainMenuJson.storesLinkUrl} />
                                    }
                                </div>

                                <div className="action" id="help" onClick={() => {
                                    handleActionToggle(true);
                                    GACustomEvent(ACTION_HELP);
                                    window.location = mainMenuJson.customerServiceUrl;
                                    GA4HeaderClick(ga4types.LABEL_HEADER_HELP, ga4types.LABEL_HEADER_LEVEL1);
                                }}>
                                    <span className={`${classnames('icon', styles.icon, styles.helpIcon)}`} />
                                    <label>
                                        {translate('/Header/Help')}
                                    </label>
                                </div>
                                {isShoppingEnabled &&
                                    <div className="action" id="login">
                                        <LoginPopup firstName={localStorage.getItem('firstName') !== null && localStorage.getItem('firstName') !== '' ? localStorage.getItem('firstName') : props.firstName} />
                                    </div>
                                }
                                {isMobileDevice && mainMenuJson && mainMenuJson.wishListUrl ?
                                    <div className="action" id="shortlistwrapperMobile" ref={ref}>
                                        {props.isAuthenticated ?
                                            <div className={styles.addToShortList}>
                                                <div id={'shortlist'} className={classnames('action', styles1.addToShortList, styles.addToShortList)}>
                                                    <a href={mainMenuJson.wishListUrl}>
                                                        <i className={classnames(styles.faRegular, styles1.faRegular, styles1.faHeartO)}></i>
                                                        <span>{translate('/ProductPage/ShortList')}</span>
                                                    </a>
                                                </div>
                                                {tooltip ? <WishListToolTip id={styles.WishListToolTip} /> : null}
                                            </div>
                                            :
                                            <div className={styles.addToShortList}>
                                                <div id={'shortlist'} className={classnames('action', styles1.addToShortList, styles.addToShortList)}>
                                                    <div onClick={() => { handleTooltip() }}>
                                                        <i className={classnames(styles.faRegular, styles1.faRegular, styles1.faHeartO)}></i>
                                                        <span>{translate('/ProductPage/ShortList')}</span>
                                                    </div>
                                                </div>
                                                {tooltip ? <WishListToolTip id={styles.WishListToolTip} /> : null}
                                            </div>
                                        }
                                    </div> : null
                                }
                                {isMobileDevice && mainMenuJson && !mainMenuJson.hideCartIcon &&
                                    <div className="action" id='cart'>
                                        <MiniCart
                                            checkouturl={props.checkouturl}
                                            linklabel={mainMenuJson.minicartReadMoreLabel}
                                            linkurl={mainMenuJson.minicartReadMoreLinkUrl}
                                        />
                                    </div>
                                }
                                {isMobileDevice && mainMenuJson && mainMenuJson.menuItems &&
                                    <div className="action" id="menu-burger">
                                        <div className="mobile-burger">
                                            <button type="button" aria-label="menu"
                                                className={`mobile-burger-btn btn ${props.menu ?
                                                    'closeMenuButton' :
                                                    'openMenuButton'}
                                                    `}
                                                onClick={handleToggleMenu}
                                            >
                                                <span />
                                                <span />
                                                <span />
                                            </button>
                                            <label>{translate('/Header/Menu')}</label>
                                        </div>
                                    </div>
                                }
                            </div>
                            {!isMobileDevice && mainMenuJson && !mainMenuJson.hideCartIcon &&
                                <div className="bottomarea">
                                    <div
                                        onClick={(e) => { e.preventDefault, GA4HeaderClick(ga4types.LABEL_HEADER_BASKET, ga4types.LABEL_HEADER_LEVEL1) }}
                                        className="action miniCart-area">
                                        <MiniCart
                                            checkouturl={props.checkouturl}
                                            linklabel={mainMenuJson.minicartReadMoreLabel}
                                            linkurl={mainMenuJson.minicartReadMoreLinkUrl}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={classnames("menuarea", {
                    [styles.bottomwrapper]: props.menu,
                    [styles.menuFixed]: !isMobileDevice && isFixed,
                    ["fixed"]: isMobileDevice && isFixed,
                    ["fixed"]: !isMobileDevice && isFixed
                })}>
                    {mainMenuJson && mainMenuJson.menuItems &&
                        <div id="menuScroll" className={classnames("header__bottomarea", { [styles.open]: props.menu })}>
                            <div className={classnames("container", styles.navContainer)}>
                                <div className={classnames(`navigation ${props.menu && 'mainMenu--open'} newStyleWrapper`)}>
                                    <NavMenu
                                        menuItems={mainMenuJson.menuItems}
                                        menuIsOpen={props.menu}
                                        handleToggleMenu={handleToggleMenu}
                                        offerLink={mainMenuJson.offerLink}
                                        usefulLinks={mainMenuJson.usefulLinks}
                                        usp={mainMenuJson.usp}
                                        mobileHeader={mainMenuJson.mobileHeader}
                                        message={mainMenuJson.headerLeftMessage}
                                        handleToggleOverlay={props.toggleOverlay}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {displayOnceAnnouncement && MainPopupBoxSeen &&
                        <div id="displayOnceAnnouncement" className={classnames('displayOnceAnnouncement', 'generic', styles.displayOnceAnnouncement)}>
                            <div dangerouslySetInnerHTML={{ __html: mainMenuJson.displayOnceAnnouncement }} />
                            <button aria-label="hidePopup" onClick={() => handleHideMainPopupBox()} className={'glyphicon glyphicon-remove'}></button>
                        </div>
                    }
                </div>
                {mainMenuJson && mainMenuJson.menuSticker && <div className={classnames("menuSticker", {
                    [styles.menuStickerFixed]: !isMobileDevice && isFixed,
                    [styles.menuStickerHidden]: isMobileDevice && props.menu
                })}>
                </div>}

                {mainMenuJson && mainMenuJson.countDown.length > 0 && <CountDown countDown={props.mainMenuJson.countDown} />}

                {discountCode && discountInfo &&
                    <div id={styles.discountWrapper} className={classnames('discount', 'container', {
                        [styles.menuStickerFixed]: !isMobileDevice && isFixed,
                        [styles.menuStickerHidden]: isMobileDevice && props.menu,
                        ["marquee-discountContent"]: !discountCheck,
                    })}>
                        <div className={classnames(styles.discountCode)} dangerouslySetInnerHTML={{ __html: mainMenuJson.discountCode }} />
                        <div className={'discountInfoWrapper'}><div className={classnames({ ["discountCheck"]: !discountCheck })} dangerouslySetInnerHTML={{ __html: mainMenuJson.discountInfo }} /></div>
                    </div>
                }
                {!isMobileDevice && <Overlay />}
            </div>
            <div style={{ height: headerHeightRef + 'px' }} className={classnames({
                "hidden": !isSlideUp
            })}></div>

            {mainMenuJson && mainMenuJson.usp.length > 0 && <div className="header__pictogram">
                <div className="container">
                    <div className="pictograms">
                        {mainMenuJson.usp.map((item, i) => (
                            <a key={`usp${i}`} className="pictogram" onClick={() => handleActionUspClick(item.link, item.text)}>
                                <img src={item.icon ? item.icon : '/styles/images/pictogram_empty.png'} width="23px" height="23px" alt={item.text ? item.text : ''} />
                                {isThemeTFR ? <span title={item.text}>{item.text}</span> : <React.Fragment>{item.text}</React.Fragment>}
                            </a>
                        )
                        )}
                    </div>
                </div>
            </div>}

            {!isMobileDevice && mainMenuJson && mainMenuJson.standardTextBlock &&
                <div className={classnames("container", styles.standardTextBlock)} dangerouslySetInnerHTML={{ __html: mainMenuJson.standardTextBlock }} />
            }

            {isMobileDevice && mainMenuJson && mainMenuJson.standardTextBlockMobile &&
                <div className={classnames("container", styles.standardTextBlockMobile)} dangerouslySetInnerHTML={{ __html: mainMenuJson.standardTextBlockMobile }} />
            }

            {props.breadcrumbs && props.breadcrumbs.length > 0 &&
                <Breadcrumbs breadcrumbs={props.breadcrumbs} />
            }
            {props.genericErrorModalActive &&
                <GenericErrorModal isOpen={props.genericErrorModalActive} hideGenericError={hideGenericError} />
            }
        </React.Fragment>
    );
}

export default connect(
    ({ breakpoint, profile, cart, menu, overlay, genericErrorModal }) => ({
        breakpoint,
        isAuthenticated: profile.isAuthenticated,
        miniCartVisible: cart.miniCartVisible,
        menu,
        overlay,
        genericErrorModalActive: genericErrorModal.active,
        firstName: profile.data.firstName
    }),
    dispatch => ({
        toggleOverlay: (display) => dispatch(toggleOverlay(display)),
        toggleMenu: (display) => dispatch(toggleMenu(display)),
        onHideGenericErrorPopup: () => dispatch(hideGenericErrorPopup())
    })
)(Header);