import {
    CHANGE_PASSWORD_PENDING,
    CHANGE_PASSWORD_FULFILL,
    CHANGE_PASSWORD_REJECT,
    CHANGE_PASSWORD_UPDATE,
    CHANGE_PASSWORD_COMPLETED,
} from 'Shared/action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';
export const COMPLETED = 'COMPLETED';

const initialState = {
    status: FULFILLED,
    message: '',
    successData: null,
    passwordStatus: FULFILLED,
    data: '',
};

export default function password(state = initialState, action) {
    switch (action.type) {
    case CHANGE_PASSWORD_PENDING:
        return Object.assign({}, state, {
            status: PENDING,
            passwordStatus : PENDING
        });
    case CHANGE_PASSWORD_FULFILL:
        return Object.assign({}, state, {
            status: FULFILLED,
            passwordStatus : FULFILLED
        });
    case CHANGE_PASSWORD_REJECT:
        return Object.assign({}, state, {
            status: REJECTED,
            passwordStatus: REJECTED,
            successData: action.successData,
        });
    case CHANGE_PASSWORD_UPDATE:
        return Object.assign({}, state, {
            data: action.data,
        });
    case CHANGE_PASSWORD_COMPLETED:
        return Object.assign({}, state, {
            passwordStatus: COMPLETED,
            successData: action.successData,
        });
    default:
        return state;
    }
}
