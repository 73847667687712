//GA4 eCommerce
export const ACTION_VIEWITEM_LIST = 'view_item_list';
export const ACTION_SELECTITEM = "select_item";
export const EVENT_VIEWITEM = "view_item";
export const EVENT_ADDTOCART = "add_to_cart";
export const EVENT_REMOVEFROMCART = "remove_from_cart";
export const EVENT_VIEWCART = "view_cart";
export const ACTION_BEGINCHECKOUT = "begin_checkout";
export const ACTION_ADDSHIPPINGINFO = "add_shipping_info";
export const ACTION_ADDPAYMENTINFO = "add_payment_info";
export const ACTION_PURCHASE = "purchase";
export const EVENT_VIEWPROMOTION = "view_promotion";
export const ACTION_SELECTPROMOTION = "select_promotion";
export const EVENT_ADDTOWISHLIST = "add_to_wishlist";

//Site wide
export const ACTION_PAGEVIEW = "pageview";
export const ACTION_HEADERCLICK = "header_click";
export const ACTION_FOOTERCLICK = "footer_click";
export const ACTION_FORMTRACKING = "form_tracking";
export const ACTION_BUTTONCLICK = "button_click";

export const ACTION_BOOKING_APPOINTMENTMETHOD = "appointment_method";
export const ACTION_BOOKING_INHOME = "in_home_appointment_step";
export const ACTION_BOOKING_VIRTUAL = "virtual_appointment";
export const ACTION_BOOKING_STEP = "appointment_step";

export const LABEL_BOOKING_INHOME_MEASURE_CONSULTATION = "in-home measure & consultation";
export const LABEL_BOOKING_VIRTUAL_SERVICE_CONSULTATION = "virtual consultation";
export const LABEL_BOOKING_VIRTUAL_SERVICE_TELEPHONE = "virtual consultation - telephone";
export const LABEL_BOOKING_VIRTUAL_SERVICE_VIDEO = "video home consultation";
export const LABEL_BOOKING_STEP_APPOINTMENT_LANDING = 1;
export const LABEL_BOOKING_STEP_APPOINTMENTS = 2;
export const LABEL_BOOKING_STEP_SERVICE = 3;
export const LABEL_BOOKING_STEP_CALENDER = 4;
export const LABEL_BOOKING_STEP_COMPLETE = 5;
export const ACTION_FORMCOMPLETE = "form_complete";
export const TITLE_ERROR_BOOKING = 'appointments_error_booking';
export const TITLE_ERROR_AVAILABILITY = 'appointments_error_availability';

export const ACTION_PLP_FILTERS = "plp_filters";
export const ACTION_PLP_CLEARFILTERS = "plp_clear_filter";
export const ACTION_PLP_CLEARALLFILTERS = "plp_clear_all_filters";

export const LABEL_HEADER_SEARCH = "Search";
export const LABEL_HEADER_STORES = "Stores";
export const LABEL_HEADER_HELP = "Help";
export const LABEL_HEADER_LOGIN = "Login";
export const LABEL_HEADER_ACCOUNT = "Account";
export const LABEL_HEADER_BASKET = "Basket";

export const LABEL_HEADER_LEVEL1 = "Level 1";
export const LABEL_HEADER_LEVEL2 = "Level 2";
export const LABEL_HEADER_LEVEL3 = "Level 3";
export const LABEL_HEADER_LEVEL4 = "Level 4";
export const LABEL_HEADER_LEVEL5 = "Level 5";

//Social Media icons - Footer
export const LABEL_FOOTER_INSTAGRAM = "Instagram";
export const LABEL_FOOTER_PINTEREST = "Pinterest";
export const LABEL_FOOTER_GOOGLE = "Google";
export const LABEL_FOOTER_FACEBOOK = "Facebook";
export const LABEL_FOOTER_TWITTER = "Twitter";
export const LABEL_FOOTER_YOUTUBE = "Youtube";

export const LABEL_SECTION_ADD = "Add";
export const LABEL_SECTION_REMOVE = "Remove";
export const LABEL_SECTION_CONFIRM = "Confirm";
export const LABEL_SECTION_DISCARD = "Discard";
export const LABEL_ADD_UNDERLAY = "Add underlay";
export const LABEL_ADD_ACCESSORIES = "Add accessories";
export const LABEL_ADD_GRIPPER = "Add gripper";
export const LABEL_ADD_FITTING = "Add fitting";
export const LABEL_ADD_UPLIFT = "Add uplift and disposal";
export const LABEL_ADD_HEADBOARD = "Add headboard";
export const LABEL_ADD_DESIGNERBRAND = "Designer brands";

//FORM
export const LABEL_FORM_FIRST_INTERACTION = "First Interaction";
export const LABEL_FORM_ERROR = "Error";
export const LABEL_FORM_SUCCESS = "Success";
export const LABEL_FORM_CREATEACCONT = "Create account";
export const LABEL_FORM_CONTACTFORM = "Contact us";
export const LABEL_FORM_SIGNIN = "Login";
export const LABEL_FORM_CHANGEPASSWORD = "Change password";
export const LABEL_FORM_FORGOTPASSWORD = "Forgot password";
export const LABEL_FORM_RETRIEVEQUOTE = "Retrieve  quote";
export const LABEL_FORM_SEARCHORDER = "Search order";
export const LABEL_FORM_CHECKOUTFORM = "Checkout form";
export const LABEL_FORM_PROFILE = "Profile";

export const LABEL_FORM_COMPLAINTFRORM = "Complaints form";
export const LABEL_FORM_NEWSLETTERSIGNUP = "Newsletter signup";
export const LABEL_FORM_SEARCHFORQUOTE = "Search for quote";
export const LABEL_FORM_SEARCHFORORDER = "Search for order";
export const LABEL_FORM_APPOINTMENT = "Free Home Consultation";
export const LABEL_LOADMORERESULTS = "Load more results";
export const LABEL_LOADMORERESULTS_COUNTER = "load_more_results_";
export const Label_LOGO = "Logo";

//Account Dropdown list
export const LABEL_ACCOUNT_MENU_PROFILE = "Profile";
export const LABEL_ACCOUNT_MENU_CHANGE_PASSWORD = "Change password";
export const LABEL_ACCOUNT_MENU_ORDER_HISTORY = "Order history";
export const LABEL_ACCOUNT_MENU_MY_APPOINTMENTS = "My appointments";
export const LABEL_ACCOUNT_MENU_MY_QUOTES = "My quotes";
export const LABEL_ACCOUNT_MENU_LOGOUT = "Logout";

//IHC
export const LABEL_IHC_STEP_DETAILS = 2;
export const LABEL_IHC_STEP_CALENDAR = 3;
export const LABEL_IHC_STEP_SERVICE = 4;
export const LABEL_IHC_STEP_COMPLETE = 5;