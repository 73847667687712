import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { setShippingOption, updateDeliveryPickupOnly, setErrorMessage } from '../../actions';
import { PENDING } from '../../reducer';
import { CarryOut } from '../../../../checkoutConstants';
import * as styles from './shippingoptions.scss';
import PickUpStoreOptions from './Components/PickUpStoreOptions';
import { translate } from 'Shared/translate';
import classnames from 'classnames';
import { DELIVERY_SHIPPING } from './../../index';
import Spinner from 'Shared/Spinner';

function ShippingOptions(props) {

    const [error, setError] = useState('');
    const [tooltip, setTooltip] = useState('');

    const prevProps = useRef(props);
    useEffect(() => {
        if (prevProps.current.selectedModeId !== props.selectedModeId) {
            props.validateForm();
        }
        prevProps.current = props;
    }, [props.selectedModeId, props.validateForm]);


    const handleChange = (type) => {
        if (props.options && props.options.length != 1) {
            props.setShippingOption(type.code);
        }
        setError('');
        props.setErrorMessage("");
    }

    const handleStoreChange = (msg) => {
        setError(msg);
        props.setErrorMessage(msg);
    }

    return (
        <div className={styles.shippingOptionsWrapper}>
            <div className={styles.deliveryButtons}>
                {props.options != null ? props.options.length < 1 && (props.selectedAddress && props.selectedAddress.zipCode != '') ?
                    <div id={styles.overlay} className={classnames(styles.overlay)}>
                        <Spinner alt />
                    </div> : null : null}
                {props.options && props.options.length > 0 ? props.options.map((option, i) => {
                    if (option.hasStores) {
                        const matchingDelivery = props.deliveryModeDisplay ? props.deliveryModeDisplay.find(delivery => delivery.deliveryItemCode === option.deliveryItemCode) : null;
                        return (<PickUpStoreOptions
                            key={`deliveryOption${i}`}
                            deliveryOption={option}
                            matchingDelivery={matchingDelivery ? matchingDelivery : null}
                            pickUpStoreOption={matchingDelivery ? matchingDelivery.modeOfDeliveryDisplayName : option.description}
                            pickUpStoreOptionTooltipTitle={matchingDelivery ? matchingDelivery.tooltipTitle : null}
                            pickUpStoreOptionTooltipContent={matchingDelivery ? matchingDelivery.tooltipContent : null}
                            validateForm={() => props.validateForm()}
                            changeOptions={props.updateDeliveryPickupOnly}
                            isQuoteCart={props.isQuoteCart}
                            isSampleOnly={props.isSampleOnly}
                            isFinanceCart={props.isFinanceCart}
                            handleStoreChange={handleStoreChange}

                        />);
                    } else {
                        const matchingDelivery = props.deliveryModeDisplay ? props.deliveryModeDisplay.find(delivery => delivery.deliveryItemCode === option.deliveryItemCode) : null;

                        let deliveryItemCode;
                        if (matchingDelivery) {
                            ({ deliveryItemCode } = matchingDelivery);
                        } else {
                            deliveryItemCode = option.deliveryItemCode;
                        }

                        const handleTooltip = (tooltipId, isMouseOver) => {
                            const tooltipState = { tooltip };
                            tooltipState[tooltipId] = isMouseOver;
                            setTooltip(tooltipState);
                        }

                        return (
                            <div className={classnames(styles.homeDeliveryOption, { [styles.noDeliveryModeDisplay]: !props.deliveryModeDisplay })} key={`deliveryOption${i}`}>
                                <button
                                    className={classnames('btn', 'shippingoptionbtn', styles.shippingoptionbtn, option.code, option.code === props.selectedModeId ? 'btnsecondary' : 'button-link')}
                                    onClick={() => handleChange(option)}
                                    title={option.code}
                                    key={`deliveryOption${i}`}
                                    type="button" aria-label="SampleOnly"
                                    disabled={props.isQuoteCart || props.isSampleOnly}
                                >
                                    {matchingDelivery ?
                                        <div className={styles.name}>
                                            {matchingDelivery.modeOfDeliveryDisplayName}
                                        </div> :
                                        <div className={styles.name}>
                                            {option.description}
                                        </div>
                                    }
                                    {
                                        !props.isSampleOnly && (props.isQuoteCart && props.selectedModeId != CarryOut ?
                                            <div>
                                                {matchingDelivery ? matchingDelivery.modeOfDeliveryDisplayName : translate('/Checkout/DeliveryMethods/HomeDelivery')}
                                            </div> :
                                            <div>£{Number(option.deliveryCharge).toFixed(2)}</div>)
                                    }
                                </button>
                                <div className={styles.tooltipWrapper}>
                                    {matchingDelivery ?
                                        <div className={classnames(styles.tooltipContainer, 'tooltipContent')}>
                                            <div
                                                onMouseEnter={() => handleTooltip(matchingDelivery.deliveryItemCode, true)}
                                                onMouseLeave={() => handleTooltip(matchingDelivery.deliveryItemCode, false)}
                                                className={styles.infoCircle}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#404147" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10 14V10M10 6H10.01" stroke="#404147" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <span>{matchingDelivery.tooltipTitle}</span>
                                            </div>
                                        </div> : null
                                    }
                                    {tooltip[deliveryItemCode] ? <div className={styles.tooltipContent}>{matchingDelivery.tooltipContent}</div> : null}
                                </div>
                            </div>

                        );
                    }
                }) :
                    <div className={styles.emptyOptions}>
                        {props.errorMessage != "" && <div className={styles.errorMessage}>{props.errorMessage}</div>}
                        {props.options && props.options.length === 0 && (props.selectedAddress && props.selectedAddress.zipCode != '' ?
                            (props.deliveryModesLoadingMessage ? props.deliveryModesLoadingMessage : translate('/Checkout/DeliveryMethods/FetchingDelivery'))
                            : translate('/Checkout/DeliveryMethods/EmptyOptions'))}
                        {props.customDisplayMessages != null && <div className={styles.errorMessage}>{props.customDisplayMessages[0]}</div>}
                    </div>
                }
            </div>
            {error != "" && <div className={styles.errorMessage}>{error}</div>}
            {!props.isSampleOnly && props.options && props.options.length != 0 && props.customDisplayMessages &&
                <div className={styles.deliveryMessageWrapper}>
                    <ul>
                        {props.customDisplayMessages.map((message, index) => {
                            return (
                                <li key={index} className={styles.message}>{message}</li>
                            )
                        })}
                    </ul>
                </div>}
        </div>
    );
}

export default connect(
    ({ checkout }) => ({
        options: checkout.delivery.data.shippingOptions,
        deliveryModesLoadingMessage: checkout.delivery.data.deliveryModesLoadingMessage,
        isPending: checkout.delivery.status === PENDING,
        isSelectedPreferenceType: checkout.delivery.data.preference === DELIVERY_SHIPPING,
        selectedModeId: checkout.delivery.data.modeId,
        errorMessage: checkout.delivery.data.errorMessage,
        customDisplayMessages: checkout.delivery.data.customDisplayMessages,
    }),
    dispatch => ({
        setShippingOption: (...args) => dispatch(setShippingOption(...args)),
        updateDeliveryPickupOnly: (...args) => dispatch(updateDeliveryPickupOnly(...args)),
        setErrorMessage: (message) => dispatch(setErrorMessage(message)),
    })
)(ShippingOptions);