import {
    WISHLIST_DISABLE_WISHLIST_BUTTON,
} from '../../Shared/action-types';

import { postJson } from '../../Shared/server';

export async function addToWishList(code) {
    const data = { Code: code };
    
    const response =  await fetch('/wishlist/addline', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
        const jsonData = await response.json();
        return jsonData;
      } else {
        return null;
      }
};

export async function removeFromWishList(code) {
    const data = { Code: code };
    
    const response =  await fetch('/wishlist/removeline', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
        const jsonData = await response.json();
        return jsonData;
      } else {
        return null;
      }
};

export function getAllWishList() {
    return postJson("/wishlist/getallwishList");
}

export function toggleDisableWishlistButton(disable) {
    return dispatch => {
        dispatch({
            type: WISHLIST_DISABLE_WISHLIST_BUTTON,
            disable,
        });
    };
}
