import React from 'react';
import { translate } from 'Shared/translate';
import * as styles from './SingleRemovePopup.scss';
import Button from 'Shared/Button';
import classnames from 'classnames';
import { siteTheme, isTheme } from 'Shared/customfunctions';

export default ({ lineId, toggleModal, removeSingle, isPending }) => (
    <div className={styles.single}>
        <div className={classnames(styles.headline, {
            [styles.tftbox]:  isTheme(siteTheme.TFR)
        })}>{translate('/Cart/RemoveMessages/SingleItem')}</div>        
            <div className={styles.actions}>
                <button aria-label="Cancel" disabled={isPending} onClick={toggleModal} className={classnames(styles.btnCancel + ` btnCancel`)}>{translate('/Cart/RemoveMessages/Cancel')}</button>
                <Button aria-label="Ok"
                    disabled={isPending} 
                    onClick={() => removeSingle(lineId)}
                    className={
                        classnames( styles.btnOkay + ` btnOkay`, 
                            { ['loadingSpinner']: isPending },
                            { [styles.loadingSpinner]: isPending },
                        )
                    }
                    >
                    <span class="spinner"></span>
                    <span class="text">{translate('/Cart/RemoveMessages/Ok')}</span>
                    
                </Button>
            </div>        
    </div>
);



