import * as types from './constants';
import { GetCategory } from 'Shared/CategoryType';

export function GACustomSearch(searchText) {
    window.dataLayer.push({
        event: types.EVENT_SEARCHITEM,
        "EventSearchInput": searchText
      });
    
    GAvirtualPageView('/search?query=' + encodeURI(searchText), 'search_query');
}

export function GACustomEvent(action, label = '' , category = types.CATEGORY_CLICK, event = types.EVENT_CLICKACTION) {
    window.dataLayer.push({
        event,
        "EventCategory": category,
        "EventAction": action,
        "EventLabel": label
    });
}

export function GAvirtualPageView(url,pageTitle)
{
    window.dataLayer.push({
        event:types.EVENT_VIRTUALPAGEVIEW,
        virtualPageURL:url,
        virtualPageTitle: pageTitle
        });
}

export function GAviewProduct(selectedVariant, selectedItem) {
    window.dataLayer.push({
        event: types.EVENT_PRODUCTDETAILVIEW,
        ecommerce: {
                detail: {
                    products: [{
                    name: selectedItem.displayName,
                    id: selectedItem.displayCode,
                    price: `${selectedItem.priceAmount}`,
                    category: GetCategory[selectedItem.categoryType],
                    ...( selectedVariant && {variant: selectedVariant.color})
                    }]
                }
            }
      });
}

export function GAcheckoutStep(step, option, products, stepFind) {
    if(!stepFind) { //step not yet existing
        window.dataLayer.push({
            event: types.EVENT_CHECKOUTSTEPS,
            ecommerce: {
                checkout: {
                    actionField: {
                    step,
                    ...(option && {option}),
                    },
                    ...(step === 1 && {
                        products: prepareProducts(products)
                    })
                }
            }
        });
    } else { 
        window.dataLayer.push({
            event: types.EVENT_CHECKOUTSTEPS,
            ecommerce: {
                checkout_option: {
                    actionField: { step, option }
             },
           }
         });
    }
    return true;
}
export function GAremoveProductsFromBasket(removedItems) {
    if(removedItems.length > 0) {
        window.dataLayer.push({
        event: types.EVENT_REMOVEFROMBASKET,
            ecommerce: {
                remove: {                                // 'add' actionFieldObject measures.
                    products: prepareProducts(removedItems)
                }
            }
        })
    }
}
export function GAaddProductToBasket(addedItems) {
    if(addedItems.length > 0) {
        window.dataLayer.push({
        event: types.EVENT_ADDTOBASKET,
            ecommerce: {
                add: {                                // 'add' actionFieldObject measures.
                    products: prepareProducts(addedItems)
                }
            }
        });
    }
}

function calcPrice(item){
    if (item.discountAmount != 0  && item.quantity != 0){
        return `${item.price - (item.discountAmount / item.quantity)}`;
    }
    else{
        return `${item.price}`;
    }
}

function prepareProducts(items) {
    return items.map(item => ({
        name: item.productName,
        id: item.productId,
        price: calcPrice(item),
        category: item.categoryType || item.relationType,
        ...( (item.color || item.style)  && {variant: item.color || item.style}),
        quantity: item.quantity
    }));
}

export function GAecommerceCheckoutExists() {
    return dataLayer.find(layer => layer.event === types.EVENT_CHECKOUTSTEPS);
}
