export const EVENT_ADDTOBASKET = "EEAddToBasket";
export const EVENT_REMOVEFROMBASKET = "EERemoveFromBasket";
export const EVENT_CHECKOUTSTEPS = "EECheckout";
export const EVENT_CLICKACTION = "CustomClick";
export const EVENT_PRODUCTDETAILVIEW = "EEProductDetailView";
export const EVENT_SEARCHITEM = "CustomSearch";
export const EVENT_VIRTUALPAGEVIEW = "VirtualPageview";

export const CATEGORY_CLICK = "Click";
export const CATEGORY_SEARCH = "Search";

export const ACTION_ACCEPTTC = "accept terms and conditions";
export const ACTION_ADDFILTER = "add filter";
export const ACTION_APPOINTMENTS = "proceed to next page";
export const ACTION_APPOINTMENTSMEASUREHALL = "measure hall, stairs & landing";
export const ACTION_APPOINTMENTSPRODUCTTYPE = "product type";
export const ACTION_APPOINTMENTSROOM = "number of rooms";
export const ACTION_APPOINTMENTBOOK = "book appointment";
export const ACTION_CHECKOUT = "checkout";
export const ACTION_FINDADDRESS = "find address";
export const ACTION_HELP = "help";
export const ACTION_LOADMORERESULTS = "load more results";
export const ACTION_LOGIN = "login";
export const ACTION_MYACCOUNT = "my account";
export const ACTION_ORDERSAMPLE = "order sample";
export const ACTION_PROMOCODE = "promo code";
export const ACTION_REMOVEFILTER = "remove filter";
export const ACTION_SAVEBASKET = "save basket";
export const ACTION_SEARCH = "search";
export const ACTION_SEARCHRESULTS = "quicksearch results";
export const ACTION_SORTBY = "sort by";
export const ACTION_STORES = "stores";
export const ACTION_VARIANTSELECT = "variant select";
export const ACTION_VIEWPRODUCTDELIVERYOPTIONS = "view product delivery options";
export const ACTION_VIEWPRODUCTINFO = "view product information";
export const ACTION_VISUALIZER = "visualizer";

export const LABEL_BANNER = "banner";
export const LABEL_HELP = "help";
export const LABEL_LOGIN = "login";
export const LABEL_MINICART = "minicart";
export const LABEL_ORDERDIRECT = "order direct";
export const LABEL_STORES = "stores";

export const TITLE_DETAILS = 'appointments_details';
export const TITLE_SERVICE = 'appointments_service';
export const TITLE_CALENDAR = 'appointments_calendar';
export const TITLE_COMPLETE = 'appointments_complete';
export const TITLE_ERROR_BOOKING = 'appointments_error_booking';
export const TITLE_ERROR_AVAILABILITY = 'appointments_error_availability';