import {
    createStore,
    combineReducers,
    applyMiddleware,
    compose,
} from 'redux';
import thunk from 'redux-thunk';
import cart from '../Cart/Browser/reducer';
import localcart from '../Cart/Browser/LocalCart/reducer';
import wishListPage from '../../Features/WishList/reducer';
import checkout from '../Checkout/Browser/Components/CheckoutPage/reducer';
import checkoutPayment from '../Checkout/Browser/Components/CheckoutPage/Components/Payment/reducer';
import breakpoint from '../Shared/Reducers/breakpoint';
import overlay from '../Shared/Overlay/reducer';
import searchResults from '../Search/reducer';
import register from '../Registration/reducer';
import product from '../Product/Browser/Components/ProductPage/reducer';
import login from '../Login/reducer';
import password from '../Password/reducer';
import profile from '../Profile/reducer';
import facets from '../Category/Products/Facet/reducer';
import sortArea from '../Category/Products/SortArea/reducer';
import category from '../Category/reducer';
import requestCallback from '../Shared/Browser/Components/RequestCallbackModal/reducer';
import orderHistory from '../OrderHistory/Browser/reducer';
import ihcform from '../MoreIQ/Browser/Components/reducer';
import myConsultations from '../MyConsultations/Browser/Components/reducer';
import contactForm from '../ContactForm/Browser/Components/reducer';
import complaintsForm from '../ComplaintsForm/Browser/Components/reducer';
import menu from '../Shared/Header/reducer';
import quoteSearch from '../Quotes/Browser/Components/QuoteSearch/reducer';
import myQuotes from '../Quotes/Browser/Components/MyQuotes/reducer';
import orderSearch from '../OrderSearch/Browser/Components/OrderSearch/reducer';
import genericErrorModal from '../Shared/GeneralErrorModal/reducer';
import verifyAccount from '../VerifyAccount/Browser/Components/reducer';

import contactOptions from '../ContactOptions/reducer';


// Like `combineReducers` but merges an `Array` of reducers
export function mergeReducers({ reducers, initialState }) {
    return (state = initialState, action) => reducers.reduce((s, r) => r(s, action), state);
}

const composedCreateStore = compose(
    applyMiddleware(thunk),
    // This enables the Redux Dev Tools for Chrome:
    // https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd/related
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
)(createStore);

export default composedCreateStore(
    combineReducers({
        cart,
        localcart,
        checkout,
        checkoutPayment,
        searchResults,
        breakpoint,
        overlay,
        register,
        product,
        login,
        profile,
        password,
        sortArea,
        category,
        facets,
        requestCallback,
        orderHistory,
        menu,
        ihcform,
        myConsultations,
        contactForm,
        complaintsForm,
        genericErrorModal,
        quoteSearch,
        myQuotes,
        orderSearch,
        verifyAccount,
        contactOptions,
        wishListPage
    })
);
