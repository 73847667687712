import {
    ORDER_HISTORY_PENDING,
    ORDER_HISTORY_FULFILL,
    ORDER_HISTORY_REJECT,
} from 'Shared/action-types';

export const PENDING = 'PENDING';
export const FAILED = 'FAILED';
export const FULFILLED = 'FULFILLED';

const initialState = {
    status: FULFILLED,
    orders: window.ORDER_HISTORY,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ORDER_HISTORY_PENDING:
            return Object.assign({}, state, {
                status: PENDING,
            });
        case ORDER_HISTORY_REJECT:
            return Object.assign({}, state, {
                status: FAILED,
            });
        case ORDER_HISTORY_FULFILL:
            return Object.assign({}, state, {
                status: FULFILLED,
            });
        default:
            return state;
    }
}
