import { embed as embedGenericPageHeader } from './Shared/Header';
import { embed as embedGenericMinimalPageHeader } from './Shared/MinimalHeader';
// An Id must not be part of another Id: Example Checkout and CheckoutPage
const staticContainers = [
    {
        id: 'GenericPageHeader',
        embededWrapper: embedGenericPageHeader,
    },
    {
        id: 'MinimalGenericPageHeader',
        embededWrapper: embedGenericMinimalPageHeader,
    }
];


/**
 * ### allowIndividualRender ###
 * Since we load some containers static in main bundle and some on demand
 * with dynamic imports we will by default render the static containers first and then
 * each dynamic imported container once loaded. In order to get a better load experience
 * we are awaiting all dynamic containers on a page before rendering
 * any static or dynamic container. Some containers are less important to await and by
 * setting allowIndividualRender: true you can opt out from this sync rendering.
 * Example may be things out of viewport like RegistrationBlock.
 * While ProductPage is nice to render in sync with the header so it should be false.
 */

const dynamicContainers = [
    { id: 'BreadCrumbsBlock', allowIndividualRender: false },
    { id: 'FooterBlock', allowIndividualRender: true },
    { id: 'ProductPage', allowIndividualRender: false },
    { id: 'CheckoutPage', allowIndividualRender: false },
    { id: 'PageHeaderCheckout', allowIndividualRender: false },
    { id: 'MinimalGenericPageHeader', allowIndividualRender: false },
    { id: 'profileInfoWrapper', allowIndividualRender: true },
    { id: 'wishlistInfoWrapper', allowIndividualRender: true },
    { id: 'MinimalFooterBlock', allowIndividualRender: true },
    { id: 'NewsletterArea', allowIndividualRender: true },
    { id: 'Carousel', allowIndividualRender: true },
    { id: 'CmsNavigation', allowIndividualRender: true },
    { id: 'CategoryPage', allowIndividualRender: false },
    { id: 'ProductListBlock', allowIndividualRender: true },
    { id: 'ProductListTile', allowIndividualRender: true },
    { id: 'BannerBlock', allowIndividualRender: true },
    { id: 'PromotionPage', allowIndividualRender: false },
    { id: 'PromotionListingPage', allowIndividualRender: false },
    { id: 'OrderHistory', allowIndividualRender: false },
    { id: 'RegistrationBlock', allowIndividualRender: true },
    { id: 'MyAppointmentsPage', allowIndividualRender: false },
    { id: 'MoreIQPage', allowIndividualRender: false },
    { id: 'ContactForm', allowIndividualRender: true },
    { id: 'QuoteSearch', allowIndividualRender: false },
    { id: 'ComplaintsForm', allowIndividualRender: false },
    { id: 'MyQuotes', allowIndividualRender: false },
    { id: 'OrderSearch', allowIndividualRender: false },
    { id: 'Setpassword', allowIndividualRender: false },
    { id: 'VerifyAccountPage', allowIndividualRender: false },
    { id: 'NewsletterWithContentBlock', allowIndividualRender: true }
];


export const renderContainers = (waitForBeforeRender) => {
    loadDynamicContainers().then((dynamicContainerData) => {
        /** 
         *  Load dynamic containers early
         *  Wait for them to be loaded before we render
         *  both static and dynamic container in one go
         *  to avoid layout shifts when rendering.
         */
        if(waitForBeforeRender){
            waitForBeforeRender.then(() => {
                setTimeout(() => {
                    /** setTimout in order create a new frame and avoid long running frames */
                    renderStaticContainers();
                    renderDynamicContainers(dynamicContainerData);
                }, 0)
            })
        }
    });
};

const loadDynamicContainers = () => {
    let dynamicImports = [];
    dynamicContainers.forEach((container) => {
        const nodeList = document.querySelectorAll(`[id^='${container.id}']`);
        const multipleContainers = Array
            .prototype
            .slice
            .call(nodeList);

        if (multipleContainers && multipleContainers.length > 0) {
            multipleContainers.forEach(element => {
                let importCall = null;
                switch (container.id) {
                    /**
                     * Webpack doesn't allow us to use variable in import path like import(foo)
                     * and we don't want to make the import call until we are sure we need the module
                     * Hence we have to construct the import calls in a static was call by call in this ugly way.
                     */

                    case 'BreadCrumbsBlock':
                        importCall = import('./BreadCrumbs/Browser/Components/index');
                        break;
                    case 'ProductPage':
                        importCall = import('./Product/Browser/Providers/ProductPageProvider');
                        break;
                    case 'CheckoutPage': 
                        importCall = import('./Checkout/Browser/Providers/CheckoutPage');
                        break;
                    case 'PageHeaderCheckout':
                        importCall = import('./Checkout/Browser/Providers/CheckoutPageHeader');
                        break;
                    case 'MinimalGenericPageHeader':
                        importCall = import('./Shared/MinimalHeader');
                        break;
                    case 'profileInfoWrapper':
                        importCall = import('./Profile');
                        break;
                    case 'wishlistInfoWrapper':
                        importCall = import('./WishList/WishListPage');
                        break;
                    case 'FooterBlock':
                        importCall = import('./Footer/Browser/Components/index');
                        break;
                    case 'MinimalFooterBlock':
                        importCall = import('./Footer/Browser/Components/MinimalFooter/index');
                        break;
                    case 'NewsletterArea':
                        importCall = import('./Newsletter');
                        break;
                    case 'Carousel':
                        importCall = import('./Shared/Carousel');
                        break;
                    case 'CmsNavigation':
                        importCall = import('./Shared/CmsNavigation');
                        break;
                    case 'CategoryPage':
                        importCall = import('./Category');
                        break;
                    case 'ProductListBlock':
                        importCall = import('./ProductListBlock');
                        break;
                    case 'ProductListTile':
                        importCall = import('./ProductListTile');
                        break;
                    case 'BannerBlock':
                        importCall = import('./Banner');
                        break;
                    case 'PromotionPage':
                        importCall = import('./Promotion');
                        break;
                    case 'PromotionListingPage':
                        importCall = import('./PromotionListing');
                        break;
                    case 'OrderHistory':
                        importCall = import('./OrderHistory/Browser/Providers');
                        break;
                    case 'RegistrationBlock':
                        importCall = import('./Registration/Browser/Components/index');
                        break;
                    case 'MyAppointmentsPage':
                        importCall = import('./MyConsultations/Browser/Components');
                        break;                        
                    case 'MoreIQPage':
                        importCall = import('./MoreIQ/Browser/Components');
                        break;
                    case 'ContactForm':
                        importCall = import('./ContactForm/Browser/Components/index');
                        break;
                    case 'QuoteSearch':
                        importCall = import('./Quotes/Browser/Providers/quoteSearch');
                        break;
                    case 'ComplaintsForm':
                        importCall = import('./ComplaintsForm/Browser/Components/index');
                        break;                        
                    case 'MyQuotes':
                        importCall = import('./Quotes/Browser/Providers/myQuotes');
                        break;
                    case 'OrderSearch':
                        importCall = import('./OrderSearch/Browser/Providers/orderSearch');
                        break;
                    case 'Setpassword':
                        importCall = import('./Password/Browser/Providers/setpassword');
                        break;                        
                    case 'VerifyAccountPage':
                        importCall = import('./VerifyAccount/Browser/Components/index');
                        break;
                    case 'NewsletterWithContentBlock':
                        importCall = import('./ContentBlocks/Browser/Components/NewsletterWithContentBlock/index');
                        break;
                }

                if (importCall) {
                    dynamicImports.push({
                        id: container.id,
                        element: element,
                        allowIndividualRender: container.allowIndividualRender,
                        importCall: importCall,
                    });
                }

            })
        }
    });

    return new Promise((resolve) => {
        const containersToBerenderedTogetherWithStatic = dynamicImports.filter(c => !c.allowIndividualRender);

        return Promise.all(containersToBerenderedTogetherWithStatic.map(c => c.importCall)).then(() => {
            resolve(dynamicImports)    
        })
    });
}
const renderDynamicContainers = (dynamicContainers) => {
    dynamicContainers.forEach((container) => {
        container.importCall.then((module) => {
            module.embed(container.element)
        });
    });
}

export const renderStaticContainers = () => {
    staticContainers.forEach((element) => {
        const nodeList = document.querySelectorAll(`[id^='${element.id}']`);
        const multipleContainers = Array
            .prototype
            .slice
            .call(nodeList);      
        if (multipleContainers && multipleContainers.length > 0) {
            multipleContainers.forEach(el => element.embededWrapper(el));
        }
    });
};
