import { connect } from 'react-redux';
import { LoginPopup } from './LoginPopup';
import { toggleOverlay } from 'Shared/Overlay/actions';
import {
    resetLogin,
    getloginForm,
    toggleLogin,
    getPagesUrl,
    logout
} from 'Login/actions';

export default connect(
    ({ login, breakpoint, profile }) => ({
        data: login.data,
        accountPageUrl: login.accountPageUrl,
        visible: login.visible,
        success: login.success,
        breakpoint,
        isAuthenticated: profile.isAuthenticated,
        navigation: login.navigation,
    }),
    dispatch => ({
        onResetLogin: () => dispatch(resetLogin()),
        onGetLoginForm: () => dispatch(getloginForm()),
        onToggleLogin: (visible) => dispatch(toggleLogin(visible)),
        onToggleOverlay: (display) => dispatch(toggleOverlay(display)),
        onGetPagesUrl: () => dispatch(getPagesUrl()),
        onHandleLogout: () => dispatch(logout())
    })
)(LoginPopup);