import 'whatwg-fetch';
import 'babel-polyfill';

import '../Styles/globals.scss';

import './Login';
import './Password';
import './Market';

import store from './Shared/store';
import { updateBreakpointOnResize } from './Shared/breakpoints';
import { setPhrases } from './Shared/translate';
import { renderContainers } from './entryContainers';
import { loadScript } from './Shared/load-script';

setPhrases(window.LANGUAGE_PHRASES || {});

store.dispatch(updateBreakpointOnResize());

/**
 * Translations are loaded in a non-blocking way from _Layout.cshtml
 * We need to make sure they are loaded before we render react components.
 * So even if the request has already started and may be complete before
 * we hit loadScript() we still need to await it to be sure.
 * loadScript returns a Promise and renderContainers() will wait for the promise before rendering.
 */
const loadScriptPromise = loadScript(window.langOutputEndpoint);
setTimeout(() => {
    /** setTimout in order create a new frame and avoid long running frames */
    renderContainers(loadScriptPromise);
}, 0)
