import {
    FACET_EXPAND,
    FACET_GROUP_EXPAND,
} from 'Shared/action-types';

const initialState = {
    expanded: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case FACET_EXPAND:
        return Object.assign({}, state, {
            expanded: action.expand,
        });
    case FACET_GROUP_EXPAND:
        return Object.assign({}, state, {
            activeFacetGroup: action.group === state.activeFacetGroup ? '' : action.group,
        });
    default:
        return state;
    }
}
