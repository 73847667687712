import {
    SORTAREA_EXPAND,
} from 'Shared/action-types';

export function toggleExpandSortArea(expand) {
    return (dispatch) => {
        dispatch({
            type: SORTAREA_EXPAND,
            expand,
        });
    };
}
