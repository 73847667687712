import { 
    MYQUOTES_PENDING, 
    MYQUOTES_FULFILL, 
    MYQUOTES_REJECT,
    MYQUOTES_REDIRECT,
} from 'Shared/action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';
export const REDIRECT = 'REDIRECT';

const initialState = {
    status: FULFILLED,
    quotes: window.MY_QUOTES
};

export default function register(state = initialState, action) {
    switch (action.type) {
        case MYQUOTES_REDIRECT:
            return Object.assign({}, state, {
                status: REDIRECT,
            });
        case MYQUOTES_PENDING:
            return Object.assign({}, state, {
                status: PENDING,
            });
        case MYQUOTES_REJECT:
            return Object.assign({}, state, {
                status: REJECTED,
            });
        case MYQUOTES_FULFILL:
            return Object.assign({}, state, {
                status: FULFILLED,
            });
        default:
            return state;
    }
}


