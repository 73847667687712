// REFACTOR Should we keep the prefix? (LOCALCART) if so, why?
export const LOCALCART_ADD_ITEM = 'LOCALCART_ADD_ITEM';
export const LOCALCART_REMOVE_ITEM = 'LOCALCART_REMOVE_ITEM';
export const REMOVE_ITEMS = 'REMOVE_ITEMS';
export const LOCALCART_UPDATE_ITEM = 'LOCALCART_UPDATE_ITEM';
export const LOCALCART_EMPTY = 'LOCALCART_EMPTY';
export const SET_ITEM_QUANTITY = 'SET_ITEM_QUANTITY';
export const UPDATE_AREA = 'UPDATE_AREA';


