import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '../../../../../../../../Shared/translate';

// Components
import ServiceSection from '../ServiceSection';

export const UPLIFT_SECTION_ID = 'UPLIFT';
export const UPLIFT_TYPE_ID = 'uplift';
export const DISPOSAL_SECTION_ID = 'DISPOSAL';
export const DISPOSAL_TYPE_ID = 'disposal';
export const UPLIFTANDDISPOSALMINSKU = 'upliftanddisposalminsku';

export const Uplift = ({
    description,
    title,
    products,
    expanded,
    visible,
    selectedItems,
    onItemSelect,
    onItemDeselect,
    onSectionToggle,
    onSectionDiscard,
    onSectionConfirm,
    disabled,
    calculatedArea,
    onQuantityChange
}) => (
    <ServiceSection
        sectionId={UPLIFT_SECTION_ID}
        description={description}
        products={products}
        title={title || translate('/ProductPage/RelatedProducts/Uplift/Title')}
        expanded={expanded}
        visible={visible}
        selectedItems={selectedItems}
        addButtonLabel={translate('/ProductPage/Variant/AddButton')}
        discardSectionButtonLabel={translate('/ProductPage/RelatedProducts/Uplift/DiscardButton')}
        confirmButtonLabel={translate('/ProductPage/RelatedProducts/Uplift/ConfirmButton')}
        disabled={disabled}
        onItemSelect={onItemSelect}
        onItemDeselect={onItemDeselect}
        onSectionToggle={onSectionToggle}
        onSectionDiscard={onSectionDiscard}
        onSectionConfirm={onSectionConfirm}
        calculatedArea={calculatedArea}
        onQuantityChange={onQuantityChange}
    />
);

Uplift.propTypes = {
    products: PropTypes.array.isRequired,
    description: PropTypes.string,
    expanded: PropTypes.bool,
    visible: PropTypes.bool,
    selectedItems: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    onItemSelect: PropTypes.func.isRequired,
    onItemDeselect: PropTypes.func.isRequired,
    onSectionToggle: PropTypes.func.isRequired,
    onSectionDiscard: PropTypes.func.isRequired,
    onSectionConfirm: PropTypes.func.isRequired,
};
