import 'url-search-params-polyfill';

const HTTP_SUCCESS = 200;
const HTTP_CLIENT_ERROR = 400;

const acceptedBodyTypes = [
    Blob,
    FormData,
    URLSearchParams,
    ArrayBuffer,
];

function load(url, method, body, headers = {}) {
    // Fetch API pollyfilled with whatwg-fetch
    // Json stringify if `body` is truly but not a string nor an instance of any of the accepted types
    if (body && typeof body !== 'string' && acceptedBodyTypes.every(t => !t.prototype.isPrototypeOf(body))) {
        body = JSON.stringify(body);
    }
    const fixUrl = url.toLowerCase().replace(/([^:]\/)\/+/g, "$1");
    return fetch(fixUrl, {
        method,
        body,
        credentials: 'same-origin',
        headers: Object.assign({
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json; charset=utf-8',
        }, headers),
    });
}

export function isOK(status) {
    return HTTP_SUCCESS <= status && status < HTTP_CLIENT_ERROR;
}

export function json(response) {
    if (isOK(response.status)) {
        return response.json();
    } else {
        return response.json().then(j => Promise.reject({
            status: response.status,
            errors: Array.isArray(j.errors) ? j.errors : null,
        }));
    }
}

export function text(response) {
    if (isOK(response.status)) {
        return response.text();
    } else {
        return Promise.reject({
            status: response.status,
        });
    }
}

export function get(url) {
    return load(url, 'get');
}
export function getJson(url) {
    return get(url).then(json);
}
export function getText(url) {
    return get(url).then(text);
}

export function post(url, body) {
    return load(url, 'post', body);
}
export function postJson(url, body) {
    return post(url, body).then(json);
}
export function postText(url, body) {
    return post(url, body).then(text);
}
