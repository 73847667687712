import {
    SEARCH_PENDING,
    SEARCH_FULFILL,
    SEARCH_REJECT,
    SEARCH_UPDATE,
    TOGGLE_QUICKSEARCH,
    UPDATE_SEACHQUERY,
} from '../Shared/action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

const initialState = {
    status: FULFILLED,
    searchResults: [],
    show: false,
    searchQuery: window.CATEGORY_DATA && window.CATEGORY_DATA.searchTerm || '',
};

export default function login(state = initialState, action) {
    switch (action.type) {
    case SEARCH_PENDING:
        return Object.assign({}, state, {
            status: PENDING,
        });
    case SEARCH_FULFILL:
        return Object.assign({}, state, {
            status: FULFILLED,
        });
    case SEARCH_REJECT:
        return Object.assign({}, state, {
            status: REJECTED,
        });
    case SEARCH_UPDATE:
        return Object.assign({}, state, {
            searchResults: action.results,
        });
    case UPDATE_SEACHQUERY:
        return Object.assign({}, state, {
            searchQuery: action.searchQuery,
        });
    case TOGGLE_QUICKSEARCH:
        return Object.assign({}, state, {
            show: action.show,
        });
    default:
        return state;
    }
}
