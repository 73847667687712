import process from "process";

const production = !window.environment || window.environment !== 'development';


export default class ImageUrlHelper 
{
    static appendWitdhQueryParam = (imageUrl, width) => {
        if(production){
            return `/cdn-cgi/image/width=${width}${imageUrl}`;         
        }
        else{
            return `${imageUrl}?width=${width}`;
        }
    }

    static appendWitdhAndHeightQueryParam = (imageUrl, width, height) => 
    {
        console.log(`Env=${window.environment}`)
        if(production){
            return `/cdn-cgi/image/width=${width},height=${height}${imageUrl}`;            
        }
        else{
            return `${imageUrl}?width=${width}&height=${height}`;
        }
    }
}
