(function () {
   
    fetch('/static/appVersion.json')
    .then(response => response.json())
    .then(data => {
        const res = data.publishVersion;
        evaluatePublishVersion(res);
    });
    function evaluatePublishVersion(version) {
        const appVersionKey = "AppVersion";
        try {
            var test = (localStorage.length >= 0);
        } catch (e) {
            return;
        };

        let localAppVersion = localStorage.getItem(appVersionKey);
        if (version === localAppVersion) {
            return;
        };
               
        localStorage.setItem(appVersionKey, version);
    };
})();