const initialState = {
    success: false,
    status: 'FULFILL',
    activationToken: window.VERIFYACCOUNTPAGE && window.VERIFYACCOUNTPAGE.activationToken,
    email: window.VERIFYACCOUNTPAGE && window.VERIFYACCOUNTPAGE.email,
    sourceUrl: window.VERIFYACCOUNTPAGE && window.VERIFYACCOUNTPAGE.sourceUrl
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
