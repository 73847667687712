import {
    REQUEST_CALLBACK_PENDING,
    REQUEST_CALLBACK_FULFILL,
    REQUEST_CALLBACK_REJECT,
    REQUEST_CALLBACK_UPDATE,
    REQUEST_CALLBACK_RESET,
    RC_GETDATA_FULFILL
} from './action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

const initialState = {
    status: FULFILLED,
    success: false,
    data: {
        callBackTimes: []
    },
    message: ''
};

export default function register(state = initialState, action) {
    switch (action.type) {
    case REQUEST_CALLBACK_PENDING:
        return Object.assign({}, state, {
            status: PENDING,
        });
    case REQUEST_CALLBACK_FULFILL:
        return Object.assign({}, state, {
            status: FULFILLED,
        });
    case REQUEST_CALLBACK_REJECT:
        return Object.assign({}, state, {
            status: REJECTED,
            message: action.data,
        });
    case REQUEST_CALLBACK_UPDATE:
        return Object.assign({}, state, {
            data: action.data,
            success: action.success,
            message: initialState.message
        });
    case REQUEST_CALLBACK_RESET:
        return Object.assign({}, state, {
            data: {},
            success: false,
        });
    case RC_GETDATA_FULFILL:
        return Object.assign({}, state, {
            data: action.data,
        });
    default:
        return state;
    }
}


