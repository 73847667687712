export const EnumCatType = {
    Unknown: 0,
    Accessories: 1,
    ArtificialGrass: 2,
    Beds: 3,
    CarpetTiles: 4,
    Carpets: 5,
    EngineeredWood: 6,
    Headboards: 7,
    Laminate: 8,
    LuxuryVinylTiles: 9,
    Mattresses: 10,
    Rugs: 11,
    Underlay: 12,
    Vinyl: 13,
    DivanBeds: 14,
    Services: 15,
    BedAccessories: 16,
};

export const HardFlooringCategoryUrls = {
    Carpets: 'carpets',
    CarpetTiles: 'carpet-tiles',
    ArtificialGrass: 'artificial-grass',
    EngineeredWood: 'engineered-wood',
    Laminate: 'laminate',
    LuxuryVinylTiles: 'luxury-vinyl-tiles',
    Vinyl: 'vinyl'
};

export const GetCategory = {
    0: 'Unknown',
    1: 'Accessories',
    2: 'ArtificialGrass',
    3: 'Beds',
    4: 'CarpetTiles',
    5: 'Carpets',
    6: 'EngineeredWood',
    7: 'Headboards',
    8: 'Laminate',
    9: 'LuxuryVinylTiles',
    10: 'Mattresses',
    11: 'Rugs',
    12: 'Underlay',
    13: 'Vinyl',
    14: 'DivanBeds',
    15: 'Services',
    16: 'BedAccessories',
}

export const Measurements = {
    [EnumCatType.Carpets]: 'm<sup>2</sup>',
    [EnumCatType.Vinyl]: 'm<sup>2</sup>',
    [EnumCatType.LuxuryVinylTiles]: 'm<sup>2</sup>',
    [EnumCatType.Laminate]: 'm<sup>2</sup>',
    [EnumCatType.EngineeredWood]: 'm<sup>2</sup>',
    [EnumCatType.ArtificialGrass]: 'm<sup>2</sup>',
    [EnumCatType.Underlay]: 'm<sup>2</sup>',
}

//list of categories using w*l=<val>m2 for size
export const WxLArray = [
    EnumCatType.Carpets, EnumCatType.Vinyl, EnumCatType.ArtificialGrass, EnumCatType.Underlay
]

//list of categories using boxes 
export const BoxArray = [
    EnumCatType.Laminate, EnumCatType.LuxuryVinylTiles, EnumCatType.EngineeredWood
]