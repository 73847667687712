import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import { PENDING } from 'Login/reducer';
import { siteTheme, isTheme } from 'Shared/customfunctions';
import * as styles from './LoginForm.scss';
import * as ga4types from 'Shared/GA4/constants';
import { GA4FormTracking } from 'Shared/GA4/site-wide';


export function LoginForm(props) {

    let closeButton = window.location.origin + `/styles/icons/close-button-white.png`;
    const isThemeCRT = isTheme(siteTheme.CARPETRIGHT);


    const handleLoginClick = (e) => {
        e.preventDefault();
        const url = '/loginblock/login';
        const body = {
            'FormModel.email': props.email,
            'FormModel.password': props.password,
            'FormModel.returnurl': props.returnurl
        }
        props.onLogin(url, body, props.isCheckout, props.isRegistration, props.isNewsLetter);

        if (!props.email && !props.password) {
            GA4FormTracking(ga4types.LABEL_FORM_SIGNIN, ga4types.LABEL_FORM_ERROR, [translate("/Login/Form/Empty/Email"), translate("/Login/Form/Empty/Password")]);
        }

        if (props.success) {
            GA4FormTracking(ga4types.LABEL_FORM_SIGNIN, ga4types.LABEL_FORM_SUCCESS, "");
        }

        if (!props.email && props.password) {
            GA4FormTracking(ga4types.LABEL_FORM_SIGNIN, ga4types.LABEL_FORM_ERROR, translate("/Login/Form/Empty/Email"));
        }

        if (!props.password && props.email) {
            GA4FormTracking(ga4types.LABEL_FORM_SIGNIN, ga4types.LABEL_FORM_ERROR, translate("/Login/Form/Empty/Password"));
        }
    }

    const handleHideDiv = (e) => {
        let LoginBlockWrapper = document.getElementById("LoginBlockWrapper");
        let body = document.querySelector("body");
        LoginBlockWrapper.classList.add("hide");
        body.classList.remove("ReactModal__Body--open");
        body.classList.add("ReactModal__Body--closed");
    }


    return (
        <div className="col-md-12 col-sm-12" id="LoginBlockWrapper">
            {props.anonymous ? <h2 className="head-title">{translate("/Login/HeadingAnonymous")}</h2> : <h2 className="head-title">{translate("/Login/Heading")}</h2>}
            {props.anonymous && props.isNewsLetter ? <p>{translate("/Login/Form/ExistingUserAnonymousNewsletter")}</p> : <p>{translate("/Login/Form/ExistingUserAnonymous")}</p>}
            <div className="login-wrap">
                {props.newsletterSignupON && <div id={"closeBtn"} onClick={() => { handleHideDiv, window.location.reload() }} className={classnames(styles.clossButtonWrapper)}>
                    <img className={"closeBtn"} src={closeButton} alt="close" />
                </div>}
                <form action="/loginblock/login" role="form" method="post">
                    <input type='hidden' name="ReturnUrl" value="/" />
                    <div className="form-group">
                        <input
                            className={styles.inputEmail}
                            type="email"
                            name="Email"
                            placeholder={translate("/Login/Form/Label/Email")}
                            onChange={e => props.onChangeEmail(e.target.value)}
                            value={props.email}
                            data-ta='email-input-field'
                            autoFocus
                        />
                    </div>
                    <div className="form-group">
                        <input
                            className={styles.inputPassword}
                            type="password"
                            name="Password"
                            placeholder={translate("/Login/Form/Label/Password")}
                            onChange={e => props.onChangePassword(e.target.value)}
                            value={props.password}
                            data-ta='password-input-field'
                        />
                    </div>
                    <div className={classnames(
                        'popupErrorMessage',
                        'responseMessage',
                        'errorMessage',
                        { ['displayMessage']: props.error })
                    }>
                        <i className="icon ion-android-alert"></i>
                        <span className="message">
                            <React.Fragment>
                                {props.isMigratedUser ? translate("/Login/Form/Error/MigratedUser") : props.invalid ? translate("/Login/Form/Error/WrongPasswordOrEmail") : props.message}
                            </React.Fragment>
                        </span>
                    </div>
                    <div className={classnames('form-group bottom-area', styles.btnWrapper)}>
                        <button
                            type="submit" aria-label="Login"
                            className={classnames(
                                'btn btnprimary',
                                styles.btnLogin,
                                {
                                    ['loadingSpinner']: props.status === PENDING,
                                    ['btnprimary-fm']: !isThemeCRT
                                }
                            )}
                            disabled={props.status === PENDING}
                            onClick={handleLoginClick}
                            data-ta='login-modal-button'
                        >
                            <span className="spinner"></span>
                            <span className="text">{translate("/Login/Form/Button")}</span>
                        </button>
                    </div>
                    <div className={classnames(styles.forgotPassword, 'forgotPassword')}>
                        <a href={props.isCheckout ? `${props.forgotPasswordPageUrl}?p=c` : props.forgotPasswordPageUrl}
                            onClick={(e) => { e.preventDefault, GA4FormTracking(ga4types.LABEL_FORM_FORGOTPASSWORD, ga4types.LABEL_HEADER_LEVEL1) }}
                        >{translate("/Login/Form/ForgotPassword")}</a>
                    </div>
                    {!props.anonymous && <div className="create-account">
                        <h2 className="bottom-area">
                            {translate("/Login/Form/CreateAccount/Title")}
                        </h2>
                        <p>{translate("/Login/Form/CreateAccount/Description")}</p>
                        <a href={props.registrationPageUrl} className="btn btnprimary createAccountButton" onClick={(e) => { e.preventDefault, GA4FormTracking(ga4types.LABEL_FORM_CREATEACCONT, ga4types.LABEL_HEADER_LEVEL1) }}>
                            <span className="text">{translate("/Login/Form/CreateAccount/Button")}</span>
                        </a>
                    </div>}
                </form>
            </div>
        </div>
    );
}

LoginForm.propTypes = {
    forgotPasswordPageUrl: PropTypes.string.isRequired,
    registrationPageUrl: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    error: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    onChangeEmail: PropTypes.func.isRequired,
    onChangePassword: PropTypes.func.isRequired,
    onLogin: PropTypes.func.isRequired,
}