import React from 'react';
import { translate } from 'Shared/translate';

export default ({ item }) => (
    <React.Fragment>
        {item.size && <div><label>{translate('/Cart/Attributes/Size')}:</label><span>{item.size}</span></div>}
        {item.color && <div><label>{translate('/Cart/Attributes/Color')}:</label><span>{item.color}</span></div>}
        {item.style && <div><label>{translate('/Cart/Attributes/StorageOptions')}:</label><span>{item.style}</span></div>}
        {item.comfortRating && <div><label>{translate('/Cart/Attributes/ComfortRating')}:</label><span>{item.comfortRating}</span></div>}
    </React.Fragment>
);
