import {
    CREATE_JOB,    
    SET_ADDRESS,
    CHECK_SET_ADDRESS,
    GET_AVAILABLESLOTS,
    GET_AVAILABLESLOTSAPI,
    CREATE_BOOKING,
    GET_SELECTEDDATETIME,
    GET_USERDETAILS,  
    SET_DETAILS,
    SET_SCHEDULETIMESLOT,
    SET_COMPLETEBOOKING,
    IHC_FULLFILL,
    IHC_PENDING,
    SET_PRODUCTTYPE1
} from './action-types';

import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

const initialState = { 
    pending: false,
    address : window.IHCFORM_DATA && window.IHCFORM_DATA.addresses && window.IHCFORM_DATA.addresses.length > 0 && window.IHCFORM_DATA.addresses.find(x => x.isPrimary) || {
        country: '',
        street: '',
        streetNumber: '',
        city: '',
        postCode: '',
    },
    addresses : window.IHCFORM_DATA && window.IHCFORM_DATA.addresses,
    details:{
        email: window.IHCFORM_DATA && window.IHCFORM_DATA.email || '',
        title: window.IHCFORM_DATA && window.IHCFORM_DATA.title || '',
        firstName: window.IHCFORM_DATA && window.IHCFORM_DATA.firstName || '',
        lastName: window.IHCFORM_DATA && window.IHCFORM_DATA.lastName || '',
        telePhone: window.IHCFORM_DATA && window.IHCFORM_DATA.telePhone || '',
    },
    propertyDetails: {
        postCode: '',
        duration: '',
    },
    customerId: window.IHCFORM_DATA && window.IHCFORM_DATA.customerId,
    availableTitles: window.IHCFORM_DATA && window.IHCFORM_DATA.availableTitles,
    contactPreference: window.IHCFORM_DATA && window.IHCFORM_DATA.contactPreference ,
    typeOfService: null,
    selectedTypeOfService : '',
    guestCustomerId: null,
    guestAccessToken: null,
    contactPermission: window.IHCFORM_DATA && window.IHCFORM_DATA.contactPermission,
    availableSlots: null,
    jobId: null,
    scheduleTimeSlot: [],
    numberofRooms: window.IHCFORM_DATA && window.IHCFORM_DATA.numberofRooms,
    stairsTypes: window.IHCFORM_DATA && window.IHCFORM_DATA.stairsTypes,
    productTypes: window.IHCFORM_DATA && window.IHCFORM_DATA.productTypes,
    fittingTimeframes: window.IHCFORM_DATA && window.IHCFORM_DATA.fittingTimeframes,
    areaCount: window.IHCFORM_DATA && window.IHCFORM_DATA.areaCount,
    selectedDate: null,
    selectedSlot: null,
    hallStairsLanding: false,
    productName: '',
    availablePostCode: true,
    serviceablePostCode: true,
    isSetAddressCalled: false,
    unserviceableMessage: '',
    bookingComplete: null,
    planningWindowStartDate:null,
    appointmentBookingExpiration: window.IHCFORM_DATA && window.IHCFORM_DATA.appointmentBookingExpiration,
    links: window.IHCFORM_DATA && window.IHCFORM_DATA.links,
    logOutAfterBooking: window.IHCFORM_DATA && window.IHCFORM_DATA.logOutAfterBooking,
    postCodeFileExists: window.IHCFORM_DATA && window.IHCFORM_DATA.postCodeFileExists,
    bookingServiceNotAvailable: window.IHCFORM_DATA && window.IHCFORM_DATA.bookingServiceNotAvailable,
    calendarAdvice: window.IHCFORM_DATA && window.IHCFORM_DATA.calendarAdvice,
    calendarError: window.IHCFORM_DATA && window.IHCFORM_DATA.calendarError,
    calendarExpire: window.IHCFORM_DATA && window.IHCFORM_DATA.calendarExpire,
    reservedSlotExpire: window.IHCFORM_DATA && window.IHCFORM_DATA.reservedSlotExpire,
    calendarFooterText: window.IHCFORM_DATA && window.IHCFORM_DATA.calendarFooterText,
    notAvailableSlotsMessage1: window.IHCFORM_DATA && window.IHCFORM_DATA.notAvailableSlotsMessage1,
    notAvailableSlotsMessage2: window.IHCFORM_DATA && window.IHCFORM_DATA.notAvailableSlotsMessage2,
    confirmationTitle: window.IHCFORM_DATA && window.IHCFORM_DATA.confirmationTitle,
    confirmationMessage: window.IHCFORM_DATA && window.IHCFORM_DATA.confirmationMessage,
    confirmationImage: window.IHCFORM_DATA && window.IHCFORM_DATA.confirmationImage,
    confirmationUserAccountHelpText: window.IHCFORM_DATA && window.IHCFORM_DATA.confirmationUserAccountHelpText,
    serviceBlocksTitle: window.IHCFORM_DATA && window.IHCFORM_DATA.serviceBlocksTitle,
    otherServices: window.IHCFORM_DATA && window.IHCFORM_DATA.otherServices,
    sessionExpiredText: window.IHCFORM_DATA && window.IHCFORM_DATA.sessionExpiredText,
    planningWindowDays: window.IHCFORM_DATA && window.IHCFORM_DATA.planningWindowDays,
    earliestBookingDay: window.IHCFORM_DATA && window.IHCFORM_DATA.earliestBookingDay,
};

export default function reducer(state = initialState, action) {   
    const actionData = action.data;
    switch (action.type) {
        case CREATE_JOB:            
            return Object.assign({}, state, {
                jobId: action.data.jobId,
                planningWindowStartDate: action.data.planningWindowStartDate
            });
        case GET_AVAILABLESLOTS:            
            return Object.assign({}, state, {
                availableSlots: action.availableSlots,
                propertyDetails: action.data,
                jobId : action.jobId
            });

            case GET_AVAILABLESLOTSAPI:
                return Object.assign({}, state, {
                    availableSlots:action.availableSlots,
                    jobId:action.jobId                 
                });

        case GET_SELECTEDDATETIME:          
            return Object.assign({}, state, {
                selectedDate: `${moment(action.date).format('DD')}-${moment(action.date).format('MM')}-${action.date.getFullYear()}`,
                selectedSlot: action.selectedSlot,
                formattedDate : action.formattedDateval,
                formattedSlot : action.formattedSlotval
            });
            case GET_USERDETAILS:  
            return Object.assign({}, state, {
                formattedSlot : action.formattedSlotval
            });
        case SET_SCHEDULETIMESLOT: {
            let newState = cloneDeep(state);
            newState.scheduleTimeSlot.forEach(x => {
                if (x.name === action.data.name) {
                    x.selected = true;
                }
                else {
                    x.selected = false;
                }
            })
            return newState;
        }

        case SET_PRODUCTTYPE1:{
            let newState = cloneDeep(state);
            let itemToUpdate = newState.productTypes.find(x => x.name === action.data.name);
            newState.productTypes.forEach(x => {
                if (x.name === action.data.name) {
                    x.selected = !itemToUpdate.selected;
                }
            })
            return newState;
        }

        case SET_DETAILS:            
            return Object.assign({}, state, {
                details: action.data
            });
        case CREATE_BOOKING:{
            return Object.assign({}, state, {
                bookingComplete: action.data,
            });
        }
        case SET_COMPLETEBOOKING: {
            return Object.assign({}, state, {
                bookingComplete: action.data,
            });
        }
        case IHC_FULLFILL: {
            return Object.assign({}, state, {
                pending: false,
                availableSlots: action.data
            });
        }
        case IHC_PENDING: {
            return Object.assign({}, state, {
                pending: true,
            });
        }
        case SET_ADDRESS:{   
            return Object.assign({}, state, {
                availablePostCode: action.availablePostCode,
                serviceablePostCode: action.serviceablePostCode,
                unserviceableMessage: action.unserviceableMessage,
                isSetAddressCalled: true
            });
        }
        case CHECK_SET_ADDRESS:{   
            return Object.assign({}, state, {
                isSetAddressCalled: action.isSetAddressCalled
            });
        }
        default:
            return state;
    }
}

