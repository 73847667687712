import React, { useEffect, useRef } from 'react';
import CartItem, { types } from './Components/CartItem';
import classnames from 'classnames';
import * as styles from './CartItemList.scss';
import { translate } from 'Shared/translate';
import { objectIsEmpty } from 'Shared/customfunctions';
import { GAremoveProductsFromBasket } from 'Shared/GA/helpers';
import { GA4RemoveFromCart } from 'Shared/GA4/helpers';


export default function CartItemList(props) {

    const prevprops = useRef(props);
    useEffect(() => {
        if (prevprops.current.cartItems.length > props.cartItems.length) {
            const removedItems = prevprops.current.cartItems.filter((prevCartItem) =>
                !props.cartItems.find(curCartItem =>
                    curCartItem.lineId === prevCartItem.lineId
                )
            );
            if (removedItems && removedItems.length > 0) {
                GAremoveProductsFromBasket(removedItems);
                GA4RemoveFromCart(removedItems);
            }
        }
        prevprops.current = props;
    }, [props]);


    const extractMainCartItems = (items) => {
        const mainCartItems = items.filter((cartItem) => !cartItem.relatedTo && (cartItem.relatedToOrderLineId != undefined ? !cartItem.relatedToOrderLineId : true));
        mainCartItems.forEach((mainCartItem) => {
            mainCartItem.relatedCartItems = [];
        });
        return mainCartItems;
    }

    const extractRelatedCartItems = (items) => {
        return items.filter((cartItem) => cartItem.relatedTo || cartItem.relatedToOrderLineId);
    }

    const itemHasAccessories = (mainCartItem) => {
        return !objectIsEmpty(mainCartItem.relatedCartItems);
    }


    const {
        cartItems,
        cartType,
        breakpoint,
        isMobileView,
        cartIsPending,
        inStock,
        location,
        removeItem,
        isQuoteCart,
    } = props;

    const mainCartItems = extractMainCartItems(cartItems);
    const relatedCartItems = extractRelatedCartItems(cartItems);

    relatedCartItems.forEach((relatedCartItem) => {
        const mainCartItem = mainCartItems.find((item) => (
            item.lineId === relatedCartItem.relatedTo) || (item.orderLineId ? item.orderLineId === relatedCartItem.relatedToOrderLineId : false)
        );
        if (mainCartItem) {
            mainCartItem.relatedCartItems.push(relatedCartItem);
        }
    });


    return (
        <section className={classnames(styles.productList, styles[location])}>
            {mainCartItems && mainCartItems.map((mainCartItem) => (
                <div className={itemHasAccessories(mainCartItem) ? styles.hasAccessories : ''}
                    key={`itemgroup${mainCartItem.lineId}`}
                >
                    {/* Main product in cart */}
                    <CartItem
                        cartItem={mainCartItem}
                        cartIsPending={cartIsPending}
                        breakpoint={breakpoint}
                        isMobileView={isMobileView}
                        type={types.MAIN_ITEM}
                        location={location}
                        removeItem={(lineId) => removeItem(lineId, cartType)}
                        key={`${location}${mainCartItem.lineId}`}
                        isQuoteCart={isQuoteCart}
                    />

                    {/* Accessories list in cart */}
                    {mainCartItem.relatedCartItems && mainCartItem.relatedCartItems.length > 0 &&
                        (<div className={styles.relatedWrapper}>
                            <p className={styles.accessoryLabel}>
                                <label>{translate('/Cart/Accessories')}</label>
                            </p>
                            {mainCartItem.relatedCartItems.map(cartItem =>
                                <CartItem
                                    cartItem={cartItem}
                                    cartIsPending={cartIsPending}
                                    breakpoint={breakpoint}
                                    isMobileView={isMobileView}
                                    type={types.RELATED_ITEM}
                                    location={location}
                                    inStock={inStock}
                                    removeItem={(lineId) => removeItem(lineId, cartType)}
                                    key={`${location}${cartItem.lineId}`}
                                    isQuoteCart={isQuoteCart}
                                />
                            )}
                        </div>
                        )}
                </div>
            ))}
        </section>
    );
}
