import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    setPickUpStore,
} from '../../../../actions';
import { DELIVERY_PICKUP } from './index';
import { PENDING } from '../../../../reducer';
import { translate } from 'Shared/translate';
import * as styles from './PickUpStoreOptions.scss';
import classnames from 'classnames';

const PickUpStoreOptions = ({
    deliveryOption,
    isPending,
    options,
    selectedStoreId,
    selectedModeId,
    setPickUpStore,
    changeOptions,
    isQuoteCart,
    isFinanceCart,
    validateForm,
    handleStoreChange,
    pickUpStoreOption,
    pickUpStoreOptionTooltipTitle,
    pickUpStoreOptionTooltipContent,
    matchingDelivery,
    isSampleOnly
}) => {
    const [tooltip, setTooltip] = useState(false);
    const [showSelectDropdown, setShowSelectDropdown] = useState(false);

    useEffect(() => {
        validateForm();
    }, [selectedStoreId]);

    const setter = (shoppingCart) => {
        shoppingCart && handleStoreChange(shoppingCart.errorMessage);
    };

    const handleChange = (e) => {
        setPickUpStore(e.target.value)
            .then(result => setter(result));
    };

    let defaultOption;
    const error = "";
    const unitOfMeasure = "miles";
    if (!selectedStoreId) {
        const text = isPending ?
            translate('/Checkout/DeliveryMethods/PickupInStore/LoadingStores') :
            translate('/Checkout/DeliveryMethods/PickupInStore/ChooseStore');
        defaultOption = <option value="">{text}</option>;
    }

    const optionsList = options && options.map((o) => (
        <option key={o.orgUnitNumber} value={o.orgUnitNumber}>{o.orgUnitName} ({o.distance.toFixed(2)} {unitOfMeasure})</option>
    ));

    return (
        <React.Fragment>
            <div className={classnames(styles.pickupStores, { [styles.matchingDelivery]: !matchingDelivery })}>
                <button
                    className={classnames('btn', 'shippingoptionbtn', styles.shippingoptionbtn, deliveryOption.code === selectedModeId ? 'btnsecondary' : 'button-link')}
                    onClick={() => { changeOptions(deliveryOption.code); setShowSelectDropdown(true); }}
                    type="button" aria-label="DeliveryCharge"
                    disabled={isQuoteCart || isFinanceCart}
                >
                    <div className={styles.name}>{pickUpStoreOption}</div>
                    {!isSampleOnly && <div>£{Number(deliveryOption.deliveryCharge).toFixed(2)}</div>}
                </button>
                <div className={styles.tooltipWrapper}>
                    {pickUpStoreOptionTooltipTitle != null && pickUpStoreOptionTooltipContent != null ?
                        <div className={classnames(styles.tooltipContainer, 'tooltipContent')}>
                            <div
                                onMouseEnter={() => setTooltip(true)}
                                onMouseLeave={() => setTooltip(false)}
                                className={styles.infoCircle}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z" stroke="#404147" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 14V10M10 6H10.01" stroke="#404147" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>{pickUpStoreOptionTooltipTitle}</span>
                            </div>
                        </div> : null
                    }
                    {tooltip ? <div className={classnames(styles.tooltipContent, 'tooltipContent')}>{pickUpStoreOptionTooltipContent}</div> : null}
                </div>
            </div>
            {deliveryOption.code === selectedModeId &&
                <React.Fragment>
                    <select
                        className={styles.select}
                        value={selectedStoreId || ''}
                        onChange={e => e.target.value && handleChange(e)}
                        disabled={isQuoteCart || isFinanceCart}
                    >
                        {defaultOption}
                        {optionsList}
                    </select>
                </React.Fragment>
            }
        </React.Fragment>
    );
};

export default connect(
    ({ checkout }) => ({
        options: checkout.delivery.data.pickUpStores,
        isPending: checkout.delivery.status === PENDING,
        isSelectedPreferenceType: checkout.delivery.data.preference === DELIVERY_PICKUP,
        selectedStoreId: checkout.delivery.data.storeId,
        selectedModeId: checkout.delivery.data.modeId,

    }),
    dispatch => ({
        setPickUpStore: (...args) => dispatch(setPickUpStore(...args)),
    })
)(PickUpStoreOptions);