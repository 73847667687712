import { login } from './actions';
import { on } from '../Shared/events';
import store from '../Shared/store';
import { outerHTML } from '../Shared/dom';
import { invokeWhenUpdated } from '../Shared/state';
import serialize from 'form-serialize';

function render(state, previousState) {
    if (state.data !== previousState.data) {
        const loginBlock = document.getElementById('LoginBlockWrapper');
        !!loginBlock && outerHTML(loginBlock, state.data);
    }
}

// Keybindings and dispatchers
on('submit', '#LoginBlock', e => {
    e.preventDefault();
    store.dispatch(login('/loginblock/login', serialize(e.target, true)));
});

// The function `invokeWhenUpdated` only invokes `render` if the returned state has
// changed from the previous invokation. It passes the returned state as first argument and
// the returned state from the previous invokation as second argument. Then you have the
// ability to check diffs by compering those objects before you perform an update on the DOM.
//
// Note: The first time render will be invoked here is when a `store.dispatch` has been triggered
// and the returned state has been changed. At that first invokation, previous state will be the
// initial state.
store.subscribe(invokeWhenUpdated(state => state.login)(render));

