import {
    ADYEN_INITIALIZE,
    ADYEN_CARD_DETAILS_UPDATE,
    ADYEN_DROPIN,
    ADYEN_PAYLOAD,
    ADYEN_PSP_REFERENCE,
    STRIPE_INITIALIZE,
    STRIPE_CARDELEMENT,
    GIFTCARD_PENDING,
    GIFTCARD_FULFILL,
    GIFTCARD_REJECT,
    GIFTCARD_UPDATE,
    GIFTCARD_TOGGLE,
    LOYALTYCARD_PENDING,
    LOYALTYCARD_FULFILL,
    LOYALTYCARD_REJECT,
    LOYALTYCARD_UPDATE,
    LOYALTYCARD_TOGGLE,
    SELECTED_PAYMENT_METHOD_UPDATE,
    SELECTED_TERM_HITACHI_UPDATE,
    AVAILABLE_TERMS_HITACHI_UPDATE,
    SELECTED_TERM_PAYBYFINANCE_UPDATE,
    AVAILABLE_TERMS_PAYBYFINANCE_UPDATE,
    SELECTED_TERM_V12FINANCE_UPDATE,
    AVAILABLE_TERMS_V12FINANCE_UPDATE,
    PAYMENT_METHODS_UPDATE,
} from '../../../../../../Shared/action-types';
import {
    PENDING,
    FULFILLED,
    REJECTED,
} from '../../reducer';

export const initialState = {
    paymentMethods: 'PAYMENT_METHODS' in window && PAYMENT_METHODS || null,
    CardPaymentProvider: 'CARD_PAYMENT_PROVIDER' in window && CARD_PAYMENT_PROVIDER || null,
    environment: 'ENVIRONMENT' in window && ENVIRONMENT || null,
    clientKey: 'CLIENT_KEY' in window && CLIENT_KEY || null,
    paymentCardTypes: 'CHECKOUT_INFO' in window && CHECKOUT_INFO.checkoutInfo.availablePaymentCardTypes || null,
    financeProductsAvailable: 'CHECKOUT_INFO' in window && CHECKOUT_INFO.checkoutInfo.financeProductsAvailable || null,
    adyen: {
        paymentMethods: null,
        cardDetails: null,
        adyenDropin: null,
        adyenPayload: null,
        adyenPspReference: null,
    },
    stripeVersion: 'STRIPE_VERSION' in window && STRIPE_VERSION || null,
    stripe: {
        stripeClient: null,
        cardElement: null,
    },
    giftCard: {
        status: FULFILLED,
        isExpanded: false,
        data: {
            cardNumber: '',
            giftCardBalance: null,
            giftCardBalanceWithCurrency: '',
            giftCardUsageAmount: 0,
        },
    },
    loyaltyCard: {
        status: FULFILLED,
        isExpanded: false,
        data: {
            cardNumber: '',
            loyaltyCardBalance: null,
            loyaltyCardUsageAmount: 0,
        },
    },
    isSampleOnly: 'CHECKOUT_INFO' in window ? CHECKOUT_INFO.checkoutInfo.isSampleOnly : false,
    paybyfinance: {
        availableTerms: 'CHECKOUT_INFO' in window && CHECKOUT_INFO.checkoutInfo.financeProductsAvailable || null,
        selectedTerm: 'CHECKOUT_INFO' in window && (CHECKOUT_INFO.checkoutInfo.financeProductsAvailable.length > 0 && CHECKOUT_INFO.checkoutInfo.financeProductsAvailable[0].months) || '',
    },
    v12finance: {
        availableTerms: 'CHECKOUT_INFO' in window && CHECKOUT_INFO.checkoutInfo.financeProductsAvailable || null,
        selectedTerm: 'CHECKOUT_INFO' in window && CHECKOUT_INFO.checkoutInfo.financeProductsAvailable[0] || '',
    },
    selectedPaymentMethod: 'creditcard',
};

/* eslint-disable complexity */
export default function reducer(state = initialState, action) {
    switch (action.type) {

        case ADYEN_INITIALIZE:
            return Object.assign({}, state, {
                adyen: Object.assign({}, state.adyen, {
                    paymentMethods: Object.assign({}, state.adyen.paymentMethods, action.data)
                }),
            });

        case ADYEN_CARD_DETAILS_UPDATE:
            return Object.assign({}, state, {
                adyen: Object.assign({}, state.adyen, {
                    cardDetails: Object.assign({}, state.adyen.cardDetails, action.data)
                }),
            });

        case ADYEN_DROPIN:
            return Object.assign({}, state, {
                adyen: Object.assign({}, state.adyen, {
                    adyenDropin: Object.assign({}, state.adyen.adyenDropin, action.data)
                }),
            });

        case ADYEN_PAYLOAD:
            return Object.assign({}, state, {
                adyen: Object.assign({}, state.adyen, {
                    adyenPayload: Object.assign({}, state.adyen.adyenPayload, action.data)
                }),
            });

        case ADYEN_PSP_REFERENCE:
            return Object.assign({}, state, {
                adyen: Object.assign({}, state.adyen, {
                    adyenPspReference: Object.assign({}, state.adyen.adyenPspReference, action.data)
                }),
            });

        case STRIPE_INITIALIZE:
            return Object.assign({}, state, {
                stripe: Object.assign({}, state.stripe, {
                    stripeClient: Object.assign({}, state.stripe.stripeClient, action.data)
                }),
            });

        case STRIPE_CARDELEMENT:
            return Object.assign({}, state, {
                stripe: Object.assign({}, state.stripe, {
                    cardElement: Object.assign({}, state.stripe.cardElement, action.data)
                }),
            });

        case GIFTCARD_PENDING:
            return Object.assign({}, state, {
                giftCard: Object.assign({}, state.giftCard, {
                    status: PENDING,
                    pendingCardBalance: action.pendingCardBalance,
                    pendingAmount: action.pendingAmount,
                }),
            });
        case GIFTCARD_FULFILL:
            return Object.assign({}, state, {
                giftCard: Object.assign({}, state.giftCard, {
                    status: FULFILLED,
                    message: '',
                }),
            });
        case GIFTCARD_REJECT:
            return Object.assign({}, state, {
                giftCard: Object.assign({}, state.giftCard, {
                    status: REJECTED,
                    message: action.message,
                }),
            });
        case GIFTCARD_UPDATE:
            return Object.assign({}, state, {
                giftCard: Object.assign({}, state.giftCard, {
                    data: Object.assign({}, state.giftCard.data, action.data),
                }),
            });

        case GIFTCARD_TOGGLE:
            return Object.assign({}, state, {
                giftCard: Object.assign({}, state.giftCard, {
                    isExpanded: !state.giftCard.isExpanded,
                }),
            });


        case LOYALTYCARD_PENDING:
            return Object.assign({}, state, {
                loyaltyCard: Object.assign({}, state.loyaltyCard, {
                    status: PENDING,
                }),
            });
        case LOYALTYCARD_FULFILL:
            return Object.assign({}, state, {
                loyaltyCard: Object.assign({}, state.loyaltyCard, {
                    status: FULFILLED,
                    message: '',
                }),
            });
        case LOYALTYCARD_REJECT:
            return Object.assign({}, state, {
                loyaltyCard: Object.assign({}, state.loyaltyCard, {
                    status: REJECTED,
                    message: action.message,
                }),
            });
        case LOYALTYCARD_UPDATE:
            return Object.assign({}, state, {
                loyaltyCard: Object.assign({}, state.loyaltyCard, {
                    data: Object.assign({}, state.loyaltyCard.data, action.data),
                }),
            });
        case LOYALTYCARD_TOGGLE:
            return Object.assign({}, state, {
                loyaltyCard: Object.assign({}, state.loyaltyCard, {
                    isExpanded: !state.loyaltyCard.isExpanded,
                }),
            });
        case SELECTED_PAYMENT_METHOD_UPDATE:
            return Object.assign({}, state, {
                selectedPaymentMethod: action.data,
            });
        case SELECTED_TERM_HITACHI_UPDATE:
            return Object.assign({}, state, {
                hitachi: Object.assign({}, state.hitachi, {
                    selectedTerm: action.data,
                }),
            });
        case AVAILABLE_TERMS_HITACHI_UPDATE:
            return Object.assign({}, state, {
                hitachi: Object.assign({}, state.hitachi, {
                    availableTerms: action.data,
                }),
            });
        case SELECTED_TERM_PAYBYFINANCE_UPDATE:
            return Object.assign({}, state, {
                paybyfinance: Object.assign({}, state.paybyfinance, {
                    selectedTerm: action.data,
                }),
            });
        case AVAILABLE_TERMS_PAYBYFINANCE_UPDATE:
            return Object.assign({}, state, {
                paybyfinance: Object.assign({}, state.paybyfinance, {
                    availableTerms: action.data,
                }),
            });
        case SELECTED_TERM_V12FINANCE_UPDATE:
            return Object.assign({}, state, {
                v12finance: Object.assign({}, state.v12finance, {
                    selectedTerm: action.data,
                }),
            });
        case AVAILABLE_TERMS_V12FINANCE_UPDATE:
            return Object.assign({}, state, {
                v12finance: Object.assign({}, state.v12finance, {
                    availableTerms: action.data,
                }),
            });
        case PAYMENT_METHODS_UPDATE:
            return Object.assign({}, state, {
                paymentMethods: Object.assign({}, state.paymentMethods, action.data),
            });

        default:
            return state;
    }
}
