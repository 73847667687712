import {
    SEARCH_PENDING,
    SEARCH_FULFILL,
    SEARCH_REJECT,
    SEARCH_UPDATE,
    UPDATE_SEACHQUERY,
    TOGGLE_QUICKSEARCH,
} from '../Shared/action-types';
import { postJson } from '../Shared/server.js';

export function reset() {
    return (dispatch) => {

        dispatch({
            type: SEARCH_UPDATE,
            results: [],
        });

        dispatch({
            type: UPDATE_SEACHQUERY,
            searchQuery: '',
        });
    };
}

export function search(url, q) {
    return dispatch => {
        if (q) {
            q = q.trim();
            dispatch({ type: SEARCH_PENDING });
            return postJson(url, { q }).then(
                results => {
                    dispatch({ type: SEARCH_FULFILL });
                    dispatch({
                        type: SEARCH_UPDATE,
                        results: results.result,
                    });
                    dispatch({
                        type: TOGGLE_QUICKSEARCH,
                        show: results.result,
                    });

                    return results;
                },
                () => dispatch({ type: SEARCH_REJECT })
            );
        } else {
            dispatch(reset());
        }
    };
}

export function toggleQuickSearch(show) {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_QUICKSEARCH,
            show,
        });
    };
}

export function updateSearchQuery(query) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SEACHQUERY,
            searchQuery: query,
        });
    };
}
