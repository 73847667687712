const scripts = {};
const scriptElements = {};

export const loadScript = (url, callback) => {
    if (!(url in scripts)) {
        let element = document.createElement('script');
        element.src = url;
        if (callback) {
          element = callback(element);
        }
        scripts[url] = new Promise((resolve, reject) => {
          element.onload = () => {
            scriptElements[url] = element;
            resolve();
          };
          element.onerror = reject;
          document.body.appendChild(element);
        });
      }
      return scripts[url];
};
