import {
    SORTAREA_EXPAND,
} from 'Shared/action-types';

const initialState = {
    expanded: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case SORTAREA_EXPAND:
        return Object.assign({}, state, {
            expanded: action.expand,
        });
    default:
        return state;
    }
}
