import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { MEDIUM } from 'Shared/breakpoints';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import { Icon } from 'Shared/icon';
import CartItemList from '../CartItemList';
import { location } from '../CartItemList/Components/CartItem';
import * as styles from './MiniCart.scss';
import { GACustomEvent } from 'Shared/GA/helpers';
import { ACTION_CHECKOUT } from 'Shared/GA/constants';
import ServiceDisplay from '../ServiceDisplay';
import { siteTheme, isTheme } from 'Shared/customfunctions';
import { getCartCache, setCartCache } from '../cartHelper';


// REFACTOR Does this component need to be stateful?
export default function MiniCart(props) {

    const [cartAuthUpdating, setCartAuthUpdating] = useState(false);
    const [clicked, setClicked] = useState(false);

    // componentDidMount
    useEffect(() => {
        if (!isCheckout() && props.cart === null) {
            let cartCache = getCartCache();
            if (cartCache != null) {
                props.updateCartInfo(cartCache);
            }
            else {
                props.getCartItems();
                props.getCartItems();
            }
        }
        // minicartContent = document.getElementById('minicartContent');
    }, [])

    // componentDidUpdate
    const prevprops = useRef(props);
    useEffect(() => {
        const cartItemsExist = props.cart && props.cart.items || [];
        const prevCartCnt = prevprops.current.cart && prevprops.current.cart.items ? prevprops.current.cart.items.length : 0;
        const currentCartCnt = props.cart && props.cart.items ? props.cart.items.length : 0;
        if (prevprops.current.cart && currentCartCnt > prevCartCnt) {
            if (!props.miniCartVisible && !cartAuthUpdating) {
                props.toggleMiniCart(true);
                props.toggleOverlay(true);
            }
            if (cartAuthUpdating) {
                setCartAuthUpdating(false);
            }
        }
        if (!cartItemsExist.length && props.miniCartVisible) {
            props.toggleMiniCart(false);
            props.toggleOverlay(false);
        }
        if (props.isAuthenticated != prevprops.current.isAuthenticated) {
            setCartAuthUpdating(true);
            props.getCartItems();
        }
        setCartCache(JSON.stringify(props.cart));
        prevprops.current = props;
    }, [props]);


    const toggleMiniCart = () => {
        props.toggleMiniCart(!props.miniCartVisible);
        props.toggleOverlay(!props.miniCartVisible);
    }

    const itemsInCart = (arr, key) => {
        if (!arr) {
            return 0;
        }
        if (arr && arr.length < 1) {
            return 0;
        }
        return arr.filter(x => {
            return x.relatedTo === null
        }).length;
    }

    const removeTrailingSlash = (string) => {
        return string.replace(/\/$/, '');
    }

    const isCheckout = () => {
        const pathName = removeTrailingSlash(window.location.pathname);
        const checkouturl = removeTrailingSlash(props.checkouturl);
        return pathName.endsWith(checkouturl);
    }

    const prepareTotalCnt = (totalItemsInCart) => {
        const dp = 10;
        return totalItemsInCart < dp ? '0' : '';
    }

    const navigatingToCheckout = () => {
        setClicked(true);
        window.location.href = props.checkouturl;
    }


    if (isCheckout()) {
        return null;
    }

    const totalItemsInCart = props.cart ? itemsInCart(props.cart.items, 'productId') : 0;
    const minicartVisible = props.miniCartVisible && totalItemsInCart > 0;
    const isMobileTablet = props.breakpoint < MEDIUM;
    const isThemeCR = isTheme(siteTheme.CARPETRIGHT);

    return (
        <div className={classnames(styles.miniCart,
            minicartVisible && styles.displayCartContent
        )}>
            {/* Cart icon with total in header - on desktop screen */}
            <button aria-label="DontClose"
                className={classnames(styles.cartButton, 'jsDontClose')}
                onClick={toggleMiniCart}
            >
                <div className={classnames('icon', styles.cartIconArea)}>
                    {props.cart && totalItemsInCart > 0 && (
                        <div className={classnames(styles.countBadge, 'countBadgeFM')}>
                            <span>{totalItemsInCart}</span>
                        </div>
                    )}
                </div>
                {isThemeCR && <div className={styles.countTotal}>
                    <label className={styles.totalInHeader}>
                        {props.cart &&
                            (totalItemsInCart > 0
                                ? props.cart.subtotalWithCurrency
                                : translate('/Cart/EmptyCart'))}
                    </label>
                </div>}
            </button>

            {/* Cart popover modal */}
            <div
                className={classnames(
                    styles.cartContent,
                    'jsDontClose',
                    isMobileTablet && styles.cartContentMobile)}
            >
                {/* Cart close icon - on mobile screen */}
                <div className={styles.titleArea}>
                    <button aria-label="Close"
                        className={styles.iconButton}
                        onClick={toggleMiniCart}
                    >
                        <Icon size={'md-24'} className={'ion-android-close'} />
                    </button>
                </div>

                {/* Cart middle content - product and accessories list */}
                <div id="minicartContent" className={styles.cartMiddleContent}>
                    {props.cart && (
                        // REFACTOR "props.cart.items || []"" should be handled in a better way
                        <CartItemList
                            location={location.MINICART}
                            cartItems={props.cart.items || []}
                            update={props.update}
                            removeItem={(cartType, lineId) => props.remove(cartType, lineId)}
                            breakpoint={props.breakpoint}
                            cartIsPending={props.isPending}
                            cartType={props.cart.cartType}
                            isQuoteCart={props.cart.isQuoteCart}
                            isMobileView
                        />
                    )}
                </div>

                {/* Cart bottom content -total and checkout button */}
                <div className={styles.cartBottomContent}>
                    <div className={styles.totalSum}>
                        <span>{translate('/Cart/TotalSum')}</span>
                        <div className={classnames(styles.checkoutprice, "checkoutprice")}>
                            {totalItemsInCart > 0 ? props.cart.subtotalWithCurrency : 0}
                        </div>
                    </div>
                    <div onClick={() => GACustomEvent(ACTION_CHECKOUT)} >
                        <Button aria-label="CheckOut"
                            className={classnames("minicartCheckoutButton", styles.minicartCheckoutButton)}
                            fullWidth={true}
                            data_ta='checkout-mini-cart'
                            onClick={() => navigatingToCheckout()}
                            isPending={clicked === true}
                        >
                            {translate('/Header/MiniCart/Button/GoToCheckout')}
                        </Button>
                    </div>
                    <h2 className={styles.aprinfo}>
                        {props.cart && isTheme(siteTheme.CARPETRIGHT) &&
                            <span dangerouslySetInnerHTML={{ __html: props.cart.creditDescription }} />}
                    </h2>
                    <a onClick={() => toggleMiniCart()}>{props.linklabel}</a>
                    {props.cart &&
                        <ServiceDisplay isMiniCart serviceItems={props.cart.items || []} />
                    }
                </div>

            </div>
        </div>
    );
}
