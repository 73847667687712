import {
    MYCONSULTATIONS_CANCEL_APPOINTMENT,
    MYCONSULTATIONS_PENDING,
    MYCONSULTATIONS_FULFILL,
    
} from './action-types';
import cloneDeep from 'lodash/cloneDeep';

const initialState = {
    pending: false,
    consultations: window.MY_CONSULTATIONS && window.MY_CONSULTATIONS || [] ,
};

export default function reducer(state = initialState, action) {    
    const actionData = action.data;
    switch (action.type) {

        case MYCONSULTATIONS_PENDING: {
            return { ...state, pending: true }
        }
        case MYCONSULTATIONS_FULFILL: {
            return { ...state, pending: false }
        }
        case MYCONSULTATIONS_CANCEL_APPOINTMENT: {
            let newState = cloneDeep(state);
            let index = newState.consultations.findIndex(x => x.id === action.data);
            newState.consultations.splice(index, 1);
            return newState;
        }

        default:
            return state;
    }
}