import React from 'react';
import * as styles from './attributes.scss';

import BedAttributes from './beds';
import CarpetsAttributes from './carpets';
import VinylAttributes from './vinyl';
import RugAttributes from './rugs';


export default ({ item, categoryType }) => {
    const attributes = {
        Beds: <BedAttributes item={item} />,
        Carpets: <CarpetsAttributes item={item} />,
        Vinyl: <VinylAttributes item={item} />,
        Rugs: <RugAttributes item={item} />,
    };
    return (
    <div className={styles.productAttr}>
        {attributes[categoryType]}
    </div>
    );
};
