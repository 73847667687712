
import { DISPLAY_GENERIC_ERROR } from '../action-types';
import { HIDE_GENERIC_ERROR } from '../action-types';

const initialState = {
    active : false
};

export default function reducer(state = initialState, action) {
const actionData = action.data;
switch (action.type) {

    case DISPLAY_GENERIC_ERROR:
        return Object.assign({}, state, {
            active: true
        });
    case HIDE_GENERIC_ERROR:
        return Object.assign({}, state, {
            active: false
        });
    
    default:
        return state;
}
}