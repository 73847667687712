import { BREAKPOINT_UPDATE } from './action-types';
import { read } from './dom';

export const INIT = 0;
const INIT_WIDTH = 0;

export const MICRO = 1;
const MICRO_WIDTH = 511;

export const TINY = 2;
const TINY_WIDTH = 768;

export const SMALL = 3;
const SMALL_WIDTH = 980;

export const MEDIUM = 4;
const MEDIUM_WIDTH = 1024;

export const LARGE = 5;
const LARGE_WIDTH = 1280;

export const LARGER = 6;
const LARGER_WIDTH = 1366;

export const HUGE = 7;
const HUGE_WIDTH = 1600;

const breakpoints = [
    [INIT_WIDTH, INIT],
    [MICRO_WIDTH, MICRO],
    [TINY_WIDTH, TINY],
    [SMALL_WIDTH, SMALL],
    [MEDIUM_WIDTH, MEDIUM],
    [LARGE_WIDTH, LARGE],
    [HUGE_WIDTH, HUGE],
    [LARGER_WIDTH, LARGER],
];

breakpoints.reverse();

// Causes reflow, prefer using `currentBreakpointPromise`
export function currentBreakpoint() {
    const width = window.innerWidth;
    const current = breakpoints.find(b => width >= b[0]) || breakpoints[breakpoints.length - 1];
    return current[1];
}

export function currentBreakpointPromise() {
    return read(currentBreakpoint);
}

export function updateBreakpointOnResize() {
    let updateThrottled = false;
    return (dispatch, getState) => {
        window.addEventListener('resize', () => {
            if (!updateThrottled) {
                updateThrottled = true;
                currentBreakpointPromise().then(current => {
                    updateThrottled = false;
                    const previous = getState().breakpoint;
                    if (current !== previous) {
                        dispatch({
                            type: BREAKPOINT_UPDATE,
                            current,
                        });
                    }
                });
            }
        });
    };
}
