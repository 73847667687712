import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Megamenu from './megamenu';
import * as styles from './megamenu.scss';
import { MEDIUM } from 'Shared/breakpoints';
import { Icon } from 'Shared/icon';
import { translate } from 'Shared/translate';
import * as ga4types from 'Shared/GA4/constants';
import { GA4HeaderClick } from 'Shared/GA4/site-wide';
import { siteTheme, isTheme } from 'Shared/customfunctions';

const NavMenu = (props) => {
    const [state, setState] = useState({
        activeItem: 0,
        level: 0,
        levels: ['step1', 'step2', 'step3'],
        height: 'initial',
        isMobile: props.breakpoint < MEDIUM,
        essentialValue: false,
        offers: false
    });
    const currentTimeout = useRef(null);

    useEffect(() => {
        const updateIsMobile = () => {
            const newIsMobile = props.breakpoint < MEDIUM;
            if (state.isMobile !== newIsMobile) {
                resetLevel();
                if (newIsMobile) {
                    hideMenu();
                } else {
                    closeMenu();
                }
                setState(prevState => ({ ...prevState, isMobile: newIsMobile }));
            }
        };
        updateIsMobile();
    }, [props.breakpoint]);

    useEffect(() => {
        updateHeight();
    }, [state.level, state.activeItem]);

    const isDevice = () => navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i);

    const changeActiveItem = (e, activeItem, itemHasChildren) => {
        if (!itemHasChildren && !props.menuIsOpen) {
            props.handleToggleOverlay(false);
            return;
        }
        if (!itemHasChildren && props.menuIsOpen) {
            hideMenu();
            return;
        }
        if ((isDevice() && (!props.menuIsOpen || state.activeItem !== activeItem)) || state.isMobile) {
            e.preventDefault();
            document.activeElement.blur();
        }
        if (!state.isMobile) {
            clearTimeout(currentTimeout.current);
            currentTimeout.current = setTimeout(() => {
                if (state.activeItem !== activeItem) {
                    setState(prevState => ({ ...prevState, activeItem }));
                }
                openMenu();
            }, 300);
        } else {
            setState(prevState => ({ ...prevState, activeItem }));
        }
    };

    const nextLevel = (itemcnt) => {
        setState(prevState => ({ ...prevState, level: prevState.level + 1 }));
    };

    const prevLevel = () => {
        const newLevel = state.level - 1;
        setState(prevState => ({ ...prevState, level: newLevel }));
    };

    const resetLevel = () => {
        setState(prevState => ({ ...prevState, level: 0 }));
    };

    const updateHeight = (itemcnt) => {
        const activeItem = props.menuItems[state.activeItem];
        const itemHeight = 50;
        switch (state.level) {
            case 0:
                itemcnt = props.menuItems.length;
                break;
            case 1:
                itemcnt = activeItem.columnList.length;
                break;
        }
        setState(prevState => ({ ...prevState, height: (props.breakpoint < MEDIUM ? itemcnt * itemHeight : 'auto') }));
    };

    const itemHasChildrenFunc = (index) => {
        const { menuItems } = props;
        return menuItems[index].columnList.length > 0
            || menuItems[index].brandList.length > 0
            || menuItems[index].campaignList.length > 0
            ? true : false;
    };

    const openMenu = () => {
        if (!props.menuIsOpen) {
            props.handleToggleMenu(true);
        }
    };

    const closeMenu = () => {
        if (props.menuIsOpen) {
            props.handleToggleMenu(false);
        }
    };

    const hideMenu = () => {
        clearTimeout(currentTimeout.current);
        closeMenu();
    };

    const isMobileFunc = () => {
        return props.breakpoint < MEDIUM ? true : false;
    };

    const handleClickToggle = (e) => {
        var contentHeader = e.currentTarget;
        var content = contentHeader.nextElementSibling;
        contentHeader.classList.toggle(styles.active);
        if (content) {
            content.style.display = content.style.display === "flex" ? "none" : "flex";
        }
    };

    const handleLiClickToggle = (e) => {
        let essentialValueAccordionMainHeader = document.getElementById('essential_valueAccordionMainHeader');
        let offersAccordionMainHeader = document.getElementById('offersAccordionMainHeader');
        if (e.currentTarget.id === 'essential-value') {
            setState(prevState => ({ ...prevState, essentialValue: essentialValueAccordionMainHeader.classList.toggle('active') }));
        }
        if (e.currentTarget.id === 'offers') {
            setState(prevState => ({ ...prevState, offers: offersAccordionMainHeader.classList.toggle('active') }));
        }
    };

    return (
        <React.Fragment>
            <div id={styles.newMenuOn} className={classnames(styles.navigationwrapper, styles.newMenuOn)}>
                {state.isMobile && props.mobileHeader.length > 0 && (
                    <ul className={styles.mobilemenutop}>
                        {props.mobileHeader.map((item, index) =>
                            <li key={index}><a className={styles.action} href={item.mobileMenuLinkUrl}>
                                <div className="customIcon"><img src={item.mobileMenuLinkIcon} alt={item.text} /></div>
                                <label dangerouslySetInnerHTML={{ __html: item.mobileMenuLinkTitle }} />
                            </a></li>
                        )}
                    </ul>
                )}
                <ul className={classnames(styles.mainMenu, styles[state.levels[state.level]], 'mainMenu--loaded', 'mainMenu--content')}
                    {...!state.isMobile && { 'onMouseLeave': hideMenu }}
                >
                    {props.menuItems ? props.menuItems.map((item, index) => {
                        const itemHasChildren = itemHasChildrenFunc(index);
                        return (
                            <li onClick={(e) => handleLiClickToggle(e)} id={item.linkText.toLowerCase().replace(/(?!\w|\s)./g, '').replace(/\s+/g, ' ').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2').replace(/\s/g, '-')} className={classnames({
                                [styles.menuisactive]: (state.activeItem === index && props.menuIsOpen),
                                ['menuisactive']: (state.activeItem === index && props.menuIsOpen),
                            })}
                                style={{
                                    backgroundColor: item.linkTextBackgroundColor != null && !state.isMobile ? item.linkTextBackgroundColor : '',
                                    paddingLeft: item.linkTextBackgroundColor != null && !state.isMobile ? "10px" : "",
                                    paddingRight: item.linkTextBackgroundColor != null && !state.isMobile ? "10px" : "",
                                    borderRadius: item.linkTextBackgroundColor != null && !state.isMobile ? "5px" : "",
                                }}
                                key={item.linkText + index}
                                {...!state.isMobile && { 'onMouseEnter': e => changeActiveItem(e, index, itemHasChildren) }}
                            >
                                <div id={item.linkText.toLowerCase().replace(/(?!\w|\s)./g, ' ').replace(/\s+/g, ' ').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2').replace(/\s/g, '_') + `AccordionMainHeader`}
                                    {...((state.isMobile || isDevice()) && itemHasChildren) && { 'onClick': handleClickToggle }}
                                    className={classnames("ga_menuitem", "accordionHeader accordionMainHeader", styles.accordionHeader, styles.menulink, styles.accordionMainHeader,
                                        {
                                            [styles.nonexticon]: !itemHasChildren,
                                            [styles.essentialValueActive]: item.linkText.toLowerCase().replace(/(?!\w|\s)./g, ' ').replace(/\s+/g, ' ').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2').replace(/\s/g, '-') == 'essential-value',
                                            [styles.offersActive]: item.linkText.toLowerCase().replace(/(?!\w|\s)./g, ' ').replace(/\s+/g, ' ').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2').replace(/\s/g, '-') == 'offers'
                                        })}
                                >

                                    {!state.isMobile &&
                                        <a href={item.linkUrl || item.allLinkUrl}
                                            style={{ color: item.linkTextColor != null ? item.linkTextColor : '' }}
                                            onClick={(e) => { e.preventDefault, GA4HeaderClick(item.linkText, ga4types.LABEL_HEADER_LEVEL2) }}
                                            className={classnames("ga_menuitem", styles.menulink, {
                                                [styles.nonexticon]: !itemHasChildren,
                                            })}> {item.linkText} </a>
                                    }
                                    {state.isMobile && itemHasChildren &&
                                        <p onClick={(e) => { e.preventDefault, GA4HeaderClick(item.linkText, ga4types.LABEL_HEADER_LEVEL2) }} style={{ marginBottom: '0px' }}>{item.linkText}</p>
                                    }
                                    {state.isMobile && !itemHasChildren &&
                                        <a href={item.linkUrl || item.allLinkUrl} onClick={(e) => { item.linkText, e.preventDefault, GA4HeaderClick(item.linkText, ga4types.LABEL_HEADER_LEVEL2) }} style={{ marginBottom: '0px', width: '100%' }}>{item.linkText}</a>
                                    }
                                </div>
                                {itemHasChildren && (state.activeItem === index || isMobileFunc()) && (
                                    <div className={styles.bottomarea}>
                                        <Megamenu
                                            menuItem={props.menuItems[index]}
                                            linkText={item.linkText}
                                            nextLevel={(itemcnt) => nextLevel(itemcnt)}
                                            prevLevel={prevLevel}
                                            breakpoint={props.breakpoint}
                                            essentialValue={state.essentialValue}
                                            offers={state.offers}
                                        />

                                    </div>)
                                }
                            </li>
                        )

                    }
                    ) : 'There are no items in the menu...'}
                </ul>
                {state.isMobile && state.level > 0 && (
                    <div className={styles.viewallmobile}>
                        <a href={props.menuItems[activeItem].linkUrl || props.menuItems[activeItem].allLinkUrl} >
                            {translate('/Navigation/MegaMenu/ViewAll')} {props.menuItems[activeItem].linkText}
                            <Icon className={'ion-ios-arrow-forward'} />
                        </a>
                    </div>
                )}
                {state.isMobile &&
                    <div className={styles.mobilemenubottom}>
                        <ul>
                            {isTheme(siteTheme.CARPETRIGHT) ? <React.Fragment>{
                                props.usp && props.usp.slice(2).map((x, i) => {
                                    return <li key={`menulinks${i}`}>
                                        <a href={x.link} title={x.text}>{x.text}</a>
                                    </li>
                                })
                            }</React.Fragment> : <React.Fragment>{
                                props.usp && props.usp.map((x, i) => {
                                    return <li key={`menulinks${i}`}>
                                        <a href={x.link} title={x.text}>{x.text}</a>
                                    </li>
                                })
                            }</React.Fragment>
                            }
                            {props.usefulLinks && props.usefulLinks.map((x, i) => {
                                return <li key={`menulinks${i}`}>
                                    <a href={x.url} title={x.title}>{x.text}</a>
                                </li>
                            })}
                            {props.message &&
                                <li style={{ display: 'block', paddingTop: 12 + 'px' }} dangerouslySetInnerHTML={{ __html: props.message }}>
                                </li>}
                        </ul>
                    </div>
                }
            </div>
        </React.Fragment>
    );

};

export default connect(
    state => ({ breakpoint: state.breakpoint }),
    null
)(NavMenu);