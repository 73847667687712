export const CART_ADD_ITEMS_PENDING = 'CART_ADD_ITEMS_PENDING';
export const CART_ADD_ITEMS_UPDATE = 'CART_ADD_ITEMS_UPDATE';
export const CART_ADD_ITEMS_FULFILL = 'CART_ADD_ITEMS_FULFILL';
export const CART_ADD_ITEMS_REJECT = 'CART_ADD_ITEMS_REJECT';

export const CART_QUICKBUY_PENDING = 'CART_QUICKBUY_PENDING';
export const CART_QUICKBUY_UPDATE = 'CART_QUICKBUY_UPDATE';
export const CART_QUICKBUY_FULFILL = 'CART_QUICKBUY_FULFILL';
export const CART_QUICKBUY_REJECT = 'CART_QUICKBUY_REJECT';

export const CART_REMOVE_ITEM_PENDING = 'CART_REMOVE_ITEM_PENDING'; 
export const CART_REMOVE_ITEM_UPDATE = 'CART_REMOVE_ITEM_UPDATE'; 
export const CART_REMOVE_ITEM_FULFILL = 'CART_REMOVE_ITEM_FULFILL'; 
export const CART_REMOVE_ITEM_REJECT = 'CART_REMOVE_ITEM_REJECT'; 

export const COUPON_PENDING = 'COUPON_PENDING';
export const COUPON_UPDATE = 'COUPON_UPDATE';
export const COUPON_FULFILL = 'COUPON_FULFILL';
export const COUPON_REJECT = 'COUPON_REJECT';
