import {
    CATEGORYPAGE_UPDATE,
    CATEGORYPAGE_UPDATE_PRODUCTLIST,
} from '../Shared/action-types';

export const PENDING = 'PENDING';
export const UPDATING = 'UPDATING';
export const FAILED = 'FAILED';

const initialState = {
    data: window.CATEGORY_DATA || {},
    products: window.CATEGORY_DATA && window.CATEGORY_DATA.productViewModels || [],
};

export default function reducer(state = initialState, action) {
    const actionData = action.data;
    if (actionData && actionData.bannerData && !actionData.bannerData.title && !actionData.bannerData.description) {
        action.data.bannerData = state.data.bannerData;
    }
    switch (action.type) {
    case CATEGORYPAGE_UPDATE:
        return Object.assign({}, state, {
            data: action.data,
            products: action.products,
            recommendations: action.recommendations,
        });

    case CATEGORYPAGE_UPDATE_PRODUCTLIST:
        return Object.assign({}, state, {
            products: action.products,
        });
    default:
        return state;
    }
}
