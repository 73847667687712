import React from 'react';
import * as styles from './heading.scss';

const Heading = ({ title, rightContent, secureLogo }) => {
    return (
        <div className={styles.heading}>
            <h2>{title}</h2>
            {rightContent}
            {secureLogo}
        </div>
    );
};

export default Heading;
