import {
    ORDER_PENDING,
    ORDER_FULFILL,
    ORDER_REJECT,
    ORDER_UPDATE,
    AGREED_ON_TERMS,
    ORDER_FORM_HAS_ERRORS,
} from 'Shared/action-types';
import {
    PENDING,
    FULFILLED,
    REJECTED,
} from '../../reducer';

export const initialState = {
    status: FULFILLED,
    hasAgreedOnTerms: false,
    data: {
        orderNumber: null,
    },
    formHasErrors: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

    case ORDER_PENDING:
        return Object.assign({}, state, {
            status: PENDING,
        });
    case ORDER_FULFILL:
        return Object.assign({}, state, {
            status: FULFILLED,
            message: '',
        });
    case ORDER_REJECT:
        return Object.assign({}, state, {
            status: REJECTED,
            data: Object.assign({}, state.data, action.data),
        });
    case ORDER_UPDATE:
        return Object.assign({}, state, {
            data: Object.assign({}, state.data, action.data),
        });

    case AGREED_ON_TERMS:
        return Object.assign({}, state, {
            hasAgreedOnTerms: action.isChecked,
        });

    case ORDER_FORM_HAS_ERRORS:
        return Object.assign({}, state, {
            formHasErrors: action.formHasErrors,
            message: '',
            data: Object.assign({}, state.data, action.data),
            status: REJECTED,
        });

    default:
        return state;
    }
}
