import {
    LOGIN_PENDING,
    LOGIN_FULFILL,
    LOGIN_REJECT,
    LOGIN_UPDATE,
} from '../Shared/action-types';

import {
    RESET_DATA,
    GET_LOGIN_FORM,
    DISPLAY_LOGIN_POPUP,
    DISPLAY_ANONYMOUS_LOGIN_MODAL,
    HIDE_ANONYMOUS_LOGIN_MODAL,
    DISPLAY_LOGIN_MODAL,
    SUCCESS_LOGIN,
    GET_PAGES_URL,
    ONCHANGE_EMAIL_INPUT,
    ONCHANGE_PASSWORD_INPUT,
    UPDATE_ERROR,
} from './action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

const initialState = {
    status: FULFILLED,
    data: '',
    visible: false,
    isOpenLoginModal:false,
    success: false,
    navigation: [],
    forgotPasswordPageUrl: '',
    registrationPageUrl: '',
    accountPageUrl: '',
    email: '',
    password: '',
    error: false,
    isMigratedUser: false,
    isAnonymousLogin: false,
    message: '',
    invalid: false,
    showRegisterMsg: false,
    errorMsg: '',
    showSpinner: false
};

export default function login(state = initialState, action) {
    switch (action.type) {
    case LOGIN_PENDING:
        return Object.assign({}, state, {
            status: PENDING,
        });
    case LOGIN_FULFILL:
        return Object.assign({}, state, {
            status: FULFILLED,
        });
    case LOGIN_REJECT:
        return Object.assign({}, state, {
            status: REJECTED,
        });
    case LOGIN_UPDATE:
        return Object.assign({}, state, {
            data: action.data,
        });
    case GET_LOGIN_FORM:
        return Object.assign({}, state, {
            data: action.data,
        });
    case DISPLAY_LOGIN_POPUP:
        return Object.assign({}, state, {
            visible: action.visible,
        });
    case SUCCESS_LOGIN:
        return Object.assign({}, state, {
            success: action.success,
            email: '',
            password: '',
            isAnonymousLogin: action.isAnonymousLogin,
            message: action.message
        });
    case DISPLAY_ANONYMOUS_LOGIN_MODAL:
        return Object.assign({}, state, {
            isOpenLoginModal: action.isOpen ? action.isOpen : false,
            isCheckout: action.isCheckout,
            isAnonymousLogin: action.isAnonymousLogin,
            isMigratedUser: action.isMigratedUser,
            showRegisterMsg: action.showRegisterMsg,
            errorMsg: action.errorMsg,
            showSpinner: action.showSpinner,
            isNewsLetter: action.isNewsLetter
        });
    case HIDE_ANONYMOUS_LOGIN_MODAL:
    return Object.assign({}, state, {
        isOpenLoginModal: false,
    });
    case DISPLAY_LOGIN_MODAL:
        return Object.assign({}, state, {
            isOpenLoginModal: action.isOpen,
            isCheckout: action.isCheckout,
        });
    case GET_PAGES_URL:
        return Object.assign({}, state, {
            forgotPasswordPageUrl: action.forgotPasswordPageUrl,
            registrationPageUrl: action.registrationPageUrl,
            accountPageUrl: action.accountPageUrl,
            navigation: action.navigation
        });
    case ONCHANGE_EMAIL_INPUT:
        return Object.assign({}, state, {
            email: action.email,
            error: false,
        });
    case ONCHANGE_PASSWORD_INPUT:
        return Object.assign({}, state, {
            password: action.password,
            error: false,
        });
    case UPDATE_ERROR:
        return Object.assign({}, state, {
            status: REJECTED,
            error: action.error,
            isMigratedUser: action.isMigratedUser,
            message: action.message,
            invalid: action.invalid
        });

    default:
        return state;
    }
}
