import {
    PROFILEPAGE_PENDING,
    PROFILEPAGE_UPDATING,
    PROFILEPAGE_UPDATING_FIELD,
    PROFILEPAGE_FIELD_UPDATED,
    PROFILEPAGE_UPDATE_FINISHED,
    PROFILEPAGE_CLEAR_MESSAGE,
    PROFILEPAGE_STATES_UPDATE,
    PROFILEPAGE_UPDATE_ADDRESSLIST,
    PROFILEPAGE_UPDATE_SELECTED_ADDRESS,
    PROFILEPAGE_USER_IS_AUTHENTICATED,
    PROFILEPAGE_UPDATE_CONTACTPERMISSIONS,
    SHAREMYDATA_UPDATE
} from '../Shared/action-types';

export const PENDING = 'PENDING';
export const UPDATING = 'UPDATING';
export const FAILED = 'FAILED';

const initialState = {
    status: PENDING,
    states: [],
    data: window.PROFILE_PAGE ? window.PROFILE_PAGE : {
        firstName: '',
        lastName: '',
        titleId: '',
        email: '',
        phone: '',
        alternativePhone: '',
        newsletter: false,
        addresses: [],
        contactPermission:[],
        privacyNotice:''
    },
    addresses: window.PROFILE_PAGE && window.PROFILE_PAGE.Addresses || null,
    availableTitles: window.TITLES || null,
    loadingSpinner: false,
    message: '',
    success: false,
    isAuthenticated: window.IS_AUTHENTICATED,
    contactPermissionTitle: window.PROFILE_PAGE && window.PROFILE_PAGE.contactPermissionTitle,
    contactPermissionDescripton: window.PROFILE_PAGE && window.PROFILE_PAGE.contactPermissionDescripton,
    contactPermissionCheckBoxLabel: window.PROFILE_PAGE && window.PROFILE_PAGE.contactPermissionCheckBoxLabel,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PROFILEPAGE_UPDATING_FIELD:
            return Object.assign({}, state, {
                status: UPDATING,
                data: Object.assign({}, action.data),
                message: action.message,
            });
        case PROFILEPAGE_FIELD_UPDATED:
            return Object.assign({}, state, {
                status: PENDING,
            });
        case PROFILEPAGE_PENDING:
            return Object.assign({}, state, {
                status: PENDING,
            });
        case PROFILEPAGE_UPDATING:
            return Object.assign({}, state, {
                status: UPDATING,
                loadingSpinner: true,
                success: false,
                message: '',
            });
        case PROFILEPAGE_UPDATE_FINISHED:
            return Object.assign({}, state, {
                status: FAILED,
                loadingSpinner: false,
                data: Object.assign({}, action.data),
                message: action.message,
                success: action.success,
            });
        case PROFILEPAGE_STATES_UPDATE:
            return Object.assign({}, state, {
                states: action.states,
            });
        case PROFILEPAGE_UPDATE_SELECTED_ADDRESS:
            return Object.assign({}, state, {
                states: action.states,
            });
        case PROFILEPAGE_CLEAR_MESSAGE:
            return Object.assign({}, state, {
                message: '',
            });
        case PROFILEPAGE_UPDATE_ADDRESSLIST:
            return Object.assign({}, state, {
                data: Object.assign({}, action.data),
            });
        case PROFILEPAGE_USER_IS_AUTHENTICATED:
            return Object.assign({}, state, {
                isAuthenticated: action.isAuthenticated,
            });
        case PROFILEPAGE_UPDATE_CONTACTPERMISSIONS:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {
                    contactPermission: action.data,
                }),
            });
        case SHAREMYDATA_UPDATE:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, {
                    shareMyData: action.data
                })
            });
        default:
            return state;
    }
}
