// Helper functions to prevent layout thrashing. Always use these helper functions when
// the conditions on this page are meet: https://gist.github.com/paulirish/5d52fb081b3570c81e3a
// Use `read(() => ...)` when you are about to read something from the DOM.
// Use `write(() => ...)` when you are about to write something to the DOM.
// Both functions returns a promise and resolves with the returned value from the passed callback.
// E.g: `read(() => document.body.style.background).then(color => write(() => document.body.style.color = color))`

const reads = [];
const writes = [];
let scheduled = false;

function flush() {
    scheduled = false;
    [reads, writes].forEach(queue => {
        let task;
        while ((task = queue.shift())) {
            task();
        }
    });
}

function add(fn, queue) {
    if (!scheduled) {
        scheduled = true;
        requestAnimationFrame(() => {
            flush();
        });
    }
    return new Promise((resolve, reject) => queue.push(() => {
        try {
            resolve(fn());
        } catch (e) {
            reject(e);
        }
    }));
}

export function read(fn) {
    return add(fn, reads);
}

export function write(fn) {
    return add(fn, writes);
}

// DOM manipulators which has performance in mind

export function replace(el, newEl) {
    return write(() => {
        const parent = el.parentElement;
        if (parent) {
            parent.replaceChild(newEl, el);
            return newEl;
        }

        return Promise.reject(
            'Element is not able to be replaced. This may happen if the element isn\'t attached to DOM, is ' +
            'replaced on previous tick or is root element.'
        );
    });
}

export function innerHTML(el, html) {
    const newEl = el.cloneNode(false);
    newEl.innerHTML = html;
    return replace(el, newEl);
}

export function outerHTML(el, html) {
    const fakeEl = document.createElement('div');
    fakeEl.innerHTML = html;
    const frag = document.createDocumentFragment();
    while (fakeEl.hasChildNodes()) {
        frag.appendChild(fakeEl.firstChild);
    }
    replace(el, frag);
}

// Element traversing

export function closest(el, match) {
    while (el) {
        const parent = el.parentElement;
        if (match(parent)) {
            return parent;
        }
        el = parent;
    }

    return null;
}

export function matches(el, selector) {
    if(el.msMatchesSelector || el.matches) {
        return (el.msMatchesSelector || el.matches).call(el, selector);
    }
}
