import { combineReducers } from 'redux';

import address from './Components/DeliveryAddress/reducer';
import delivery from './Components/DeliveryMethod/reducer';
import payment from './Components/Payment/reducer';
import finalizeOrder from './Components/FinalizeOrder/reducer';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

export default combineReducers({
    address,
    delivery,
    payment,
    finalizeOrder,
});
