import {
    CONTACTFORM_UPDATE_CONTACT_PERMISSION ,
    CONTACTFORM_PENDING,
    CONTACTFORM_FULFILL,
    CONTACTFORM_ERROR,

} from './action-types';
const initialState = {
    success: false,
    status: 'FULFILL',
    contactPermission: window.CONTACTFORM && window.CONTACTFORM.contactPermission,
    links: window.CONTACTFORM && window.CONTACTFORM.links,
    blockData: window.CONTACTFORM && window.CONTACTFORM.blockData,
    message: ''
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case CONTACTFORM_UPDATE_CONTACT_PERMISSION:{
            return Object.assign({}, state, {
                contactPermission: action.data,
            });
        }
        case CONTACTFORM_PENDING: {
            return Object.assign({}, state, {
                status: 'PENDING',
            });
        }
        case CONTACTFORM_ERROR: {
            return Object.assign({}, state, {
                status: 'ERROR',
                message: action.data
            });
        }
        case CONTACTFORM_FULFILL: {
            return Object.assign({}, state, {
                status: 'FULFILL',
                success: action.success,
                message: action.message
            });
        }
        default:
            return state;
    }
}
