import React from 'react';
import classnames from 'classnames';
import Spinner from './Spinner';
import * as styles from './../../Styles/_buttons.scss';

export const appearanceTypes = {
    extend: 'EXTEND',
};

export default (props) => {
    const passProps = Object.assign({}, props);
    const buttonClass = passProps.isSecondary ? styles.btnsecondary : styles.btnprimary;
    const { fullWidth, data_ta} = passProps;

    delete passProps.appearance;
    delete passProps.isPending;
    delete passProps.isSecondary;
    delete passProps.fullWidth;
    delete passProps.className;

    const classname = `${styles.btn} ${buttonClass} ${props.className || ''} ${fullWidth ? styles.buttonFullWidth : ''}`;

    if (passProps.href) {
        return (
            <a className={classnames(styles.btn, styles.btnwithurl, buttonClass, passProps.className)}
                href={passProps.href}
                data-ta={data_ta}>
                {passProps.children}
            </a>
        );
    }

    return (
        <button aria-label="Spinner"
            data-ta={data_ta}
            className={classname}
            {...passProps}
            type={passProps.type || 'button'}
        >
            {(passProps.appearance === appearanceTypes.extend) ? (
                <span className={styles.spinnerExtend}>
                    {passProps.children}
                    {props.isPending && <Spinner extend />}
                </span>
            ) : (
                <span className={styles.spinnerReplace}>
                    <span className={props.isPending ? styles.hiddenText : null}>{passProps.children}</span>
                    {props.isPending && <Spinner />}
                </span>
            )}
        </button>
    );
};
