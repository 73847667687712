import React from 'react';
import * as styles from './AccountNav.scss';
import { translate } from 'Shared/translate';
import { GACustomEvent } from 'Shared/GA/helpers';
import { ACTION_MYACCOUNT } from 'Shared/GA/constants';
import * as ga4types from 'Shared/GA4/constants';
import { GA4HeaderClick } from 'Shared/GA4/site-wide';
import { resetCartCache } from 'Cart/Browser/Components/cartHelper';


function AccountNav(props) {

    const links = props.navigation;

    const handleLogoutClick = (e) => {
        let newState = JSON.parse(window.localStorage.getItem('_ihcState'));
        if (newState != null) {
            localStorage.removeItem('_ihcState');
            window.location.reload(false);
        }
        e.preventDefault();
        GACustomEvent(ACTION_MYACCOUNT, 'Logout');
        GA4HeaderClick(ga4types.LABEL_ACCOUNT_MENU_LOGOUT, ga4types.LABEL_HEADER_LEVEL1);
        var wish_list = localStorage.getItem('_wishList');
        var wishList = wish_list !== 'undefined' && wish_list !== null ? JSON.parse(wish_list) : null;
        if (wishList) {
            localStorage.removeItem('_wishList');
        }
        resetCartCache();
        window.location = '/login/signout';
        localStorage.setItem('successLogin', 'false');
        localStorage.setItem('firstName', '');
    }


    return (
        <ul class={styles.accountNavList}>
            {
                links.map(link =>
                    <li className={styles.navItem} key={link.name}>
                        <div className={styles.innerWrapper}>
                            <a href={link.link} onClick={() => { GACustomEvent(ACTION_MYACCOUNT, link.name), GA4HeaderClick(link.name, ga4types.LABEL_HEADER_LEVEL1) }}>
                                <span>{link.name}</span>
                            </a>
                        </div>
                    </li>
                )
            }
            <li className={styles.navItem}>
                <div className={styles.innerWrapper}>
                    <a onClick={handleLogoutClick} >
                        <span>{translate('/Logout')}</span>
                    </a>
                </div>
            </li>
        </ul>
    );
}

export default AccountNav;
