import {
    WISHLIST_ADDINGTOCART,
    WISHLIST_ADDEDTOCART,
    WISHLIST_REMOVE_FROM_WISHLIST,
    WISHLIST_REMOVED_FROM_WISHLIST,
    WISHLIST_DISABLE_WISHLIST_BUTTON,
} from './../Shared/action-types';

export const ADDING = 'ADDING';
export const REMOVING = 'REMOVING';
export const PENDING = 'PENDING';

const initialState = {
    status: PENDING,
    cartInfo: window.CART_INFO || {},
    data: window.WISHLIST_PRODUCTS || null,
    loadingSpinner: false,
    message: '',
    success: false,
    checkoutUrl: window.CHECKOUT_URL || '',
    wishlistButtonDisabled: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case WISHLIST_ADDINGTOCART:
        return Object.assign({}, state, {
            status: ADDING,
        });
    case WISHLIST_ADDEDTOCART:
        return Object.assign({}, state, {
            status: PENDING,
        });
    case WISHLIST_REMOVE_FROM_WISHLIST:
        return Object.assign({}, state, {
            status: REMOVING,
        });
    case WISHLIST_REMOVED_FROM_WISHLIST:
        return Object.assign({}, state, {
            data: action.data,
            message: action.message,
            success: action.success,
            status: PENDING,
        });
    case WISHLIST_DISABLE_WISHLIST_BUTTON:
        return Object.assign({}, state, {
            wishlistButtonDisabled: action.disable,
        });
    default:
        return state;
    }
}
