import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Section.scss';
import { GACustomEvent } from 'Shared/GA/helpers';

export const SECTION_EXPANDED = 1;
export const SECTION_COLLAPSED = 2;

const Section = ({
    disabled,
    expanded: initialExpanded,
    externalTabs,
    id,
    title,
    totalPrice,
    onSectionToggle,
    children,
    products,
    calculatedArea,
    onQuantityChange
}) => {
    const [expanded, setExpanded] = useState(initialExpanded || false);
    const articleWrap = useRef(null);
    const article = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (articleWrap.current && expanded) {
                setHeight(true);
            }
        };

        window.addEventListener('resize', handleResize);

        if (initialExpanded) {
            setTimeout(() => { setHeight(initialExpanded); }, 1000);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [expanded, initialExpanded]);

    useEffect(() => {
        if (initialExpanded !== undefined && expanded !== initialExpanded) {
            toggle(initialExpanded);
        }

        if (disabled && expanded) {
            toggle(false);
        }
    }, [initialExpanded, disabled, expanded]);

    useEffect(() => {
        if (products && calculatedArea) {
            products.forEach(product => {
                onQuantityChange(product.code, calculatedArea, product.price, product.type);
            });
        }
    }, [products, calculatedArea, onQuantityChange]);

    const handleResize = () => {
        if (articleWrap.current && expanded) {
            setHeight(true);
        }
    };

    const handleToggleExpand = () => {
        const isExpanded = expanded;

        toggle(!isExpanded);
        if (!isExpanded) {
            GACustomEvent(title);
        }
        if (onSectionToggle) {
            onSectionToggle(id);
        }
    };

    const setHeight = (isExpanded) => {
        if (id !== 'ACCESSORY' && articleWrap.current) {
            articleWrap.current.style.height = isExpanded ? `${article.current.scrollHeight}px` : 0;
        }
    };

    const toggle = (expand) => {
        if (expanded !== expand) {
            setHeight(expand);
            setExpanded(expand);
        }
    };

    return (
        <div
            {...id && { 'id': id }}
            className={classnames(styles.sectionContainer, {
                [styles.externalTabs]: externalTabs,
            })}
        >
            <div className={classnames(styles.section, { [styles.expanded]: expanded })}>
                <div className={styles.headWrapper}>
                    <button aria-label="toggle" className={
                        classnames(
                            styles.toggleButton, {
                            [styles.toggleButton__expanded]: expanded,
                            [styles.disabled]: disabled,
                        })
                    }>toggle</button>
                    <div
                        className={disabled
                            ? classnames(styles.sectionHead, styles.disabled)
                            : styles.sectionHead}
                        {...!disabled && { 'onClick': () => handleToggleExpand(title) }}
                    >
                        <div className={classnames(styles.textWrapper)}>
                            <span className={styles.headTitle}>
                                {title}
                            </span>
                            <span className={styles.val}>{externalTabs && totalPrice}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.articleWrap} ref={articleWrap}>
                    <div className={styles.article} ref={article}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

Section.propTypes = {
    disabled: PropTypes.bool,
    expanded: PropTypes.bool,
    externalTabs: PropTypes.bool,
    id: PropTypes.string.isRequired,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired,
    totalPrice: PropTypes.string,
    onSectionToggle: PropTypes.func,
    products: PropTypes.array,
    calculatedArea: PropTypes.any,
    onQuantityChange: PropTypes.func
};

export default Section;