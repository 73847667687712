import React from 'react';

const PageBreadcrumbs = ({ breadcrumbs }) => {
    const pathName = window.location.pathname === '/';
    if (pathName) {
        return null;
    }
    return (
        <div className="page-breadcrumbs container">
            <div className="row">
                <div className="col-md-12">
                    <ul className="breadcrumb-list">
                        {breadcrumbs.map((breadcrumb, i) => (
                            <li key={`crumb${breadcrumb.linkText}`} className="breadcrumb-list-item">
                                {i === 0 ? <a href={breadcrumb.linkUrl}>home</a> : <a href={breadcrumb.linkUrl}>{breadcrumb.linkText}</a>}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default PageBreadcrumbs;
