import React from 'react';
import { render } from 'react-dom';
import MinimalHeader from './genericheader';
import store from '../store';
import { Provider } from 'react-redux';

export function embed(container) {  
    const mainMenuJson = JSON.parse(container.dataset.mainmenujson);
    const breadcrumbs = JSON.parse(container.dataset.breadcrumbs);  
    render((
        <Provider store={store}>
            <MinimalHeader
                mainMenuJson={mainMenuJson}
                breadcrumbs={breadcrumbs}
            />
        </Provider>
    ), container);
}
