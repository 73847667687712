import {
    DELIVERY_PENDING,
    DELIVERY_FULFILL,
    DELIVERY_REJECT,
    DELIVERY_UPDATE,
} from '../../../../../../Shared/action-types';
import {
    PENDING,
    FULFILLED,
    REJECTED,
} from '../../reducer';
import { DELIVERY_SHIPPING } from './index';

export const initialState = {
    status: FULFILLED,
    errors: [],
    data: {
        deliveryPreferences: 'CHECKOUT_INFO' in window
            && CHECKOUT_INFO.checkoutInfo.deliveryPreferences
            && CHECKOUT_INFO.checkoutInfo.deliveryPreferences.mainDeliveryPreferenceTypes
            || null,
        preference: DELIVERY_SHIPPING,
        modeId: 'CHECKOUT_INFO' in window
            && CHECKOUT_INFO.checkoutInfo.modeId
            || null,
        storeId: 'CHECKOUT_INFO' in window
            && CHECKOUT_INFO.checkoutInfo.storeId
            || null,
        shippingOptions: [],
        pickUpStores: [],
        customErrorMessages: null,
        deliveryModesLoadingMessage: 'CHECKOUT_INFO' in window
        && CHECKOUT_INFO.checkoutInfo.deliveryModesLoadingMessage
        || null,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case DELIVERY_PENDING:
        return Object.assign({}, state, {
            status: PENDING,
        });
    case DELIVERY_FULFILL:
        return Object.assign({}, state, {
            status: FULFILLED,
        });
    case DELIVERY_REJECT:
        return Object.assign({}, state, {
            status: REJECTED,
            errors: !action.error.errors ? [...action.error] : [...action.error.errors],
        });
    case DELIVERY_UPDATE:
        return Object.assign({}, state, {
            data: Object.assign({}, state.data, action.data),
        });

    default:
        return state;
    }
}
