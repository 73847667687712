import { 
    ORDERSEARCH_PENDING, 
    ORDERSEARCH_FULFILL, 
    ORDERSEARCH_REJECT
} from './action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

const initialState = {
    order: null,
    status: FULFILLED,
    success: true,
    pageTitle: window.ORDERSEARCH && window.ORDERSEARCH.pageTitle
};

export default function register(state = initialState, action) {
    switch (action.type) {
    case ORDERSEARCH_PENDING:
        return Object.assign({}, state, {
            status: PENDING
        });
    case ORDERSEARCH_FULFILL:
        return Object.assign({}, state, {
            status: FULFILLED,
            success: action.success,
            order: action.order,

        });
    case ORDERSEARCH_REJECT: 
        return Object.assign({}, state, {
            status: FULFILLED,
            success: action.success,
            order: action.order
        });
    default:
        return state;
    }
}


