import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from 'Shared/icon';
import LoginForm from 'Login/Components/LoginForm';
import AccountNav from 'Login/Components/AccountNav';
import { translate } from 'Shared/translate';
import { MEDIUM } from 'Shared/breakpoints';
import { ACTION_LOGIN } from 'Shared/GA/constants';
import { GACustomEvent } from 'Shared/GA/helpers';
import * as styles from './LoginPopup.scss'
import * as ga4types from 'Shared/GA4/constants';
import { GA4HeaderClick } from 'Shared/GA4/site-wide';


export function LoginPopup(props) {

    const [isClose, setIsClose] = useState(false);
    const [hasData, setHasData] = useState(false);
    const isMobileDevice = props.breakpoint < MEDIUM;
    const isLogin = props.isAuthenticated || props.success;


    const setCloseState = (isClose) => {
        setIsClose(isClose);
    }

    const handleLoginClick = () => {
        GACustomEvent(ACTION_LOGIN);
        GA4HeaderClick(ga4types.LABEL_HEADER_LOGIN, ga4types.LABEL_HEADER_LEVEL1);
        if (!props.visible && !hasData) {
            props.onGetPagesUrl();
            setHasData(true);
        }
        props.onToggleLogin(!props.visible);
        props.onToggleOverlay(!props.visible);
    }

    const handleAccountClick = () => {
        if (!props.visible && !hasData) {
            props.onGetPagesUrl();
            setHasData(true);
        }
        props.onToggleLogin(!props.visible);
        props.onToggleOverlay(!props.visible);
    }

    const handleCloseClick = () => {
        setCloseState(!isClose);
        props.onToggleLogin(!props.visible);
        props.onToggleOverlay(!props.visible);
    }


    return (
        <div className={classnames(styles.loginPopup, {
            [styles.caret]: props.visible && (!isMobileDevice || (isMobileDevice && isLogin))
        })}>
            <span
                className={classnames(styles.iconWrapper, 'loginclick')}
                onClick={isLogin ? handleAccountClick : handleLoginClick}
            >
                {isLogin && !isMobileDevice ? <span className={styles.userIconWrapper}><span className={`${classnames('icon', styles.icon)}`} />{translate('/Header/AccountHello')}</span> : <span id={'loginPrefixContent'} className={`${classnames('icon', styles.icon, styles.loginIcon)}`} />}

                {isLogin && !isMobileDevice ? <label className={styles.label}
                    onClick={() => GA4HeaderClick(ga4types.LABEL_HEADER_ACCOUNT, ga4types.LABEL_HEADER_LEVEL1)}
                >
                    {localStorage.getItem('firstName') !== null && localStorage.getItem('firstName') !== '' ? localStorage.getItem('firstName') : props.firstName}
                </label> :
                    <label className={styles.label}>
                        {translate('/Header/Login')}
                    </label>}
            </span>
            <div id="loginPopup" className={classnames(styles.loginContent, {
                [styles.mobile]: isMobileDevice,
                [styles.visible]: props.visible,
                [styles.loginType]: !isLogin,
                [styles.accountType]: isLogin,
            })}>
                <button
                    className={styles.closeButton}
                    type="button" aria-label="Close"
                    onClick={handleCloseClick}
                >
                    <Icon
                        size={'md-24'}
                        className={'ion-android-close'}
                    />
                </button>
                <div className={styles.Popup}>
                    {props.visible && (isLogin ? <AccountNav navigation={props.navigation} /> : <LoginForm firstName={localStorage.getItem('firstName')} />)}
                </div>
            </div>
        </div>
    );
}

LoginPopup.propTypes = {
    visible: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    data: PropTypes.string.isRequired,
    accountPageUrl: PropTypes.string.isRequired,
    breakpoint: PropTypes.number.isRequired,
    onToggleLogin: PropTypes.func.isRequired,
    onResetLogin: PropTypes.func.isRequired,
    onToggleOverlay: PropTypes.func.isRequired,
    onGetPagesUrl: PropTypes.func.isRequired,
    onHandleLogout: PropTypes.func.isRequired,
}
