import { BREAKPOINT_UPDATE } from '../action-types';
import { currentBreakpoint } from '../breakpoints';

const initialState = currentBreakpoint();

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case BREAKPOINT_UPDATE:
        return action.current;
    default:
        return state;
    }
}
