import genericRequestAction from '../Shared/generic-request-action';
import {
    CHANGE_PASSWORD_PENDING,
    CHANGE_PASSWORD_FULFILL,
    CHANGE_PASSWORD_REJECT,
    CHANGE_PASSWORD_UPDATE,
} from '../Shared/action-types';

import { post, json, text, getJson, postJson, get } from '../Shared/server';
import { GA4FormTracking } from 'Shared/GA4/site-wide';
import * as types from 'Shared/GA4/constants';

export const request = genericRequestAction({
    pending: CHANGE_PASSWORD_PENDING,
    update: CHANGE_PASSWORD_UPDATE,
    fulfill: CHANGE_PASSWORD_FULFILL,
    reject: CHANGE_PASSWORD_REJECT,
});



export function changePassword(url, body, pageId) {    
    body = {
        PageId: pageId,
        CurrentPassword: body['MainArea.FormModel.CurrentPassword'],
        NewPassword: body['MainArea.FormModel.NewPassword'],
        NewPasswordAgain: body['MainArea.FormModel.NewPasswordAgain'],
        __RequestVerificationToken: body['MainArea.FormModel.__RequestVerificationToken']
    };

    return dispatch => {
        dispatch({ type: CHANGE_PASSWORD_PENDING });

        const changePasswordButton = document.querySelector('.jsChangePasswordButton');
        changePasswordButton.disabled = true;
        changePasswordButton.classList.add('loadingSpinner');

        postJson('/changepassword/updatepassword', body)
            .then(
                data => {
                    if (!data.changePasswordSuccess) {
                        dispatch({ type: CHANGE_PASSWORD_REJECT });
                        GA4FormTracking(types.LABEL_FORM_CHANGEPASSWORD, types.LABEL_FORM_ERROR, data.errorMessage);

                        document.getElementById('errorHider').classList.add('displayMessage');
                        document.getElementById('errorMessage').innerHTML= data.errorMessage;

                    } else {
                        dispatch({ type: CHANGE_PASSWORD_FULFILL });
                        GA4FormTracking(types.LABEL_FORM_CHANGEPASSWORD, types.LABEL_FORM_SUCCESS, "");

                        document.getElementById('updateSuccesful').classList.add('displayMessage');
                        document.getElementById('successMessage').innerHTML = data.changeCompletedMessage;
                    }

                    // Note: Check if required, with new change with view component block
                    // dispatch({ type: CHANGE_PASSWORD_UPDATE, data: data.formModel });

                    changePasswordButton.classList.remove('loadingSpinner');
                    changePasswordButton.disabled = false;
                },
                () => dispatch({ type: CHANGE_PASSWORD_REJECT })
            )
    };
}