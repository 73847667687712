import {
    CONTACTOPTIONS_UPDATE ,
} from 'Shared/action-types';
const initialState = {
    checked: window.PROFILE_PAGE && window.PROFILE_PAGE.contactOptions || true,
    title: window.CONTACTOPTIONS && window.CONTACTOPTIONS.title,
    description: window.CONTACTOPTIONS && window.CONTACTOPTIONS.description,
    checkboxLabel: window.CONTACTOPTIONS && window.CONTACTOPTIONS.checkboxLabel,
    textbox: window.CONTACTOPTIONS && window.CONTACTOPTIONS.textbox,
    shareCheckboxLabel: window.CONTACTOPTIONS && window.CONTACTOPTIONS.shareCheckboxLabel,
    privacyAndTandCInfo: window.CONTACTOPTIONS && window.CONTACTOPTIONS.privacyAndTandCInfo,
    contactMeOptionsData: window.CONTACTOPTIONS && window.CONTACTOPTIONS.contactMeOptions,
    contactOptions: window.CHECKOUT_INFO
        && window.CHECKOUT_INFO.checkoutInfo.contactOptions
        || '',
    shareMyData:  window.CHECKOUT_INFO
        && window.CHECKOUT_INFO.checkoutInfo.shareMyData
        || false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case CONTACTOPTIONS_UPDATE:{
            return Object.assign({}, state, {
                checked: action.data,
            });
        }
        default:
            return state;
    }
}
