import React, { useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { updateDeliveryPreference } from './actions';
import ShippingOptions from './Components/ShippingOptions';
import { TINY } from 'Shared/breakpoints';
import { translate } from 'Shared/translate';
import Heading from '../Heading';
import * as styles from './style.scss';
import { paybyfinance, v12finance } from 'Checkout/Browser/Components/CheckoutPage/checkoutConstants';
import { GA4FormTracking } from 'Shared/GA4/site-wide';
import { LABEL_FORM_CHECKOUTFORM, LABEL_FORM_ERROR } from 'Shared/GA4/constants';

export const DELIVERY_SHIPPING = 1;
export const DELIVERY_PICKUP = 2;

const DeliveryMethod = (props) => {

    const [errorMessages, setErrorMessages] = useState([
        { message: translate('/Checkout/ErrorMessages/ShippingOption1'), value: 1 },
        { message: translate('/Checkout/ErrorMessages/ShippingOption2'), value: 2 }
    ]);

    const deliveryMessageWrapperStyle = {
        border: '1px solid #ccc',
        padding: '20px',
    };

    const deliveryModeDiplay = props.deliveryModeDiplay;
    const errorMessage = errorMessages.find(x => x.value === props.selectedDeliveryPreference);
    const deliveryMessage = DESCRIPTIONS.deliveryMessage;
    const isFinanceCart = props.selectedPaymentMethod === paybyfinance || props.selectedPaymentMethod === v12finance ? true : false;

    
    return (
        <div id='deliveryOptions'>
            <Heading title={translate('/Checkout/DeliveryMethods/Headline')} />
            <div className={classnames(styles.deliveryMethod, {
                [styles.isTiny]: props.breakpoint < TINY,
            })}>
                <div className={styles.preferenceWrapper}>
                    <div className={styles.method}>
                        <ShippingOptions
                            validateForm={() => props.validateForm()}
                            isQuoteCart={props.isQuoteCart}
                            isSampleOnly={props.isSampleOnly}
                            isFinanceCart={isFinanceCart}
                            deliveryModeDisplay={deliveryModeDiplay}
                            selectedAddress={props.selectedAddress}
                        />
                        {!props.modeId && errorMessage && props.formHasErrors && props.formSubmitted &&
                            (<span className="errorMessage" {...GA4FormTracking(LABEL_FORM_CHECKOUTFORM, LABEL_FORM_ERROR, errorMessage.message)}>{errorMessage.message}</span>)}
                        {props.selectedDeliveryPreference == DELIVERY_PICKUP
                            && props.storeId == null
                            && props.formHasErrors
                            && props.formSubmitted
                            && (<span className="errorMessage" {...GA4FormTracking(LABEL_FORM_CHECKOUTFORM, LABEL_FORM_ERROR, errorMessage.message)}>{errorMessage.message}</span>)}
                        {props.formHasErrors && props.formSubmitted && props.storeId && isFinanceCart &&
                            <span className="errorMessage" {...GA4FormTracking(LABEL_FORM_CHECKOUTFORM, LABEL_FORM_ERROR, translate('/Checkout/ErrorMessages/HitachiAndCollectInStore'))}>{translate('/Checkout/ErrorMessages/HitachiAndCollectInStore')}</span>
                        }
                    </div>
                </div>
                {deliveryMessage &&
                    <div style={deliveryMessageWrapperStyle}>{deliveryMessage}</div>
                }
            </div>
        </div>
    );
}

export default connect(
    ({ breakpoint, checkout }) => ({
        breakpoint,
        selectedDeliveryPreference: checkout.delivery.data.preference,
        deliveryPreferences: checkout.delivery.data.deliveryPreferences,
        modeId: checkout.delivery.data.modeId,
        storeId: checkout.delivery.data.storeId,
    }),
    dispatch => ({
        updateDeliveryPreference: (...args) => dispatch(updateDeliveryPreference(...args)),
    })
)(DeliveryMethod);