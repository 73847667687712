import {
    CART_PENDING,
    CART_FULFILL,
    CART_REJECT,
    CART_UPDATE,
    DISPLAY_MINICART,
    DISPLAY_FULLCART,
} from '../../Shared/action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

import * as actionTypes from './action-types';

const initialState = {
    status: FULFILLED,
    errors: [],
    data: window.CHECKOUT_INFO ?
        (CHECKOUT_INFO.checkoutInfo.shoppingCart || {}) : null, // TODO Why do we render with checkout information. We modify shopping cart...
    descriptions: window.DESCRIPTIONS,
    miniCartVisible: false,
    fullCartVisible: false,
    couponStatus: FULFILLED,
};

export default function reducer(state = initialState, action) {

    switch (action.type) {

        case CART_PENDING:
            return Object.assign({}, state, {
                status: PENDING,
            });

        case CART_FULFILL:
            return Object.assign({}, state, {
                status: FULFILLED,
            });

        case CART_REJECT:
            return Object.assign({}, state, {
                status: REJECTED,
                errors: !action.error.errors ? [...action.error] : [...action.error.errors],
            });

        case CART_UPDATE:
            return Object.assign({}, state, {
                data: action.data,
            });

        case DISPLAY_MINICART:
            return Object.assign({}, state, {
                miniCartVisible: action.miniCartVisible,
            });

        case DISPLAY_FULLCART:
            return Object.assign({}, state, {
                fullCartVisible: action.fullCartVisible,
            });

        case actionTypes.CART_ADD_ITEMS_PENDING:
            return Object.assign({}, state, {
                status: PENDING,
            });

        case actionTypes.CART_ADD_ITEMS_FULFILL:
            return Object.assign({}, state, {
                status: FULFILLED,
            });

        case actionTypes.CART_ADD_ITEMS_REJECT:
            return Object.assign({}, state, {
                status: REJECTED,
            });

        case actionTypes.CART_ADD_ITEMS_UPDATE:
            return Object.assign({}, state, {
                data: action.data,
                status: FULFILLED,
            });

        case actionTypes.CART_REMOVE_ITEM_PENDING:
            return Object.assign({}, state, {
                status: PENDING,
            });

        case actionTypes.CART_REMOVE_ITEM_UPDATE:
            return Object.assign({}, state, {
                data: action.data.cart,
                status: FULFILLED,
            });

        case actionTypes.CART_QUICKBUY_UPDATE:
            return Object.assign({}, state, {
                data: action.data
            });
        case actionTypes.CART_QUICKBUY_REJECT:
            return Object.assign({}, state, {
                status: REJECTED,
            });
        case actionTypes.COUPON_PENDING:
            return Object.assign({}, state, {
                couponStatus: PENDING,
            });
        case actionTypes.COUPON_REJECT:
            return Object.assign({}, state, {
                couponStatus: REJECTED,
            });
        case actionTypes.COUPON_UPDATE: {
            if (action.data.cartId == null && action.data.errorMessage == "Invalid coupon code") {
                return Object.assign({}, state, {
                    data: Object.assign({}, state.data, {
                        errorMessage: Object.assign({}, state.data.errorMessage, action.data)
                    })
                });
            } else {
                return Object.assign({}, state, {
                    data: action.data,
                });
            }
        }
        case actionTypes.COUPON_FULFILL:
            return Object.assign({}, state, {
                couponStatus: FULFILLED,
            });

        default:
            return state;
    }
}
