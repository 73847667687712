import { fetchMarket, fetchLanguage, fetchCurrency } from './requests.js';
import { on } from './../Shared/events';

/* Keybindings and dispatchers */
on('click', '.MarketSelector', e => {
    fetchMarket(e.target.dataset.url, e.target.dataset.marketid);
});

on('click', '.LanguageSelector', e => {
    e.preventDefault();
    fetchLanguage(e.target.dataset.url, e.target.dataset.language);
});

on('click', '.CurrencySelector', e => {
    fetchCurrency(e.target.dataset.url, e.target.dataset.marketid);
});
