import * as types from './constants';
import { GetCategory } from 'Shared/CategoryType';

//view_item_list
export function GA4ViewItemList(products, listName) {
    var maxProducts = 20;
    var startIndex = 0;
    while (products.length) {
        var p1 = products.splice(0, maxProducts);	// Split the product array into arrays with max 20 objects        
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            event: types.ACTION_VIEWITEM_LIST,
            ecommerce: {
                currency: window.currency, // Currency used in the ecommerce
                items: prepareProductList(p1, startIndex, listName)
            }            
        });
        //call promtions
        GA4ViewPromotion(p1, startIndex);
        startIndex = startIndex + 20;        
    };
}

//select_item
export function GA4SelectItem(displayName, displayCode, categoryType, priceAmount, discountAmount, index) {
    var category = GetCategory[categoryType];
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
        event: types.ACTION_SELECTITEM,
        ecommerce: {
            currency: window.currency,
            items: [{
                item_name: displayName, // name of a product that is displayed. Always include it.
                item_id: displayCode, // SKU of product or SKU of the sample. Always include it.
                affiliation: window.affiliation, // The store or affiliation from which this transaction occurred, usually it's the website name
                item_category: category, // category of a product | Only one value allowed | The first level category
                item_list_name: category, // the list name where the impression happened
                quantity: 1, // The number of products that took place in this action
                price: priceAmount, // price of a product
                discount: discountAmount, // discount of a product
                index: index, // number of a position (in which a product was displayed at that time) E.g "0", "1", "2", "n"
            }]
        }
    });
}

//view_item
export function GA4ViewItem(selectedVariant, selectedItem) {
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
        event: types.EVENT_VIEWITEM,
        ecommerce: {
            currency: window.currency, // Currency used in the ecommerce
            items: [{
                item_name: selectedItem.displayName, // name of a product that is displayed. Always include it.
                item_id: selectedItem.displayCode, // SKU of product or SKU of the sample. Always include it.
                price: selectedItem.priceAmount, // price of a product
                affiliation: window.affiliation, // The store or affiliation from which this transaction occurred, usually it's the website name
                ...((selectedItem.discountAmount) && { discount: selectedItem.discountAmount }), // discount of a product
                item_category: GetCategory[selectedItem.categoryType], // category of a product | Only one value allowed | The first level category
                ...(selectedVariant && { item_variant: selectedVariant.color }), // product variant. If there are no variants, exclude this key from the dataLayer.push
                item_list_name: GetCategory[selectedItem.categoryType], // the list name where the impression happened
                quantity: 1,
                index:1
            }]
        }
    });
}

//add_to_cart
export function GA4AddToCart(addedItems, isSampleCart) {
    if (addedItems.length == 1) {       
        var isSample = addedItems[0].productName.toLowerCase().includes('sample');
        isSampleCart = isSample;
    }
    if (addedItems.length > 0) {
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            event: types.EVENT_ADDTOCART,
            ecommerce: {
                currency: window.currency, // Currency used in the ecommerce
                sample_purchase: isSampleCart || false, // Set it as True, if all the products in the basket are samples.
                items: prepareProducts(addedItems)
            }
        });
    }   
}

//remove_from_cart
export function GA4RemoveFromCart(removedItems, isSampleCart) {
    if (removedItems.length > 0) {
        if (removedItems.length == 1) {           
            var isSample = removedItems[0].productName.toLowerCase().includes('sample');
            isSampleCart = isSample;
        }
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            event: types.EVENT_REMOVEFROMCART,
            ecommerce: {
                currency: window.currency, // Currency used in the ecommerce
                sample_purchase: isSampleCart || false, // Set it as True, if all the products in the basket are samples.
                items: prepareProducts(removedItems)
            }
        })
    }
}

//view_cart
export function GA4ViewCart(items, isSampleCart) {
    if (items.length > 0) {
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            event: types.EVENT_VIEWCART,
            ecommerce: {
                currency: window.currency, // Currency used in the ecommerce
                sample_purchase: isSampleCart || false, // Set it as True, if all the products in the basket are samples.
                items: prepareProducts(items)
            }
        })
    }
}

//begin_checkout
export function GA4BeginCheckout(items, isSampleCart) {
    if (items.length > 0) {
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            event: types.ACTION_BEGINCHECKOUT,
            ecommerce: {
                currency: window.currency, // Currency used in the ecommerce
                sample_purchase: isSampleCart || false, // Set it as True, if all the products in the basket are samples.
                items: prepareProducts(items)
            }
        })
    }
}

//add_shipping_info
export function GA4AddShippingInfo(items, shippingMethod, orderAmount, coupon, isSampleCart) {
    if (items.length > 0) {
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            event: types.ACTION_ADDSHIPPINGINFO,
            ecommerce: {
                shipping_tier: shippingMethod, // selected shipping option E.g. "Economy (5-7 days" or "Express shipping (1-2 days)"
                currency: window.currency, // Currency used in the ecommerce
                sample_purchase: isSampleCart || false, // Set it as True, if all the products in the basket are samples.
                value: orderAmount, // Order value without taxes, shipping and discounts    
                coupon: coupon, // coupon at order level
                items: prepareProducts(items)
            }
        })
    }
}

//add_payment_info
export function GA4AddPaymentInfo(items, paymetType, coupon, orderAmount, isSampleCart) {
    if (items.length > 0) {
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        dataLayer.push({
            event: types.ACTION_ADDPAYMENTINFO,
            ecommerce: {
                currency: window.currency, // Currency used in the ecommerce
                value: orderAmount, // Order value without taxes, shipping and discounts
                coupon: coupon, // coupon at order level
                sample_purchase: isSampleCart || false, // Set it as True, if all the products in the basket are samples.
                payment_type: paymetType, // selected payment option E.g. "visa, finance, mastedcard, etc"
                items: prepareProducts(items)
            }
        })
    }
}

//view_promotion
export function GA4ViewPromotion(products, startIndex) { 
    const promotions = [];
    products.forEach(product => {
        if (product.promotions.length > 0) {
            product.promotions.forEach(p => {
                if (p.match(/^H[a-zA-Z0-9]*$/)) {
                    promotions.push({
                        promotionId: p,
                        creativeName: product.displayName,
                        creativeSlot: startIndex
                    });
                }                
            });
        }
        startIndex++
    });
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
        event: types.EVENT_VIEWPROMOTION,
        ecommerce: {
            items: prepareViewPromotionList(promotions)
        }
    });
}

//select_promotion
export function GA4SelectPromotion(productPromotions, creativeName, creativeSlot) {
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    const promotions = [];
    if (productPromotions.length > 0) {
        productPromotions.forEach(p => {
            if (p.match(/^H[a-zA-Z0-9]*$/)) {
                promotions.push({
                    promotionId: p,
                    creativeName: creativeName,
                    creativeSlot: creativeSlot
                });
            }
        });
    }
    dataLayer.push({
        event: types.ACTION_SELECTPROMOTION,
        ecommerce: {
            items: prepareViewPromotion(promotions, creativeName, creativeSlot)
        }
    });
}

//add_to_wishlist
export function GA4AddtoWishList(item) {
    var category = GetCategory[item.categoryType];
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
        event: types.EVENT_ADDTOWISHLIST,
        ecommerce: {
            currency: window.currency,
            items: [{
                item_name: item.displayName, // name of a product that is displayed. Always include it.
                item_id: item.displayCode, // SKU of product or SKU of the sample. Always include it.
                affiliation: window.affiliation, // The store or affiliation from which this transaction occurred, usually it's the website name
                item_category: category, // category of a product | Only one value allowed | The first level category
                item_list_name: category, // the list name where the impression happened
                price: item.priceAmount, // price of a product
                discount: item.discountAmount, // discount of a product
                quantity: 1, // The number of products that took place in this action
                index: 1, // number of a position (in which a product was displayed at that time) E.g "0", "1", "2", "n"
            }]
        }
    });
}

function calcPrice(item) {
    if (item.discountAmount != 0 && item.quantity != 0) {
        return parseFloat(item.price - (item.discountAmount / item.quantity)).toFixed(2);
    }
    else {
        return item.price;
    }
}
function calcDisountPrice(item) {
    if (item.discountAmount != 0 && item.quantity != 0) {
        return parseFloat(item.price - (item.price - (item.discountAmount / item.quantity))).toFixed(2);
    }
    else {
        return 0;
    } 
}

function prepareProductList(items, startIndex, listName) {
    return items.map(item => ({
        item_name: item.displayName, // name of a product that is displayed. Always include it.
        item_id: item.itemNumber,  // SKU of product or SKU of the sample. Always include it.
        price: calcPrice(item), // price of a product
        affiliation: window.affiliation, // The store or affiliation from which this transaction occurred, usually it's the website name
        ...((item.brand) && { item_brand: item.brand }), // brand/vendor of a product
        discount: calcDisountPrice(item) || 0, // discount of a product
        item_category: GetCategory[item.categoryType], // category of a product | Only one value allowed | The first level category
        item_list_name: listName || GetCategory[item.categoryType], // the list name where the impression happened
        ...((item.color || item.style) && { item_variant: item.color || item.style }),
        index: startIndex++
    }));
}

function prepareProducts(items) {
    var index = 0;
    return items.map(item => ({
        item_name: item.productName, // name of a product that is displayed. Always include it.
        item_id: item.productId,  // SKU of product or SKU of the sample. Always include it.
        price: calcPrice(item), // The sales price is the per item list price minus discount.
        discount: calcDisountPrice(item) || 0, // The discount per item
        affiliation: window.affiliation, // The store or affiliation from which this transaction occurred, usually it's the website name
        ...((item.brand) && { item_brand: item.brand }), // brand/vendor of a product        
        item_category: item.categoryType || item.relationType, // category of a product | Only one value allowed | The first level category        
        item_list_name: item.categoryType || item.relationType, // the list name where the impression happened
        ...((item.color || item.style) && { item_variant: item.color || item.style }), // product variant. If there are no variants, exclude this key from the dataLayer.push
        ...((item.categoryType) && { item_list_name: item.categoryType }),  // the list name where the impression happened
        quantity: item.quantity, // The number of items sold.
        cartLineId: item.lineId, //line id passed as a unique parameter
        index: index++ // number of a position (in which a product was displayed at that time) E.g "0", "1", "2", "n"
    }));
}

function prepareViewPromotionList(promotions) {

    return promotions.map(promotion => ({
        promotion_id: promotion.promotionId, // id of a promotion. E.g. "12345"
        //promotion_name: item.promotion_name, //  E.g. "blackfriday 2021"
        creative_name: promotion.creativeName, // Name of the creative
        creative_slot: promotion.creativeSlot, // E.g. "featured banner"
    }));
}
function prepareViewPromotion(promotions, creativeName, creativeSlot) {
    
    return promotions.map(promotion => ({
        promotion_id: promotion, // id of a promotion. E.g. "12345"
        //promotion_name: item.promotion_name, //  E.g. "blackfriday 2021"
        creative_name: creativeName, // Name of the creative
        creative_slot: creativeSlot, // E.g. "featured banner"        
    }));
}