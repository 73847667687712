import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import * as styles from './LocatorArea.scss';
import * as ga4types from 'Shared/GA4/constants';
import { GA4HeaderClick } from 'Shared/GA4/site-wide';


export default function LocatorArea(props) {

     return (
        <a href={props.storesLinkUrl ? props.storesLinkUrl : '/store'}
            id="locator"
            onClick={(e) => { e.preventDefault, GA4HeaderClick(ga4types.LABEL_HEADER_STORES, ga4types.LABEL_HEADER_LEVEL1) }}
            className={classnames(styles.storeLocator, {
                [styles.displaySearchArea]: props.displayStoreSearchArea
            })}>
            <span className={classnames('icon', styles.storeSvgIcon)}></span>
            <label className={styles.title}>{translate('/Header/Stores')}</label>
        </a>
    )
}
