export const row = 'row';

export const formGroup = 'form-group';
export const formControl = 'form-control';
export const formInline = 'formInline';
export const inputGroup = 'input-group';
export const inputGroupAddon = 'input-group-addon';
export const checkbox = 'checkbox';

export const srOnly = 'sr-only';

export const colXSmall3 = 'col-xs-3';
export const colXSmall4 = 'col-xs-4';
export const colXSmall6 = 'col-xs-6';
export const colXSmall8 = 'col-xs-8';
export const colXSmall9 = 'col-xs-9';
export const colXSmall12 = 'col-xs-12';

export const colSmall3 = 'col-sm-3';
export const colSmall6 = 'col-sm-6';
export const colSmall9 = 'col-sm-9';
export const colSmall12 = 'col-sm-12';

export const colMedium3 = 'col-md-3';
export const colMedium4 = 'col-md-4';
export const colMedium6 = 'col-md-6';
export const colMedium8 = 'col-md-8';
export const colMedium9 = 'col-md-9';
export const colMedium12 = 'col-md-12';

export const colLarge3 = 'col-lg-3';
export const colLarge6 = 'col-lg-6';
export const colLarge9 = 'col-lg-9';
export const colLarge12 = 'col-lg-12';
