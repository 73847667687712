import { DISPLAYMENU_UPDATE } from '../action-types';

export const toggleMenu = (display) => {
    return {
        type: DISPLAYMENU_UPDATE,
        display,
    };
};

export async function setCookie(val) {
    const data = { UserMarketingConsent: val };
    const response =  await fetch('/Header/SetGDPRCookie', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return null;
};
