import React from 'react';
import * as styles from './tooltip.scss';
import { translate } from 'Shared/translate';

export const WishListToolTip = () => {
    return <span id={styles.wishListTooltip} className={styles.tooltip}>
        <button className={styles.loginLinkButton} onClick={handleLogin}>{translate('/WishList/Login')}</button>{translate('/WishList/Or')}
        <a href='/registration' className={styles.registration}> {translate('/WishList/Register')} </a>
        {translate('/WishList/YouHaveToSignInOrRegister')}
    </span>
}

const handleLogin = () => {
    let loginClick = document.querySelector('.loginclick');
    if (loginClick) {
        loginClick.click();
    }
}