import { write } from './dom';
import { TINY } from './breakpoints';
import { postJson } from 'Shared/server.js';
import { GA4FormTracking } from 'Shared/GA4/site-wide';
import * as types from 'Shared/GA4/constants';
import { translate } from 'Shared/translate';

const emailRegexpString = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/;
const couponCodeRegexpString = /^[a-zA-Z0-9]+$/;

export const verifyCaptcha = (action) => {
    return grecaptcha.execute(window.captchaKey, {action: action}).then((token) => {
        return postJson('/Captcha/Verify', {token}).then(response => {
            if(!response.success) {
                new Promise.reject();
            }
        });
    });
}

export const setIcon = (target, oldValue, newValue) => {
    if (target !== null) {
        target.classList.remove(oldValue);
        target.classList.add(newValue);
    }
};

export const hideMarketLanguageSelectors = () => {
    const elements = [
        '.jsLanguageSelector',
        '.jsMarketSelector',
    ];

    elements.map((element) => {
        const domElement = document.querySelector(element);

        if (domElement) {
            const menu = domElement.getElementsByTagName('ul');
            if (menu) {
                write(() => {
                    menu[0].style.display = 'none';
                });
            }

            setIcon(document.querySelector(`${element} .icon`), 'ion-ios-arrow-up', 'ion-ios-arrow-down');
        }
    });
};

export const closeElementIfOutsideClick = (fn) => {
    document.addEventListener('click', (evt) => {
        let targetElement = evt.target; // clicked element

        do {
            if (targetElement) {
                const classes = targetElement.getAttribute('class');
                if (classes && classes.includes('jsDontClose')) {
                    // This is a click inside. Do nothing, just return.
                    return;
                }
            }
            // Go up the DOM
            targetElement = targetElement.parentElement;
        } while (targetElement);

        hideMarketLanguageSelectors();

        if (fn) {
            fn();
        }
    });
};

export const couponCodeValidation = (value) => {
    return value !== '' && couponCodeRegexpString.test(value);
};

export const emailValidation = (value) => {
    var outval = emailRegexpString.test(value);
    return value !== '' && emailRegexpString.test(value);
};

const firstString = '\^\\\(\?\(\?\:\(\?\:0\(\?\:0\|11\)\\\)\?\[\\s\-\]\?\\\(\?\|\\\+\)44\\\)\?\[\\s\-\]\?\\\(\?\(\?\:0\\\)\?\[\\s\-\]\?';
const secondString = '\\\(\?\)\?\|0\)\(\?\:\\d\{2\}\\\)\?\[\\s\-\]\?\\d\{4\}\[\\s\-\]\?\\d\{4\}\|\\d\{3\}\\\)\?\[\\s\-\]\?\\d\{3\}';
const thirdString = '\[\\s\-\]\?\\d\{3,4\}\|\\d\{4\}\\\)\?\[\\s\-\]\?\(\?\:\\d\{5\}\|\\d\{3\}\[\\s\-\]\?\\d\{3\}\)\|\\d\{5\}\\\)\?\[\\s\-\]';
const fourthString = '\?\\d\{4,5\}\|8\(\?\:00\[\\s\-\]\?11\[\\s\-\]\?11\|45\[\\s\-\]\?46\[\\s\-\]';
const fifthString = '\?4\\d\)\)\(\?\:\(\?\:\[\\s\-\]\?\(\?\:x\|ext\\\.\?\\s\?\|\\#\)\\d\+\)\?\)\$';
const stringCombine = `${firstString}${secondString}${thirdString}${fourthString}${fifthString}`;

export const telephoneRegEx = new RegExp(stringCombine);


//one capital, one special, one number, 8 chars minumum, 30 max
export const passwordValidation = (value) => {
    let passwordRegex = /^(?=^.{8,30}$)(?=.*\d)(?=.*[^A-Za-z0-9]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/i;
    if(passwordRegex.test(value))
    {
        return true;

    }
    return false;
}

export const phoneValidation = (value) => {
    value = value.replace(/\s/g,'');
    if (telephoneRegEx.test(value)) {
        return true;
    }
    return false;
}

export const alphabetValidation = (value) => {
    value = value.replace(/\s/g, '');
    const alpahabeticRegEx = /^[a-zA-Z ]*$/;
    if (alpahabeticRegEx.test(value)) {
        return true;
    }
    return false;
}

export function objectIsEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}

export function isMobile(size) {
    return size < TINY ? true : false;
}

//default integer validation only
export function validateQty(event, allowFloat = false) {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode == 8 || event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 46) {
        return true;
    }
    else if(allowFloat && (event.keyCode == 190 || event.keyCode == 110 )) {
        return true;
    }
    else if ( !((key > 47 && key < 58) || (key > 95 && key < 106)) ) {
        event.preventDefault();
        return false;
    }
    else return true;
};

export const buildDataAttribute = (attribute, value) => {
    return { [attribute]: value }
}

export const validateSingleInput = (item) => {
    let validItem = true;
    var parentElement = item.parentElement;

    parentElement.classList.add("hideError");
    if(item.getAttribute('data-val-required') && !item.value) {
        item.placeholder = item.placeholder.includes('required') ?
            item.placeholder :
            `${item.placeholder.slice(0, -1)} is required`;
        validItem = false;
    } else if(item.value.trim() != '') {
        switch (item.name) {
            case 'FormModel.Email':
                validItem = emailValidation(item.value);
                break;
            case 'FormModel.Phone':
                validItem = phoneValidation(item.value);
                break;
            case 'FormModel.AlternativePhone':
                validItem = phoneValidation(item.value);
                break;
            case 'FormModel.Password':
                validItem = passwordValidation(item.value);
                break;
            case 'FormModel.Password2':
                validItem = document.getElementById('FormModel_Password').value === item.value;
                break;
            case 'FormModel.FirstName':
                validItem = alphabetValidation(item.value);
                break;
            case 'FormModel.LastName':
                validItem = alphabetValidation(item.value);
                break;
            case 'FormModel.CurrentPassword':
                validItem = passwordValidation(item.value);
                if (!validItem)
                    GA4FormTracking(types.LABEL_FORM_CHANGEPASSWORD, types.LABEL_FORM_ERROR, translate("/Registration/Form/Error/InvalidPassword"));
                break;
            case 'FormModel.NewPassword':
                validItem = passwordValidation(item.value);
                if (!validItem)
                    GA4FormTracking(types.LABEL_FORM_CHANGEPASSWORD, types.LABEL_FORM_ERROR, translate("/Registration/Form/Error/InvalidPassword"));
                break;
            case 'FormModel.NewPasswordAgain':
                validItem = document.getElementById('FormModel_NewPassword').value === item.value;
                if (!validItem)
                    GA4FormTracking(types.LABEL_FORM_CHANGEPASSWORD, types.LABEL_FORM_ERROR, translate("/Registration/Form/Error/InvalidPasswordMatch"));
                break;
        }
        if(!validItem) {
            parentElement.classList.remove("hideError");
        }
    }
    if(!validItem) {
        item.classList.add("inputHasError");
    } else {
        item.classList.remove("inputHasError");
    }
    return validItem;
}

export const validateInputs = (allInputs) => {
    let valid = true;
    for (let item of allInputs) {
        let validItem = validateSingleInput(item);
        if(!validItem) {
            valid = false;
        }
    }
    return valid;
}

export function fixedNumber(n) {
    const decimalPlace = 2;
    const result = (n - Math.floor(n)) !== 0;
    return result ? parseFloat(n).toFixed(decimalPlace) : Math.floor(n);
}

export function calculateArea(width, quantity) {
    if (quantity && quantity > 0) {
        if (isNaN(width) && parseFloat(width) > 0) {
            return fixedNumber(parseFloat(width) * quantity);
        }
        if (width && width > 0) {
            return fixedNumber(width * quantity);
        }
    }
    return '0.0';
}

export const googleRecaptcha = () => {
    const script = document.createElement("script");
    script.id = 'googlerecaptcha';
    script.type = 'text/javascript';
    script.ref = 'googlerecaptcha'; 
    script.src = "https://www.google.com/recaptcha/api.js"+ '?render=' + window.captchaKey;
    document.body.appendChild(script);
}

export const siteTheme = {
    CARPETRIGHT: 'carpetright',
    FM: 'floormegastore',
    TFR: 'thefloorroom'
}

export const ProductTypes = {
    Carpet: 'carpet',
    Vinyl: 'vinyl',
    LuxuryVinyl: 'luxury_vinyl',
    Laminate: 'laminate',
    WoodFlooring: 'wood_flooring',
    AdviceNeeded: 'advice_needed'
};

export function isTheme(theme) {
    const currentTheme = getThemeStorageName();
    return currentTheme === theme;    
}

export function selectedThemeName() {
    return getThemeStorageName();
}

export function getThemeStorageName() {
    const getlocalTheme = window.cmsTheme;

    if(getlocalTheme) {
        return getlocalTheme;
    }

    const configuredTheme = document.querySelector('#app-container').getAttribute('data-cmstheme');

    return configuredTheme;
}