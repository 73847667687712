import { 
    QUOTESEARCH_PENDING, 
    QUOTESEARCH_FULFILL, 
    QUOTESEARCH_REJECT,
    QUOTESEARCH_ISPOSCREATED,
    QUOTESEARCH_WRONGPASSWORD,
} from './action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

const initialState = {
    status: FULFILLED,
    success: true,
    isPosCreated: false,
    accountNumber: '',
    verifyAccount: false,
    type: 0,
    wrongPassword: false,
    accountLocked: false,
    forgotPasswordPageUrl: window.QUOTESEARCH && window.QUOTESEARCH.forgotPasswordPageUrl,
    order: null,
    instructions:"",
    preInstructions: window.QUOTESEARCH && window.QUOTESEARCH.preInstructions,
    pageTitle: window.QUOTESEARCH && window.QUOTESEARCH.pageTitle,
    accountActivated: false,
};

export default function register(state = initialState, action) {
    switch (action.type) {
    case QUOTESEARCH_PENDING:
        return Object.assign({}, state, {
            status: PENDING,
            isPosCreated: action.isPosCreated,
            order:null, 
            success:true,           
        });
        
    case QUOTESEARCH_FULFILL:
        return Object.assign({}, state, {
            status: FULFILLED,
            success: action.data,
            isPosCreated: false,
        });
    case QUOTESEARCH_REJECT: 
        return Object.assign({}, state, {
            status: FULFILLED,
            success: false,
        });

    case QUOTESEARCH_ISPOSCREATED: 
        return Object.assign({}, state, {
            status: FULFILLED,
            isPosCreated: true,
            accountNumber: action.accountNumber,
            verifyAccount: action.verifyAccount,
            salesOrderType: action.salesOrderType,
            order: action.order,
            instructions: action.instructions,
            accountActivated: action.accountActivated, 
        });
    case QUOTESEARCH_WRONGPASSWORD: 
        return Object.assign({}, state, {
            wrongPassword: true,
            status: FULFILLED,
            accountLocked: action.accountLocked
        });
    default:
        return state;
    }
}


