export const CREATE_JOB = 'CREATE_JOB';
export const SET_ADDRESS = 'SET_ADDRESS';
export const CHECK_SET_ADDRESS = 'CHECK_SET_ADDRESS';
export const GET_AVAILABLESLOTS = 'GET_AVAILABLESLOTS';
export const GET_SELECTEDDATETIME = 'GET_SELECTEDDATETIME';
export const GET_USERDETAILS = 'GET_USERDETAILS';
export const SET_SCHEDULETIMESLOT = 'SET_SCHEDULETIMESLOT';
export const SET_COMPLETEBOOKING = 'SET_COMPLETEBOOKING';
export const SET_DETAILS = 'SET_DETAILS';
export const CREATE_BOOKING ='CREATE_BOOKING';
export const SET_PRODUCTTYPE1 = 'SET_PRODUCTTYPE1';
export const GET_AVAILABLESLOTSAPI = 'GET_AVAILABLESLOTSAPI';
export const DELETE_JOB = 'DELETE_JOB';
export const IHC_FULLFILL = 'IHC_FULLFILL';
export const IHC_PENDING = 'IHC_PENDING';

