import genericRequestAction from '../../Shared/generic-request-action';
import specificRequestAction from '../../Shared/specific-request-action';
import * as actionsTypes from './action-types';
import { updateTerms, updatePaymentMethods, updateV12FinanceTerms } from '../../Checkout/Browser/Components/CheckoutPage/Components/Payment/actions';
import { emptyItems } from './LocalCart/actions';
import { postJson} from 'Shared/server.js'

import {
    CART_PENDING,
    CART_UPDATE,
    CART_FULFILL,
    CART_REJECT,
    DISPLAY_GENERIC_ERROR,
} from '../../Shared/action-types';
import { fetchDeliveryOptions } from '../../Checkout/Browser/Components/CheckoutPage/Components/DeliveryMethod/actions';
import { resetCartCache } from '../Browser/Components/cartHelper';

export const request = genericRequestAction({
    pending: CART_PENDING,
    update: CART_UPDATE,
    fulfill: CART_FULFILL,
    reject: CART_REJECT,
});

export const deliveryOptionRequest = specificRequestAction({
    pending: CART_PENDING,
    update: CART_UPDATE,
    fulfill: CART_FULFILL,
    reject: CART_REJECT,
});

export const couponRequest = genericRequestAction({
    pending: actionsTypes.COUPON_PENDING,
    update: actionsTypes.COUPON_UPDATE,
    fulfill: actionsTypes.COUPON_FULFILL,
    reject: actionsTypes.COUPON_REJECT,
});

export const quickBuyRequest = genericRequestAction({
    pending: actionsTypes.CART_QUICKBUY_PENDING,
    update: actionsTypes.CART_QUICKBUY_UPDATE,
    fulfill: actionsTypes.CART_QUICKBUY_FULFILL,
    reject: actionsTypes.CART_QUICKBUY_REJECT,
});

export const addItemsRequest = genericRequestAction({
    pending: actionsTypes.CART_ADD_ITEMS_PENDING,
    update: actionsTypes.CART_ADD_ITEMS_UPDATE,
    fulfill: actionsTypes.CART_ADD_ITEMS_FULFILL,
    reject: actionsTypes.CART_ADD_ITEMS_REJECT,
});

export const removeItemRequest = genericRequestAction({
    pending: actionsTypes.CART_REMOVE_ITEM_PENDING,
    update: actionsTypes.CART_REMOVE_ITEM_UPDATE,
    fulfill: actionsTypes.CART_REMOVE_ITEM_FULFILL,
    reject: actionsTypes.CART_REMOVE_ITEM_REJECT,
});

export const addItems = (items) => {
    return (dispatch) => {
        dispatch({
            type: actionsTypes.CART_ADD_ITEMS_PENDING
        })
        return postJson('/cart/addtocart', items)
        .then(res => {
            if(res.exception){
                dispatch({
                    type: DISPLAY_GENERIC_ERROR,
                });
                dispatch({
                    type: actionsTypes.CART_ADD_ITEMS_REJECT,
                });
            }
            else{
                
                dispatch({
                    type: actionsTypes.CART_ADD_ITEMS_UPDATE,
                    data: res
                });
                
                dispatch({
                    type: actionsTypes.CART_ADD_ITEMS_FULFILL
                })

                setTimeout(function(){
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                }, 400);              
            }
        }).then(d => {
            emptyItems();
        });
    }
};

export const removeItem = (lineId, cartType) => { 
    return (dispatch, getState) => {
        dispatch (
            removeItemRequest('/cart/removeitem', { lineId, cartType})
        ).then(data => {
            resetCartCache();
            dispatch(updatePaymentMethods(data.paymentMethods));
            dispatch(fetchDeliveryOptions({
                ...getState().checkout.address.data.deliveryAddress,
            }));
        });
    };
};

export const quickbuy = (code, quantity) => {
    return quickBuyRequest('/cart/addtocart', [{ code, quantity }]);
};

export function updateCartInfo(data) {
    return (dispatch) => {
        dispatch({
            type: 'CART_UPDATE',
            data,
        });
    };
}

export function toggleMiniCart(displayMiniCart) {
    return (dispatch) => {
        dispatch({
            type: 'DISPLAY_MINICART',
            miniCartVisible: displayMiniCart,
        });
    };
}

export function toggleFullCart(displayFullCart) {
    return (dispatch) => {
        dispatch({
            type: 'DISPLAY_FULLCART',
            fullCartVisible: displayFullCart,
        });
    };
}

export function addCouponCode(code) {
    return couponRequest('/Checkout/AddCouponCode', { couponCode: code });
}

export function removeCouponCode(code) {
    return couponRequest('/Checkout/RemoveCouponCode', { couponCode: code });
}

export function getCartItems() { 
    return (dispatch) => {
        dispatch (
            request('/cart/getshoppingcartjson')
        ).then(data => {
            dispatch(updateCartInfo(data || {}));
        });
    };
}
