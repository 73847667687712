import cloneDeep from 'lodash/cloneDeep';
import {
    PRODUCT_PENDING,
    PRODUCT_FULFILL,
    PRODUCT_REJECT,
    PRODUCT_UPDATE,
} from '../../../../Shared/action-types';

import * as actionTypes from './action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

export const initialState = {
    status: FULFILLED,
    data: window.PRODUCT_DATA || null,
    checkoutUrl: window.CHECKOUTURL,
};

export default function login(state = initialState, action) {

    switch (action.type) {

        case PRODUCT_PENDING:
            return Object.assign({}, state, {
                status: PENDING,
            });

        case PRODUCT_FULFILL:
            return Object.assign({}, state, {
                status: FULFILLED,
            });

        case PRODUCT_REJECT:
            return Object.assign({}, state, {
                status: REJECTED,
            });

        case PRODUCT_UPDATE:
            return Object.assign({}, state, {
                data: action.data,
            });

        case actionTypes.VARIANT_DELSELECT:
            return Object.assign({}, state, {
                data: action.data,
            });

        case actionTypes.EXPAND_NEXT_SECTION: {

            const clonedState = cloneDeep(state);

            return clonedState;
        }

        case actionTypes.SECTION_TOGGLE: {

            const clonedState = cloneDeep(state);

            const sectionId = action.sectionId;

            const relatedProductSections = clonedState.data.selectedItem.relatedProductSections;

            const sectionToToggle = relatedProductSections.find((section) => {
                return section.id === sectionId;
            });

            if (sectionToToggle) {
                sectionToToggle.expanded = !sectionToToggle.expanded;
            }

            return clonedState;
        }

        case actionTypes.SECTION_COLLAPSE: {

            // REFACTOR Duplicate code, see SECTION_TOGGLE above.
            const clonedState = cloneDeep(state);

            const sectionId = action.sectionId;

            const relatedProductSections = clonedState.data.selectedItem.relatedProductSections;

            const sectionToToggle = relatedProductSections.find((section) => {
                return section.id === sectionId;
            });

            if (sectionToToggle) {
                sectionToToggle.expanded = false;
            }

            return clonedState;
        }

        case actionTypes.SECTIONS_COLLAPSE_ALL: {

            // REFACTOR Duplicate code, see SECTION_TOGGLE above.
            const clonedState = cloneDeep(state);

            const relatedProductSections = clonedState.data.selectedItem.relatedProductSections;

            relatedProductSections.forEach((relatedProductSection) => {
                relatedProductSection.expanded = false;
            });

            return clonedState;
        }

        case actionTypes.SECTION_EXPAND: {

            // REFACTOR Duplicate code, see SECTION_TOGGLE above.
            const clonedState = cloneDeep(state);

            const sectionId = action.sectionId;

            const relatedProductSections = clonedState.data.selectedItem.relatedProductSections;

            const sectionToToggle = relatedProductSections.find((section) => {
                return section.id === sectionId;
            });

            if (sectionToToggle) {
                sectionToToggle.expanded = true;
            }

            return clonedState;
        }

        default:
            return state;
    }
}
