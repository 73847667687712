import React, { Component, useEffect, useState } from 'react';
import classnames from 'classnames';
import storage from 'local-storage-fallback';
import * as styles from '../../genericheader.scss';


export function CountDown(props) {

    const [isDisplayTimer, setIsDisplayTimer] = useState(true);
    const [time, setTime] = useState({ days: '', hours: '', minutes: '', seconds: '' });
    const [isToggleMessage, setIsToggleMessage] = useState(false);
    const [copied, setCopied] = useState(false);
    const [countDownBannerShow, setCountDownBannerShow] = useState(true);
    const [localStorageStatus, setLocalStorageStatus] = useState(false);

    let countDownLocalStorageValue = storage.getItem('countDown') == 'false';
    let dismissedBannerReappearsIn = props.countDown[0].dismissedBannerReappearsIn;
    let timerId = null;


    useEffect(() => {
        if (props.countDown[0].countDownON) {
            countDown();
            storage.setItem('countDown', props.countDown[0].countDownON && (isValidFrom() && isValidTo()));
        }
        setInterval(countDownStripBarDateSet, 1000);

        const storedStatus = localStorage.getItem('status');
        const expirationTime = localStorage.getItem('expirationTime');

        if (storedStatus === 'true' && expirationTime && new Date().getTime() < parseInt(expirationTime)) {
            setLocalStorageStatus(true);
            setupTimer(parseInt(expirationTime));
        }

        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        }
    }, []);


    const isValidFrom = () => {
        const nowDate = new Date().getTime();
        const countDonwValidFrom = new Date(props.countDown[0].validFrom).getTime();

        return (countDonwValidFrom <= nowDate);
    }

    const isValidTo = () => {
        const nowDate = new Date().getTime();
        const countDownValidTo = new Date(props.countDown[0].validTo).getTime();

        return (countDownValidTo > nowDate);
    }

    const countDownStripBarDateSet = () => {
        const isValid = (isValidFrom() && isValidTo());
        if (isDisplayTimer !== isValid) {
            storage.setItem('countDown', isValid);
            setIsDisplayTimer(isValid);
        }
    }

    const countDown = () => {
        const second = 1000,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24;

        setInterval(function () {
            const validTo = new Date(props.countDown[0].validTo).getTime();
            const now = new Date().getTime();
            const defValidToAndNow = validTo - now;

            setTime({
                days: Math.floor(defValidToAndNow / (day)),
                hours: Math.floor((defValidToAndNow % (day)) / (hour)),
                minutes: Math.floor((defValidToAndNow % (hour)) / (minute)),
                seconds: Math.floor((defValidToAndNow % (minute)) / second)
            });

        }.bind(this), 1000);
    }

    const handleClickCopy = (e) => {
        setCopied(true);
        setIsToggleMessage(false);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    }

    const handleClick = () => {
        localStorage.setItem('status', 'true');
        setLocalStorageStatus(true);
        const dismissedBannerReappearsIn = props.countDown[0].dismissedBannerReappearsIn * 60 * 60 * 1000;
        const expirationTime = new Date().getTime() + dismissedBannerReappearsIn;
        localStorage.setItem('expirationTime', expirationTime.toString());
        setupTimer(expirationTime);
    };

    const setupTimer = (expirationTime) => {
        const currentTime = new Date().getTime();
        const remainingTime = expirationTime - currentTime;

        if (timerId) {
            clearTimeout(timerId);
        }

        timerId = setTimeout(() => {
            localStorage.setItem('status', 'false');
            setLocalStorageStatus(false);
            localStorage.removeItem('expirationTime');
        }, remainingTime);
    }


    return (
        <React.Fragment>
            {countDownBannerShow && !countDownLocalStorageValue && isDisplayTimer ?
                <React.Fragment>
                    {localStorage.getItem('status') != 'true' ?
                        <div className={styles.countdownMainWrapper}>
                            {props.countDown.map((item, i) =>
                                <div key={`countDown${i}`} style={{ background: '#' + item.stripBackgroundColor }}>
                                    <div className={styles.countdownInsideMainWrapper + ` container`} >
                                        <div className={styles.countdownContentWrapper}>
                                            <p>
                                                <span style={{ color: '#' + item.textColor }} dangerouslySetInnerHTML={{ __html: item.offerText }} />
                                                {item.offerCode &&
                                                    <span
                                                        className={classnames(styles.offerCode)}
                                                        onClick={(e) => { navigator.clipboard.writeText(item.offerCode), handleClickCopy(e); }}
                                                        style={{ color: '#' + item.offerTextColor, background: '#' + item.offerBackgroundColor }}
                                                        onMouseEnter={() => { setIsToggleMessage(true) }}
                                                        onMouseLeave={() => { setIsToggleMessage(false) }}
                                                    >
                                                        {!copied && <span dangerouslySetInnerHTML={{ __html: item.offerCode }} />}
                                                        {copied && <React.Fragment>
                                                            <span className={styles.copiedDoneMessage}
                                                                style={{ color: '#' + item.offerTextColor, backgroundColor: '#' + item.offerBackgroundColor }}>Copied <svg id={styles.tick} style={{ paddingLeft: 2 + 'px', marginTop: 0, fill: '#' + item.offerTextColor, zIndex: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                    <path d="M0 11c2.761.575 6.312 1.688 9 3.438 3.157-4.23 8.828-8.187 15-11.438-5.861 5.775-10.711 12.328-14 18.917-2.651-3.766-5.547-7.271-10-10.917z" />
                                                                </svg></span>
                                                        </React.Fragment>
                                                        }
                                                        {!copied && isToggleMessage &&
                                                            <svg style={{ paddingLeft: 2 + 'px', marginTop: 0, fill: '#' + item.offerTextColor }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                <path d="M12.026 14.116c-3.475 1.673-7.504 3.619-8.484 4.09-1.848.889-3.542-1.445-3.542-1.445l8.761-4.226 3.265 1.581zm7.93 6.884c-.686 0-1.393-.154-2.064-.479-1.943-.941-2.953-3.001-2.498-4.854.26-1.057-.296-1.201-1.145-1.612l-14.189-6.866s1.7-2.329 3.546-1.436c1.134.549 5.689 2.747 9.614 4.651l.985-.474c.85-.409 1.406-.552 1.149-1.609-.451-1.855.564-3.913 2.51-4.848.669-.321 1.373-.473 2.054-.473 2.311 0 4.045 1.696 4.045 3.801 0 1.582-.986 3.156-2.613 3.973-1.625.816-2.765.18-4.38.965l-.504.245.552.27c1.613.789 2.754.156 4.377.976 1.624.819 2.605 2.392 2.605 3.97 0 2.108-1.739 3.8-4.044 3.8zm-2.555-12.815c.489 1.022 1.876 1.378 3.092.793 1.217-.584 1.809-1.893 1.321-2.916-.489-1.022-1.876-1.379-3.093-.794s-1.808 1.894-1.32 2.917zm-3.643 3.625c0-.414-.335-.75-.75-.75-.414 0-.75.336-.75.75s.336.75.75.75.75-.336.75-.75zm6.777 3.213c-1.215-.588-2.604-.236-3.095.786-.491 1.022.098 2.332 1.313 2.919 1.215.588 2.603.235 3.094-.787.492-1.021-.097-2.33-1.312-2.918z" />
                                                            </svg>
                                                        }
                                                    </span>
                                                }
                                                <span style={{ color: '#' + item.textColor }} dangerouslySetInnerHTML={{ __html: item.jointText }} />
                                            </p>
                                        </div>

                                        <ul className={classnames(styles.countdownContentWrapper)}>
                                            {item.countDownON &&
                                                <React.Fragment>
                                                    <li className={styles.counterItem} style={{ color: '#' + item.countdownTextColor, background: '#' + item.countdownBackgroundColor }}>
                                                        <div className={styles.spanWrapper}>
                                                            <span className={classnames(styles.days, `days`)} >{time.days}</span>
                                                            <span> days</span>
                                                        </div>
                                                    </li>
                                                    <li className={styles.counterItem} style={{ color: '#' + item.countdownTextColor, background: '#' + item.countdownBackgroundColor }}>
                                                        <div className={styles.spanWrapper}>
                                                            <span className={classnames(styles.hours, `hours`)} >{time.hours}</span>
                                                            <span> hrs</span>
                                                        </div>
                                                    </li>
                                                    <li className={styles.counterItem} style={{ color: '#' + item.countdownTextColor, background: '#' + item.countdownBackgroundColor }}>
                                                        <div className={styles.spanWrapper}>
                                                            <span className={classnames(styles.minutes, `minutes`)} >{time.minutes}</span>
                                                            <span> mins</span>
                                                        </div>
                                                    </li>
                                                    <li className={styles.counterItem} style={{ color: '#' + item.countdownTextColor, background: '#' + item.countdownBackgroundColor }}>
                                                        <div className={styles.spanWrapper}>
                                                            <span className={classnames(styles.seconds, `seconds`)} >{time.seconds}</span>
                                                            <span> secs</span>
                                                        </div>
                                                    </li>
                                                </React.Fragment>
                                            }
                                            <li style={{ color: '#' + item.textColor }} dangerouslySetInnerHTML={{ __html: item.extendedText }} />
                                        </ul>
                                        {dismissedBannerReappearsIn > 0 ?
                                            <i className={styles.hideCountDownBanner + " fa fa-times-circle"} aria-hidden="true" style={{ color: '#' + item.countdownBackgroundColor }}
                                                onClick={handleClick}
                                            ></i> : null}
                                    </div>
                                </div>
                            )}
                        </div>
                        : null}
                </React.Fragment> : null
            }
        </React.Fragment>
    )
}

