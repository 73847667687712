import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'Shared/translate';

// Components
import ServiceSection from '../ServiceSection';

export const FITTING_SECTION_ID = 'FITTING';
export const FITTING_TYPE_ID = 'fitting';
export const FITTINGADMIN_SECTION_ID = 'FITTINGADMIN';
export const FITTINGADMIN_TYPE_ID = 'fittingadmin';
export const FITTINGMINSKU = 'fittingminsku';
export const FITTINGADMINMINSKU = 'fittingadminminsku';

export const Fitting = ({
    description,
    title,
    products,
    expanded,
    visible,
    selectedItems,
    onItemSelect,
    onItemDeselect,
    onSectionToggle,
    onSectionDiscard,
    onSectionConfirm,
    disabled,
    calculatedArea,
    onQuantityChange,
    localcartItemsQuantity
 }) => (

    <ServiceSection
        sectionId={FITTING_SECTION_ID}
        description={description}
        products={products}
        title={title || translate('/ProductPage/RelatedProducts/Fitting/Title')}
        expanded={expanded}
        visible={visible}
        selectedItems={selectedItems}
        addButtonLabel={translate('/ProductPage/Variant/AddButton')}
        payableLabel={translate('/ProductPage/RelatedProducts/Fitting/Payable')}
        discardSectionButtonLabel={translate('/ProductPage/RelatedProducts/Fitting/DiscardButton')}
        confirmButtonLabel={translate('/ProductPage/RelatedProducts/Fitting/ConfirmButton')}
        disabled={disabled}
        onItemSelect={onItemSelect}
        onItemDeselect={onItemDeselect}
        onSectionToggle={onSectionToggle}
        onSectionDiscard={onSectionDiscard}
        onSectionConfirm={onSectionConfirm}
        calculatedArea={calculatedArea}
        onQuantityChange={onQuantityChange}
        localcartItemsQuantity={localcartItemsQuantity}
    />
);

Fitting.propTypes = {
    products: PropTypes.array.isRequired,
    description: PropTypes.string,
    expanded: PropTypes.bool,
    visible: PropTypes.bool,
    selectedItems: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    onItemSelect: PropTypes.func.isRequired,
    onItemDeselect: PropTypes.func.isRequired,
    onSectionToggle: PropTypes.func.isRequired,
    onSectionDiscard: PropTypes.func.isRequired,
    onSectionConfirm: PropTypes.func.isRequired,
};
