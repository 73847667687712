import React, { useState } from 'react';
import classnames from 'classnames';
import * as styles from './megamenu.scss';
import { SMALL } from '../../Shared/breakpoints';
import { translate } from '../../Shared/translate';
import * as ga4types from 'Shared/GA4/constants';
import { GA4HeaderClick } from 'Shared/GA4/site-wide';
import { siteTheme, isTheme } from 'Shared/customfunctions';

const MegaMenu = (props) => {
    const [activeitem, setActiveitem] = useState(0);
    const [height, setHeight] = useState('');
    const [width, setWidth] = useState('');

    const { menuItem } = props;
    const { columnList, brandList, campaignList, helpAndAdviceList, promoImageList, bottomThinBannerList } = menuItem;
    const brandListIndex = columnList.length;
    const campaignListIndex = columnList.length + 1;
    const helpAndAdviceListIndex = columnList.length + 2;
    const promoImageListIndex = columnList.length + 3;

    const handleClickToggle = (e) => {
        var contentHeader = e.currentTarget;
        var content = e.currentTarget.nextElementSibling;
        contentHeader.classList.toggle([styles.active]);
        if (content) {
            if (content.style.display == "flex") {
                content.style.display = "none";
            } else {
                content.style.display = "flex";
            }
        }
    }
    const base_url = window.location.origin;

    const changeActiveItem = (activeitem) => {
        setActiveitem(activeitem);
    }
    
    const isMobile = () => {
        return props.breakpoint <= SMALL ? true : false;
    }
    
    const handlerImgLoader = ({ target: img }) => {
        setWidth(img.width);
        setHeight(img.height);
    }

    return (
        <React.Fragment>
            <div className={classnames(styles.newStyle, styles.megamenuwrapper)}>
                {isTheme(siteTheme.CARPETRIGHT) && isMobile() ?
                    <div className={classnames(styles.megabottomarea, styles.newmegabottomareatop, {
                        [styles.carpetrightONLYmobile]: isMobile
                    })}>
                        <div className={styles.viewallareawrappertop}>
                            <a href={menuItem.allLinkUrl}
                                onClick={(e) => { e.preventDefault, GA4HeaderClick(`View all ` + menuItem.linkText, ga4types.LABEL_HEADER_LEVEL3) }}
                            >
                                <div className={styles.viewallarea}>
                                    {translate('/Navigation/MegaMenu/ViewAll')} <span>{menuItem.linkText}</span>
                                </div>
                            </a>
                        </div>
                    </div> : null
                }
                {isTheme(siteTheme.TFR) && isMobile() ?
                    <div className={classnames(styles.megabottomarea, styles.newmegabottomareatop, {
                        [styles.thefloorroomONLYmobile]: isMobile
                    })}>
                        <div className={styles.viewallareawrappertop}>
                            <a href={menuItem.allLinkUrl}
                                onClick={(e) => { e.preventDefault, GA4HeaderClick(`View all ` + menuItem.linkText, ga4types.LABEL_HEADER_LEVEL3) }}
                            >
                                <div className={styles.viewallarea}>
                                    {translate('/Navigation/MegaMenu/ViewAll')} <span>{menuItem.linkText}</span>
                                </div>
                            </a>
                        </div>
                    </div> : null
                }
                <div className={classnames(`${styles.megatoparea} container`, styles.container, {
                    [styles.carpetrightmobile]: isTheme(siteTheme.CARPETRIGHT) && isMobile
                })}>
                    <ul className={classnames(`linksWrapper`, styles.links)}>
                        {columnList.length > 0 && columnList.map((item, index) =>
                            <li id={promoImageList.length > 0 ? styles.promoImageWrapperFirstColumn : ''} className={classnames(styles.accordionWrapper, { [styles.accordionMobileWrapper]: isMobile() })} key={index}>
                                <div className={styles.accordionHeader + ` accordionHeader`} onClick={isMobile() ? handleClickToggle : null}>
                                    {item.columnHeader}
                                </div>
                                <ul className={classnames(styles.accordionContent, 'column-wrapper', item.columnHeader.toLowerCase().replace(/(?!\w|\s)./g, '').replace(/\s+/g, ' ').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2').replace(/\s/g, '-'), styles[item.columnHeader.toLowerCase().replace(/(?!\w|\s)./g, '').replace(/\s+/g, ' ').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2').replace(/\s/g, '-')], styles.innerlinks, activeitem === index ? styles.menuisactive : '')}>
                                    <li><div onClick={() => props.prevLevel()}>{item.columnHeader}</div></li>
                                    {item.linkList && item.linkList.map((link, i) =>
                                        <li className={styles.innerlinksinnerwrapper} key={link.linkText + i}>
                                            <div className={link.linkText.toLowerCase().replace(/(?!\w|\s)./g, '').replace(/\s+/g, ' ').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2').replace(/\s/g, '-')}>
                                                {!isMobile() &&
                                                    <a href={link.linkUrl}
                                                        onClick={(e) => { e.preventDefault, GA4HeaderClick(props.linkText + ' - ' + link.linkText, ga4types.LABEL_HEADER_LEVEL3) }}
                                                    >
                                                        {link.linkTitle == 'icon' ? (
                                                            <div className={styles.icon} style={{
                                                                backgroundImage: 'url(' + base_url + '/globalassets/megamenuicons'
                                                                    + props.menuItem.allLinkUrl
                                                                    + link.linkText.toLowerCase().replace(/(?!\w|\s)./g, '').replace(/\s+/g, ' ').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2').replace(/\s/g, '') + '.jpg' + ')',
                                                                padding: '0',
                                                                width: '22px',
                                                                height: '22px',
                                                                lineHeight: '0',
                                                                display: 'table',
                                                                marginRight: '10px',
                                                                borderRadius: '4px',
                                                                border: '1px solid #777',
                                                                marginBottom: '5px'
                                                            }} >
                                                            </div>
                                                        ) : null
                                                        }
                                                        {link.linkTitle == 'circle' ? (
                                                            <div className={styles.circle} style={{
                                                                backgroundImage: 'url(' + base_url + '/globalassets/megamenuicons'
                                                                    + props.menuItem.allLinkUrl
                                                                    + link.linkText.toLowerCase().replace(/(?!\w|\s)./g, '').replace(/\s+/g, ' ').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2').replace(/\s/g, '') + '.jpg' + ')',
                                                                backgroundPosition: 'center center',
                                                                padding: '0',
                                                                width: '25px',
                                                                height: '25px',
                                                                lineHeight: '0',
                                                                display: 'table',
                                                                marginRight: '10px',
                                                                borderRadius: '100%',
                                                                borderWidth: '0',
                                                                marginBottom: '5px'
                                                            }} >
                                                            </div>
                                                        ) : null
                                                        }
                                                        {link.linkText}
                                                    </a>
                                                }
                                                {isMobile() &&
                                                    <a href={link.linkUrl}
                                                        onClick={(e) => { e.preventDefault, GA4HeaderClick(props.linkText + ' - ' + link.linkText, ga4types.LABEL_HEADER_LEVEL3) }}
                                                        style={{
                                                            ...link.linkTitle == 'icon' ? {
                                                                backgroundImage: 'url(' + base_url + '/globalassets/megamenuicons'
                                                                    + props.menuItem.allLinkUrl
                                                                    + link.linkText.toLowerCase().replace(/(?!\w|\s)./g, '').replace(/\s+/g, ' ').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2').replace(/\s/g, '') + '.jpg' + ')'
                                                            } : { color: '#000000' }

                                                        }}>
                                                        {link.linkText}
                                                    </a>
                                                }
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                        {brandList.length > 0 && (
                            <li className={classnames(styles.brandListWrapper, activeitem === brandListIndex ? styles.menuisactive : '', { [styles.accordionMobileWrapper]: isMobile() })}>
                                <div className={styles.accordionHeader + ` accordionHeader`} onClick={handleClickToggle}>
                                    {translate('/Navigation/MegaMenu/Brands')}
                                </div>
                                <ul className={classnames(styles.innerlinks, styles.brandlist)}>
                                    <li><div onClick={() => props.prevLevel()}>{translate('/Navigation/MegaMenu/Brands')}</div></li>
                                    {brandList.map((link, i) =>
                                        <li key={link.linkUrl + i} className={styles.imagecontent} >
                                            {!isMobile() && link.imageUrl &&
                                                <a href={link.linkUrl}
                                                    onClick={(e) => { e.preventDefault, GA4HeaderClick(props.linkText + ' - ' + link.text, ga4types.LABEL_HEADER_LEVEL3) }}
                                                >
                                                    <img src={link.imageUrl} alt={link.text}
                                                        width={'25px'}
                                                        height={'25px'}
                                                    />
                                                    <span>{link.text}</span>
                                                </a>
                                            }
                                            {isMobile() && link.mobileImageUrl &&
                                                <a href={link.linkUrl}
                                                    style={{
                                                        ...link.mobileImageUrl != '' ? {
                                                            backgroundImage: 'url(' + link.mobileImageUrl + ')'
                                                        } : { color: '#000000' }
                                                    }}
                                                    onClick={(e) => { e.preventDefault, GA4HeaderClick(props.linkText + ' - ' + link.text, ga4types.LABEL_HEADER_LEVEL3) }}
                                                >
                                                    {link.mobileImageUrl != '' ? <img style={{ visibility: 'hidden' }} src={link.mobileImageUrl} alt={link.text} /> : <p style={{
                                                        ...isTheme(siteTheme.TFR) && isMobile() ? { color: '#000000' } : null
                                                    }}>{link.text}</p>}
                                                </a>
                                            }
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                        {campaignList.length > 0 && (
                            <li className={classnames(styles.campaignListWrapper, activeitem === campaignListIndex ? styles.menuisactive : '', { [styles.accordionMobileWrapper]: isMobile() })}>
                                <div className={styles.accordionHeader + ` accordionHeader`} onClick={handleClickToggle}>
                                    {translate('/Navigation/MegaMenu/Campaigns')}
                                </div>
                                <ul className={classnames(styles.innerlinks, styles.campaignlist)}>
                                    <li><div onClick={() => props.prevLevel()}>{translate('/Navigation/MegaMenu/Campaigns')}</div></li>
                                    {campaignList.map((link, i) =>
                                        <li key={link.linkUrl + i} className={styles.imagecontent}>
                                            <a href={link.linkUrl}
                                                onClick={(e) => { e.preventDefault, GA4HeaderClick(props.linkText + ' - ' + link.text, ga4types.LABEL_HEADER_LEVEL3) }}
                                            >
                                                {link.imageUrl && <img src={link.imageUrl} alt={link.text}
                                                    width={'25px'}
                                                    height={'25px'}
                                                />}
                                                <span>{link.text}</span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                        {helpAndAdviceList.length > 0 && (
                            <li style={{ marginRight: 0 }} className={classnames(styles.helpAndAdviceListWrapper, activeitem === helpAndAdviceListIndex ? styles.menuisactive : '', { [styles.accordionMobileWrapper]: isMobile() })}>
                                {isMobile() &&
                                    <div className={styles.accordionHeader + ` accordionHeader`} onClick={handleClickToggle}>
                                        {translate('/Navigation/MegaMenu/HelpAndAdvice')}
                                    </div>
                                }
                                {!isMobile() &&
                                    <div className={styles.accordionHeader + ` accordionHeader`} onClick={() => isMobile() && (changeActiveItem(helpAndAdviceListIndex), props.nextLevel(helpAndAdviceListIndex.length + 1))}>
                                        {translate('/Navigation/MegaMenu/HelpAndAdvice')}
                                    </div>
                                }
                                <ul className={classnames(styles.helpAndAdviceList, styles.innerlinks)}>
                                    <li><div onClick={() => props.prevLevel()}>{translate('/Navigation/MegaMenu/HelpAndAdvice')}</div></li>
                                    {helpAndAdviceList.map((link, i) =>
                                        <li key={link.linkUrl + i} className={styles.imagecontent}>
                                            <a href={link.linkUrl}
                                                onClick={(e) => { e.preventDefault, GA4HeaderClick(props.linkText + ' - ' + link.text, ga4types.LABEL_HEADER_LEVEL3) }}
                                            >
                                                {link.imageUrl && <img src={link.imageUrl} alt={link.text}
                                                    width={'25px'}
                                                    height={'25px'}
                                                />}
                                                <span>{link.text}</span>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}

                        {promoImageList.length > 0 && (
                            <li id={styles.promoImageWrapper} className={classnames('promoImageWrapper', styles.promoImageWrapper, activeitem === promoImageListIndex ? styles.menuisactive : '')}>
                                {isMobile() &&
                                    <ul className={classnames(styles.mobileOfferMenuItem)}>
                                        {promoImageList.map((link, i) =>
                                            <li key={link.linkUrl + i} className={classnames('promoImage', styles.promoImage)}>
                                                <p>{link.text}</p>
                                                <a title={link.text} href={link.linkUrl}
                                                    onClick={(e) => { e.preventDefault, GA4HeaderClick(props.linkText + ' - ' + link.text, ga4types.LABEL_HEADER_LEVEL3) }}
                                                >
                                                    {link.mobileImageUrl && <img src={link.mobileImageUrl} alt={link.text} />}
                                                </a>
                                            </li>)}
                                    </ul>
                                }

                                {!isMobile() &&
                                    <React.Fragment>
                                        <ul className={classnames(styles.accordionContent, styles.innerlinks, styles.noneMobileOfferMenuItem)}>
                                            <li className={classnames('promoImage', !isMobile() ? styles.promoImage : '')}>
                                                <div onClick={() => props.prevLevel()}></div>
                                            </li>
                                            {promoImageList.map((link, i) =>
                                                <li key={link.linkUrl + i} className={classnames('promoImage', styles.promoImage)}>
                                                    <div className={styles.accordionHeader + ` accordionHeader`}>{link.text}</div>
                                                    {!isMobile() && link.imageUrl &&
                                                        <a className={'promo-image-wrapper'} href={link.linkUrl}
                                                            onClick={(e) => { e.preventDefault, GA4HeaderClick(props.linkText + ' - ' + link.text, ga4types.LABEL_HEADER_LEVEL3) }}
                                                        >
                                                            {link.imageUrl && <img src={link.imageUrl} alt={link.text}
                                                                width={width}
                                                                height={height}
                                                                onLoad={handlerImgLoader}
                                                            />}
                                                        </a>
                                                    }
                                                </li>
                                            )}
                                        </ul>
                                    </React.Fragment>
                                }
                            </li>
                        )}
                    </ul>
                </div>
                {!isMobile() ?
                    <div className={classnames(styles.megabottomarea, styles.newmegabottomarea)}>
                        <div className={styles.viewallareawrapper}>
                            <a href={menuItem.allLinkUrl}
                                onClick={(e) => { e.preventDefault, GA4HeaderClick(`View all ` + menuItem.linkText, ga4types.LABEL_HEADER_LEVEL3) }}
                            >
                                <div className={styles.viewallarea}>
                                    {translate('/Navigation/MegaMenu/ViewAll')}<span>{menuItem.linkText}</span>
                                </div>
                            </a>
                        </div>
                    </div> : null
                }

                <div className={styles.bottomThinBannerContainer}>
                    {bottomThinBannerList.length > 0 && (
                        <div className={classnames(styles.bottomThinBannerList, `container`)}>
                            {bottomThinBannerList.map((link, i) =>
                                <div key={link.linkUrl + i} className={classnames(styles.bottomThinBanner)}>
                                    {!isMobile() && link.imageUrl &&
                                        <a className={styles.bottomThinBannerWrapper} href={link.linkUrl}
                                            onClick={(e) => { e.preventDefault, GA4HeaderClick(props.linkText + ' - ' + link.text, ga4types.LABEL_HEADER_LEVEL3) }}
                                        >
                                            <img src={link.imageUrl} alt={link.text} />
                                        </a>
                                    }

                                    {isMobile() && link.mobileImageUrl &&
                                        <a href={link.linkUrl}
                                            onClick={(e) => { e.preventDefault, GA4HeaderClick(props.linkText + ' - ' + link.text, ga4types.LABEL_HEADER_LEVEL3) }}
                                            style={{ backgroundImage: 'url(' + link.mobileImageUrl + ')' }}>
                                            <img style={{ visibility: 'hidden' }} src={link.mobileImageUrl} alt={link.text} />
                                        </a>
                                    }

                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default MegaMenu;