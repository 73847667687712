import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import * as styles from './CartItem.scss';
// Child components
import SingleRemovePopup from './components/SingleRemovePopup';
import { Icon } from 'Shared/icon';
import ReactModal from 'Shared/Modal';
import Attributes from './components/Attributes';
import LowStockNotification from 'Shared/LowStockNotification';
import { Measurements, EnumCatType, BoxArray } from 'Shared/CategoryType';
import { priceFormat } from 'Shared/GlobalConstants';
import ImageUrlHelper from 'Shared/Browser/Services/ImageUrlHelper';

export const location = {
    CHECKOUT: 'checkout',
    MINICART: 'minicart',
    ORDERHISTORY: 'orderhistory',
};

export const types = {
    MAIN_ITEM: 'main',
    RELATED_ITEM: 'related',
};


export default function CartItem(props) {

    const [quantity, setQuantity] = useState(props.cartItem.quantity);
    const [newQuantity, setNewQuantity] = useState(props.cartItem.quantity);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isSingleRemove, setIsSingleRemove] = useState(true);

    // UNSAFE_componentWillReceiveProps
    const prevProps = useRef(props);
    useEffect(() => {
        if (props.cartIsPending && isPending && !prevProps.current.cartIsPending) {
            // Wait for all requests to finish until we remove the pending state.
            setIsPending(false);
            if (prevProps.current.cartItem.quantity > 0 && isRemoving) {
                // This means that the remove request failed so restore the optimistically updated ui.
                setIsRemoving(false);
            }

            if (prevProps.current.cartItem.quantity > 0) {
                setNewQuantity(prevProps.current.cartItem.quantity);
            }
        }

        if (prevProps.current.cartItem.quantity !== quantity) {
            setQuantity(prevProps.current.cartItem.quantity);
        }
        prevProps.current = props;
    }, [props]);

    const handleRemove = (lineId) => {
        setIsRemoving(true);
        setIsPending(true);
        return props.removeItem(lineId);
    }

    const removeSingle = (lineId) => {
        handleRemove(lineId);
    }

    const toggleModal = () => {
        setIsConfirming(!isConfirming);

        if (!isConfirming && isSingleRemove === false)
            setIsSingleRemove(true);
    }


    const {
        productName,
        productUrl,
        displayPrice,
        price,
        priceWithCurrency,
        priceAfterDiscountWithCurrency,
        discountAmount,
        image,
        lineId,
        categoryType,
        payDirect,
        isSample,
        inStock,
        fittingInfo,
        fittingMinSkuInfo,
        isFittingAdminMinSku,
        isFittingAdmin,
        size
    } = props.cartItem;
    const isFitting = fittingInfo && fittingInfo.exist || fittingMinSkuInfo && fittingMinSkuInfo.exist;
    const isFittingArrangementFee = isFittingAdminMinSku || isFittingAdmin;
    const isDiscount = discountAmount > 0;
    const measurement = BoxArray.indexOf(EnumCatType[props.cartItem.categoryType]) > -1 ? null : Measurements[EnumCatType[props.cartItem.categoryType]];
    const enumCategoryType = EnumCatType[props.cartItem.categoryType];
    const displaySize = ((enumCategoryType === EnumCatType.Carpets || enumCategoryType === EnumCatType.ArtificialGrass || enumCategoryType === EnumCatType.Laminate || enumCategoryType === EnumCatType.EngineeredWood || enumCategoryType === EnumCatType.Underlay || enumCategoryType === EnumCatType.Vinyl || enumCategoryType === EnumCatType.LuxuryVinylTiles) ? size : "");

    return (
        <div
            className={classnames(
                styles.productItem,
                styles[props.type],
                styles[props.location],
                {
                    [styles.isLowStock]: !props.isQuoteCart && !inStock,
                }
            )}
        >
            <ReactModal
                isOpen={isConfirming}
                toggleModal={toggleModal}
                hideCloseButton
            >
                <SingleRemovePopup
                    lineId={lineId}
                    toggleModal={toggleModal}
                    removeSingle={(id) => removeSingle(id)}
                    isPending={props.cartIsPending}
                />
            </ReactModal>

            {/* Single product or accessories card in cart */}
            <div className={styles.productItemData}>

                {/*  Product image - in mini cart */}
                <div className={styles.imagewrap}>
                    <div className={styles.imageCell}>
                        <a {...!isSample && { 'href': productUrl }}>
                            <img src={image.url ? `${ImageUrlHelper.appendWitdhQueryParam(image.url, 100)}` : window.defaultImageUrl} className={styles.image} alt={image.name} />
                        </a>
                    </div>
                </div>

                {/* Product Item list */}
                <div className={`${styles.detailswrap} row`}>
                    {/* Items column */}
                    <div className={`${styles.itemDesc} col-sm-6`}>
                        {/*  Product image - on checkout page */}
                        {payDirect == false ?
                            <div className={styles.imageCell}>
                                <a {...!isSample && { 'href': productUrl }}>
                                    <img src={image.url ? `${ImageUrlHelper.appendWitdhQueryParam(image.url, 100)}` : window.defaultImageUrl} className={styles.image} alt={image.name} />
                                </a>
                            </div> : null
                        }

                        {/* Product description */}
                        <div className={styles.desc}>
                            <div className={styles.productData}>
                                <div className={styles.productName}>
                                    {!props.isQuoteCart && productUrl && productUrl != '#' ?
                                        <a
                                            className={classnames(styles.name, {
                                                [styles.sample]: isSample,
                                            })}
                                            {...!isSample && { 'href': productUrl }}
                                        >
                                            {productName}
                                        </a>
                                        :
                                        (payDirect ? <div
                                            className={classnames(styles.name, {
                                                [styles.sample]: isSample,
                                            })}
                                        >
                                            {productName}({translate('/ProductPage/RelatedProducts/Service/Payable')})
                                        </div> : <div
                                            className={classnames(styles.name, {
                                                [styles.sample]: isSample,
                                            })}
                                        >
                                            {productName}
                                        </div>)
                                    }
                                </div>
                                <Attributes item={props.cartItem} categoryType={categoryType} />
                            </div>

                            {!props.isQuoteCart && !isFittingArrangementFee && <div
                                className={styles.deleteButton}
                                onClick={toggleModal}
                                disabled={isRemoving}
                            >
                                <Icon className={'glyphicon glyphicon-trash'} />
                                <span>{translate('/Cart/RemoveMessages/Delete')}</span>
                            </div>}
                        </div>
                    </div>

                    {/* Unit price column */}
                    <div className={`${styles.itemUnitPrice} col-sm-2`}>
                        <div className={classnames(styles.priceCell)}>
                            <span className={styles.itemlabel}>{translate('/Checkout/CartItemList/Uprice')}:&nbsp;</span>
                            {isSample ? <span className={styles.freeSample}>{translate('/Checkout/CartItemList/FreeSample')}</span> : (
                                isDiscount ? (
                                    <div className={styles.prices}>
                                        <div className={classnames(styles.priceWithCurrency, styles.wasPrice)}>
                                            <span>
                                                {translate('/Cart/WasPrice', priceWithCurrency)}
                                            </span>&nbsp;
                                            <span dangerouslySetInnerHTML={{ __html: measurement }} />
                                        </div>
                                        <div
                                            className={classnames(styles.discountPrice, {
                                                [styles.discountPriceUpdating]: isPending,
                                            })}
                                        >
                                            <span>{translate('/Cart/NowPrice', priceFormat.CurrencySymbol + (price - discountAmount / quantity).toFixed(2))}</span>&nbsp;
                                            <span dangerouslySetInnerHTML={{ __html: measurement }} />
                                        </div>
                                    </div>
                                ) : <div className={styles.priceWithCurrency}>
                                    {payDirect ? (displayPrice ?
                                        <span>{priceFormat.CurrencySymbol}{displayPrice.toFixed(2)}&nbsp;</span> :
                                        <span>{priceWithCurrency}&nbsp;</span>) : <span>{priceWithCurrency}&nbsp;</span>}
                                    <span dangerouslySetInnerHTML={{ __html: measurement }} />
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Quantity column */}
                    <div className={`${classnames(styles.itemQuantity, newQuantity > 1 && styles.quantityRow)}
                            col-sm-2`}>
                        <div className={styles.inputWrap}>
                            <span className={styles.itemlabel}>{translate('/Checkout/CartItemList/Quantity')}:&nbsp;</span>
                            {displaySize ? <span>{displaySize}&nbsp;x&nbsp;{quantity}</span> : <span>{quantity}</span>}
                        </div>
                    </div>

                    {/* Price column */}
                    <div className={`${styles.itemPrice} col-sm-2`}>
                        <span className={styles.itemlabel}>{translate('/Checkout/CartItemList/Subtotal')}:&nbsp;</span>
                        <div
                            className={classnames({
                                [styles.updatingSubTotal]: isPending,
                            })}
                        >
                            {isSample
                                ? <span className={styles.freeSample}>{translate('/Checkout/CartItemList/FreeSample')}</span>
                                :
                                payDirect ?
                                    <span className={styles.netAmountPrice}>{priceFormat.CurrencySymbol}{Number((displayPrice ? displayPrice : price ? price.amount : 0) * quantity).toFixed(2)}</span> :
                                    <span className={styles.netAmountPrice}>{priceAfterDiscountWithCurrency}</span>}
                        </div>
                    </div>

                    {/* Fitting note */}
                    {isFitting && <div className={`${styles.itemService} col-sm-2`}>
                        <span>{translate('/ProductPage/RelatedProducts/Fitting/Name')}:&nbsp;</span>
                        <span>{translate('/ProductPage/RelatedProducts/Service/Yes')} <span >({translate('/ProductPage/RelatedProducts/Service/Payable')})</span></span>
                    </div>}

                </div>
            </div>

            {/* { !props.isQuoteCart && !inStock && 
                    <div className={styles.infoBlock}>
                        <LowStockNotification />
                    </div>
                } */}
        </div>
    );
}
