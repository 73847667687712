export const RESET_DATA = "RESET_DATA";
export const GET_LOGIN_FORM = "GET_LOGIN_FORM";
export const DISPLAY_LOGIN_POPUP = "DISPLAY_LOGIN_POPUP";
export const SUCCESS_LOGIN ="SUCCESS_LOGIN";
export const DISPLAY_LOGIN_MODAL = "DISPLAY_LOGIN_MODAL";
export const DISPLAY_ANONYMOUS_LOGIN_MODAL = "DISPLAY_ANONYMOUS_LOGIN_MODAL";
export const HIDE_ANONYMOUS_LOGIN_MODAL = "HIDE_ANONYMOUS_LOGIN_MODAL";
export const GET_PAGES_URL = "GET_PAGES_URL";
export const ONCHANGE_EMAIL_INPUT = "ONCHANGE_EMAIL_INPUT";
export const ONCHANGE_PASSWORD_INPUT = "ONCHANGE_PASSWORD_INPUT";
export const UPDATE_ERROR = "UPDATE_ERROR";