import { FITTING_TYPE_ID, FITTINGADMIN_TYPE_ID, FITTINGMINSKU, FITTINGADMINMINSKU  } from 'Product/Browser/Components/ProductPage/Components/RelatedProducts/Components/Fitting';
import { UPLIFT_TYPE_ID,  DISPOSAL_TYPE_ID, UPLIFTANDDISPOSALMINSKU } from 'Product/Browser/Components/ProductPage/Components/RelatedProducts/Components/Uplift';
import { translate } from 'Shared/translate';

const cart_cache = "miniCart";
const cart_cache_expiry = "miniCartExpiry";
const standard_expiry = 24*60*60*1000; // a days expiry
export const getCartCache = () =>
{
    try {
        var expiresIn = localStorage.getItem(cart_cache_expiry);
        if (expiresIn===undefined || expiresIn===null) { expiresIn = 0; }
        if (expiresIn < Date.now()) {// Expired
            return null;
        } else {
            let cartCache = JSON.parse(localStorage.getItem("miniCart"));
            return cartCache;
        }
    } catch(e) {
        console.log('getStorage: Error reading cart cache from localStorage: ' + JSON.stringify(e) );
        return null;
    }
}
export const setCartCache = (data) =>
{
    var schedule = Date.now() + standard_expiry;
    try {
        localStorage.setItem(cart_cache, data);
        localStorage.setItem(cart_cache_expiry, schedule);
    } catch(e) {
        console.log('setStorage: Error setting cart cache in localStorage: ' + JSON.stringify(e) );
    }
}
export const resetCartCache = () =>
{
    try {
        localStorage.removeItem(cart_cache);
        localStorage.removeItem(cart_cache_expiry);
    } catch(e) {
        console.log('setStorage: Error removing cart cache in localStorage: ' + JSON.stringify(e) );
    }
}

export const ServiceItems = (cartItems) => {
    return cartItems.filter(x => x.isFitting || x.isFittingAdmin || x.isUplift || x.isDisposal);
}

export const localCartServiceItems = (cartItems) => {
    let items = [];
    let fittingItem = cartItems.find(x => x.context == FITTING_TYPE_ID || x.context == FITTINGMINSKU);
    let fittingAdminItem = cartItems.find(x => x.context == FITTINGADMIN_TYPE_ID || x.context == FITTINGADMINMINSKU);
    let upliftItem = cartItems.find(x => x.context == UPLIFT_TYPE_ID || x.context == UPLIFTANDDISPOSALMINSKU);
    if(fittingItem){
        let name = translate('/ProductPage/RelatedProducts/Fitting/Name')
        let fitting = {
            name: name,
            price: fittingItem.price * fittingItem.quantity
        }
        items.push(fitting);
    }
    if (fittingAdminItem) {
        let name = translate('/ProductPage/RelatedProducts/Fitting/ArrangementFee')
        let fittingAdmin = {
            name: name,
            price: fittingAdminItem.price * fittingAdminItem.quantity
        }
        items.push(fittingAdmin);
    }
    if(upliftItem){
        let name = translate('/ProductPage/RelatedProducts/Uplift/Name')
        let uplift = {
            name: name,
            price: upliftItem.price * upliftItem.quantity
        }
        items.push(uplift);
    }
    return items;
}

export const localCartFittingAdminItems = (cartItems) => {
    let fittingAdminItem = cartItems.find(x => x.context == FITTINGADMIN_TYPE_ID || x.context == FITTINGADMINMINSKU);

    if (fittingAdminItem) {
        let name = translate('/ProductPage/RelatedProducts/Fitting/ArrangementFee')
        let fittingAdmin = {
            name: name,
            price: fittingAdminItem.price * fittingAdminItem.quantity
        }
        return fittingAdmin;
    }
    return null;
}

export const localCartFittingItems = (cartItems) => {
    let fittingItem = cartItems.find(x => x.context == FITTING_TYPE_ID || x.context == FITTINGMINSKU);

    if (fittingItem) {
        let name = translate('/ProductPage/RelatedProducts/Fitting/ArrangementFee')
        let fitting = {
            name: name,
            price: fittingItem.price * fittingItem.quantity
        }
        return fitting;
    }
    return null;
}

export const localCartDisposalItems = (cartItems) => {
    let disposalItem = cartItems.find(x => x.context == DISPOSAL_TYPE_ID);
    
    if(disposalItem){
        let name = translate('/ProductPage/RelatedProducts/Disposal/Name')
        let disposal = {
            name: name,
            price: disposalItem.price * disposalItem.quantity
        }
        return disposal;
    }
    return null;
}

const summarize = (cartitems, infoType, name) => {
    let items = [];
    if(cartitems){
        let total = 0;
        cartitems.map((x, i) => {
            total += x[infoType].price;
        })
        let service = {
            name: name,
            price: total
        }
        items.push(service);
    }
    return items;
}

const getCartServiceItems = (cartItems) => {
    let items = [];
    let fittingItems = cartItems.filter(x => x.fittingInfo.exist);
    let fittingAdminItems = cartItems.filter(x => x.fittingAdminInfo.exist);
    let fittingMinSkuItems = cartItems.filter(x => x.fittingMinSkuInfo.exist);
    let fittingAdminMinSkuItems = cartItems.filter(x => x.fittingAdminMinSkuInfo.exist);
    let upliftItems = cartItems.filter(x => x.upliftInfo.exist);
    let upliftAndDisposalMinSkuItems = cartItems.filter(x => x.upliftAndDisposalMinSkuInfo.exist);
    
    items = fittingItems.length > 0 ? items.concat(summarize(fittingItems, 'fittingInfo', translate('/ProductPage/RelatedProducts/Fitting/Name'))) : items;
    items = fittingAdminItems.length > 0 ? items.concat(summarize(fittingAdminItems, 'fittingAdminInfo', translate('/ProductPage/RelatedProducts/Fitting/ArrangementFee'))) : items;
    items = fittingMinSkuItems.length > 0 ? items.concat(summarize(fittingMinSkuItems, 'fittingMinSkuInfo', translate('/ProductPage/RelatedProducts/Fitting/Name'))) : items;
    items = fittingAdminMinSkuItems.length > 0 ? items.concat(summarize(fittingAdminMinSkuItems, 'fittingAdminMinSkuInfo', translate('/ProductPage/RelatedProducts/Fitting/ArrangementFee'))) : items;
    items = upliftItems.length > 0 ? items.concat(summarize(upliftItems, 'upliftInfo', translate('/ProductPage/RelatedProducts/Uplift/Name'))) : items;
    items = upliftAndDisposalMinSkuItems.length > 0 ? items.concat(summarize(upliftAndDisposalMinSkuItems, 'upliftAndDisposalMinSkuInfo', translate('/ProductPage/RelatedProducts/Uplift/Name'))) : items;
    return items;
}

export const miniCartServiceItems = (cartItems) => {
    return( getCartServiceItems(cartItems) );
}

export const checkoutCartServiceItems = (cartItems) => {
    return( getCartServiceItems(cartItems) );
}