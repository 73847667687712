import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { formControl, inputGroup, inputGroupAddon } from './../../Shared/bootstrapclasses';
import { translate } from './../../Shared/translate';
import { toggleOverlay } from './../../Shared/Overlay/actions';
import { toggleMiniCart } from './../../Cart/Browser/actions';
import { Icon } from '../../Shared/icon';
import * as styles from './base.scss';
import classnames from 'classnames';
import { search, updateSearchQuery, reset, toggleQuickSearch } from '../actions';
import { DOWN, UP, ENTER, ESC } from './../../Shared/keyboard';
import { GACustomSearch, GACustomEvent } from 'Shared/GA/helpers';
import { ACTION_SEARCHRESULTS } from 'Shared/GA/constants';
import * as ga4types from 'Shared/GA4/constants';
import { GA4HeaderClick, GA4CustomSearch } from 'Shared/GA4/site-wide';
import Spinner from 'Shared/Spinner';
import { siteTheme, isTheme } from 'Shared/customfunctions';
import ImageUrlHelper from 'Shared/Browser/Services/ImageUrlHelper';

const debounceMs = 300;


function SearchBar(props) {

    const [isSearching, setIsSearching] = useState(false);
    const [selectedRow, setSelectedRow] = useState(-1);
    const [minimize, setMinimize] = useState(props.minimize || false);
    const allowMinimize = props.allowMinimize || false;
    const prevProps = useRef(props);
    const searchForm = useRef();
    const wrapperRef = useRef();
    const searchInput = useRef();
    const currentTimeout = useRef();


    // Alternative for ComponentDidMount
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, { passive: true });
        document.addEventListener('touchstart', handleClickOutside, { passive: true });
        let urlName = window.location.pathname.replace(/\//g, '');
        if (urlName == props.searchurl.replace(/\//g, '')) {
            GA4HeaderClick(ga4types.LABEL_HEADER_SEARCH, ga4types.LABEL_HEADER_LEVEL1)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        }
    }, []);


    // Alternative for ComponentDidUpdate
    useEffect(() => {
        if (minimize != props.minimize) {
            setMinimize(props.minimize);
        }
        if (props.miniCartVisible) {
            return;
        }
        if (prevProps.current.displayQuickSearch && !props.displayQuickSearch) {
            props.reset();
            props.toggleQuickSearch(false);
            if (props.toggleDisplay) {
                props.toggleDisplay();
            }
        }
    }, [props]);


    const handleQuery = (query) => {
        props.toggleMiniCart(false);
        if (query.length > 3) {
            setIsSearching(true);
            moveSelection('cancel');
            props.updateSearchQuery(query);
            clearTimeout(currentTimeout.current);
            currentTimeout.current = setTimeout(() => {
                if (props.searchQuery.length > 0) {
                    props.search(props.quicksearchurl, props.searchQuery)
                        .then((res) => {
                            setIsSearching(false);

                            if (res && res.result && res.result.length > 0) {
                                props.toggleOverlay(true);
                            }
                        });
                }
            }, debounceMs);
        } else if (query.length === 0) {
            props.reset();
            setIsSearching(false);
        } else {
            props.reset();
            props.updateSearchQuery(query);
            setIsSearching(false);
        }
    }

    const onKeyDown = (event) => {
        switch (event.keyCode) {
            case DOWN:
                event.preventDefault();
                moveSelection('next');
                break;
            case UP:
                event.preventDefault();
                moveSelection('prev');
                break;
            case ENTER:
                event.preventDefault();
                if (selectedRow > -1) {
                    const url = props.searchResults[selectedRow].Url;
                    document.location.href = url;
                } else {
                    if (props.searchQuery)
                        GACustomSearch(props.searchQuery);
                    GA4CustomSearch(props.searchQuery);
                    searchForm.current.submit();
                }
                break;
            case ESC:
                event.preventDefault();
                moveSelection('cancel');
                props.reset();
                break;
            default:
                break;
        }
    }

    const moveSelection = (direction) => {
        let newSelectedIndex = -1;
        const collection = props.searchResults;

        newSelectedIndex = getNewSelection(direction, collection, selectedRow);
        if (newSelectedIndex < 0) {
            newSelectedIndex = getNewSelection(direction, collection, newSelectedIndex);
        }
        setSelectedRow(newSelectedIndex);
    }

    const getNewSelection = (direction, list, selectedIndex) => {
        if (direction === 'next') {
            return (selectedIndex + 1 > list.length - 1) ? -1 : selectedIndex + 1;
        } else if (direction === 'cancel') {
            return (selectedIndex = -1);
        } else {
            let newSelectedIndex = -1;
            if (selectedIndex >= 0) {
                newSelectedIndex = selectedIndex - 1;
            } else if (list.length > 0) {
                newSelectedIndex = list.length - 1;
            }
            return newSelectedIndex;
        }
    }

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            if (props.toggleDisplay && !props.displayQuickSearch) {
                props.toggleDisplay();
            }
        } else {
            return;
        }
    }

    const handleFormClick = () => {
        let page = document.getElementById('GenericPageHeader');
        page.scrollIntoView({ behavior: 'smooth' });
    }


    return !minimize ?
        <form method="get" action={props.searchurl} ref={e => searchForm.current = e} className={styles.searchForm}>
            <div
                className={classnames(styles.desktopSearch, props.displayQuickSearch && styles.display)}
                ref={node => wrapperRef.current = node}
            >
                <div className={classnames(inputGroup, styles.inputGroup)}>
                    {isTheme(siteTheme.CARPETRIGHT) &&
                        <button
                            type="submit" aria-label="Search"
                            className={classnames(inputGroupAddon, styles.addonSearch)}
                            onClick={(e) => { e.preventDefault, GA4HeaderClick("Search", ga4types.LABEL_HEADER_LEVEL1) }}
                        >
                            <Icon size={'md-32'} className={'ion-android-search'} />
                        </button>}
                    {isTheme(siteTheme.TFR) &&
                        <button
                            type="submit" aria-label="addonSearch"
                            className={classnames(inputGroupAddon, styles.addonSearch)}
                        >
                            <Icon size={'md-32'} className={'ion-android-search'} />
                        </button>}
                    <input
                        ref={input => searchInput.current = input}
                        type="text"
                        onKeyDown={onKeyDown}
                        className={classnames(formControl, styles.input, styles.borderNone, "searchLink")}
                        id="query"
                        name="query"
                        autoComplete="off"
                        value={props.searchQuery}
                        onChange={(e) => handleQuery(e.target.value)}
                        placeholder={translate('/Header/Search/Placeholder')}
                        autoFocus={allowMinimize}
                        onFocus={() => {
                            window.scrollTo(window.pageYOffset, window.pageYOffset);
                            document.body.scrollTop = window.pageYOffset;
                        }}
                    />
                    <div className={styles.spinner}>
                        {isSearching && <Spinner />}
                    </div>
                </div>
                {props.searchResults && props.searchResults.length > 0 && (
                    <div className={styles.quickSearchResult} id="quickSearchResult">
                        <ul className={styles.list}>
                            {props.searchResults.map((result, index) => {
                                return (
                                    <li
                                        onMouseEnter={evt => setSelectedRow(parseInt(evt.target.parentElement.id, 10))}
                                        id={index}
                                        key={index}
                                        className={classnames(styles.listItem, selectedRow === index && styles.highlighted)}
                                    >
                                        <a
                                            onClick={() => GACustomEvent(ACTION_SEARCHRESULTS, result.displayName)}
                                            href={result.url} className={classnames(styles.link, "searchLink")}>
                                            <img src={result.image ? `${ImageUrlHelper.appendWitdhQueryParam(result.image, 60)}` : window.defaultImageUrl} alt="coming soon" />
                                            <span dangerouslySetInnerHTML={{ __html: result.displayName }}></span>
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        </form>
        :
        <div className={'action'} id='search' onClick={handleFormClick}>
            <Icon size={'md-32'} className={'ion-android-search'} />
            <label>{translate('/Header/Search')}</label>
        </div>
}

export default connect(
    state => ({
        cart: state.cart.data,
        displayQuickSearch: state.searchResults.show,
        searchQuery: state.searchResults.searchQuery,
        searchResults: state.searchResults.searchResults,
        miniCartVisible: state.cart.miniCartVisible,
    }),
    dispatch => ({
        search: (url, query) => dispatch(search(url, query)),
        reset: () => dispatch(reset()),
        toggleQuickSearch: (value) => dispatch(toggleQuickSearch(value)),
        updateSearchQuery: (query) => dispatch(updateSearchQuery(query)),
        toggleOverlay: (display) => dispatch(toggleOverlay(display)),
        toggleMiniCart: (displayMiniCart) => dispatch(toggleMiniCart(displayMiniCart)),
    })
)(SearchBar);
