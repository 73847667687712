import React, { useEffect, useRef, useState } from 'react';
import * as styles from './overlay.scss';
import classnames from 'classnames';
import { toggleOverlay } from './actions';
import { connect } from 'react-redux';
import { toggleQuickSearch } from '../../Search/actions';
import { toggleMiniCart } from '../../Cart/Browser/actions';
import { toggleLogin } from '../../Login/actions';
import { toggleMenu } from 'Shared/Header/actions';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


function Overlay(props) {

    const minicartScroll = document.getElementById('minicartContent');
    const searchScroll = document.getElementById('quickSearchResult');
    const locatorScroll = document.getElementById('locator');
    const loginScroll = document.getElementById('loginPopup');
    const menuScroll = document.getElementById('menuScroll');

    const bodyScrollOptions = {
        reserveScrollBarGap: true
    }

    // Alternative for ComponentDidUpdate
    const prevProps = useRef(props);
    useEffect(() => {
        if (props !== prevProps.current) {
            let activeCtr = 0;
            if (prevProps.current.overlay !== props.overlay) {
                if (props.overlay) {
                    if (props.search) {
                        triggerScroll(searchScroll);
                    } else if (props.minicart) {
                        triggerScroll(minicartScroll);
                    } else if (props.storeLocator) {
                        triggerScroll(locatorScroll);
                    } else if (props.login) {
                        triggerScroll(loginScroll);
                    } else if (props.menu) {
                        triggerScroll(menuScroll);
                    }
                } else {
                    clearAllBodyScrollLocks();
                }
            }
            for (const key in props) {
                if (props.hasOwnProperty(key) && key != "overlay" && props[key] === true) {
                    activeCtr += 1;
                }
            }
            if (activeCtr > 1) {
                toggleOverlay();
            }
        }

        prevProps.current = props;
    }), [props];


    useEffect(() => {
        return () => {
            clearAllBodyScrollLocks();
        }
    }, []);


    const triggerScroll = (component) => {
        disableBodyScroll(component, bodyScrollOptions);
    }

    const toggleOverlay = () => {
        if (props.solid) {
            return;
        }

        props.toggleOverlay(false);

        if (props.search) {
            props.toggleQuickSearch(false);
        }
        if (props.minicart) {
            props.toggleMiniCart(false);
        }
        if (props.storeLocator) {
            props.toggleStoreSearchArea(false);
            props.resetStoreLocatorQuery();
        }
        if (props.login) {
            props.toggleLogin(false);
        }
        if (props.menu) {
            props.toggleMenu(false);
        }
    }


    return (
        <div className={classnames(styles.overlay, {
            [styles.hidden]: !props.overlay,
        })}
            onClick={toggleOverlay}
        />
    );
}

export default connect(
    ({ overlay, searchResults, cart, login, menu }) => ({
        overlay: overlay.active,
        solid: overlay.solid,
        search: searchResults.show,
        minicart: cart.miniCartVisible,
        login: login.visible,
        menu,
    }),
    dispatch => ({
        toggleOverlay: (display) => dispatch(toggleOverlay(display)),
        toggleQuickSearch: (display) => dispatch(toggleQuickSearch(display)),
        toggleMiniCart: (display) => dispatch(toggleMiniCart(display)),
        toggleLogin: (display) => dispatch(toggleLogin(display)),
        toggleMenu: (display) => dispatch(toggleMenu(display)),
    })
)(Overlay);
