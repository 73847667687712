export const BREAKPOINT_UPDATE = 'BREAKPOINT_UPDATE';
export const DISPLAYDIMMED_UPDATE = 'DISPLAYDIMMED_UPDATE';
export const DISPLAYMENU_UPDATE = 'DISPLAYMENU_UPDATE';

export const ACCEPT_COOKIES = 'ACCEPT_COOKIES';

export const SEARCH_PENDING = 'SEARCH_PENDING';
export const SEARCH_FULFILL = 'SEARCH_FULFILL';
export const SEARCH_REJECT = 'SEARCH_REJECT';
export const SEARCH_UPDATE = 'SEARCH_UPDATE';

export const CART_PENDING = 'CART_PENDING';
export const CART_FULFILL = 'CART_FULFILL';
export const CART_REJECT = 'CART_REJECT';
export const CART_UPDATE = 'CART_UPDATE';
export const DISPLAY_MINICART = 'DISPLAY_MINICART';
export const DISPLAY_FULLCART = 'DISPLAY_FULLCART';

export const FACET_EXPAND = 'FACET_EXPAND';
export const FACET_GROUP_EXPAND = 'FACET_GROUP_EXPAND';

export const REGISTER_PENDING = 'REGISTER_PENDING';
export const REGISTER_FULFILL = 'REGISTER_FULFILL';
export const REGISTER_REJECT = 'REGISTER_REJECT';
export const REGISTER_UPDATE = 'REGISTER_UPDATE';

export const PRODUCT_PENDING = 'PRODUCT_PENDING';
export const PRODUCT_FULFILL = 'PRODUCT_FULFILL';
export const PRODUCT_REJECT = 'PRODUCT_REJECT';
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_TRUSTPILOT = 'PRODUCT_TRUSTPILOT';

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_FULFILL = 'LOGIN_FULFILL';
export const LOGIN_REJECT = 'LOGIN_REJECT';
export const LOGIN_UPDATE = 'LOGIN_UPDATE';

export const DELIVERY_PENDING = 'DELIVERY_PENDING';
export const DELIVERY_FULFILL = 'DELIVERY_FULFILL';
export const DELIVERY_REJECT = 'DELIVERY_REJECT';
export const DELIVERY_UPDATE = 'DELIVERY_UPDATE';

export const ADDRESS_PENDING = 'ADDRESS_PENDING';
export const ADDRESS_FULFILL = 'ADDRESS_FULFILL';
export const ADDRESS_REJECT = 'ADDRESS_REJECT';
export const ADDRESS_CUSTOMER_UPDATE = 'ADDRESS_CUSTOMER_UPDATE';
export const ADDRESS_STATES_UPDATE = 'ADDRESS_STATES_UPDATE';
export const ADDRESS_CUSTOMER_INFO_UPDATE = 'ADDRESS_CUSTOMER_INFO_UPDATE';
export const ADDRESS_DELIVERY_CUSTOMER_UPDATE = 'ADDRESS_DELIVERY_CUSTOMER_UPDATE';
export const ADDRESS_CUSTOMER_UPDATE_CONTACT_PERMISSION = 'ADDRESS_CUSTOMER_UPDATE_CONTACT_PERMISSION';
export const ADDRESS_SAME_AS_BILLING = 'ADDRESS_SAME_AS_BILLING';

export const CATEGORYPAGE_UPDATE = 'CATEGORYPAGE_UPDATE';
export const CATEGORYPAGE_UPDATE_PRODUCTLIST = 'CATEGORYPAGE_UPDATE_PRODUCTLIST';

export const ORDER_PENDING = 'ORDER_PENDING';
export const ORDER_FULFILL = 'ORDER_FULFILL';
export const ORDER_REJECT = 'ORDER_REJECT';
export const ORDER_UPDATE = 'ORDER_UPDATE';
export const ORDER_FORM_HAS_ERRORS = 'ORDER_FORM_HAS_ERRORS';

export const AGREED_ON_TERMS = 'AGREED_ON_TERMS';
export const SUBSCRIBE_NEWSLETTER = 'SUBSCRIBE_NEWSLETTER';

export const PROFILEPAGE_CLEAR_MESSAGE = 'PROFILEPAGE_CLEAR_MESSAGE';
export const PROFILEPAGE_PENDING = 'PROFILEPAGE_PENDING';
export const PROFILEPAGE_UPDATING = 'PROFILEPAGE_UPDATING';
export const PROFILEPAGE_UPDATING_FIELD = 'PROFILEPAGE_UPDATING_FIELD';
export const PROFILEPAGE_FIELD_UPDATED = 'PROFILEPAGE_FIELD_UPDATED';
export const PROFILEPAGE_UPDATE_FINISHED = 'PROFILEPAGE_UPDATE_FINISHED';
export const PROFILEPAGE_STATES_UPDATE = 'PROFILEPAGE_STATES_UPDATE';
export const PROFILEPAGE_UPDATE_ADDRESSLIST = 'PROFILEPAGE_UPDATE_ADDRESSLIST';
export const PROFILEPAGE_UPDATE_SELECTED_ADDRESS = 'PROFILEPAGE_UPDATE_SELECTED_ADDRESS';
export const PROFILEPAGE_USER_IS_AUTHENTICATED = 'PROFILEPAGE_USER_IS_AUTHENTICATED';
export const PROFILEPAGE_UPDATE_CONTACTPERMISSIONS = 'PROFILEPAGE_UPDATE_CONTACTPERMISSIONS';

export const NEWSLETTER_PENDING = 'NEWSLETTER_PENDING';
export const NEWSLETTER_SENDING = 'NEWSLETTER_SENDING';
export const NEWSLETTER_SENDING_FINISHED = 'NEWSLETTER_SENDING_FINISHED';

export const WISHLIST_REMOVE_FROM_WISHLIST = 'WISHLIST_REMOVE_FROM_WISHLIST';
export const WISHLIST_REMOVED_FROM_WISHLIST = 'WISHLIST_REMOVED_FROM_WISHLIST';
export const WISHLIST_ADDINGTOCART = 'WISHLIST_ADDINGTOCART';
export const WISHLIST_ADDEDTOCART = 'WISHLIST_ADDEDTOCART';
export const WISHLIST_DISABLE_WISHLIST_BUTTON = 'WISHLIST_DISABLE_WISHLIST_BUTTON';

export const TOGGLE_QUICKSEARCH = 'TOGGLE_QUICKSEARCH';
export const UPDATE_SEACHQUERY = 'UPDATE_SEACHQUERY';

export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING';
export const CHANGE_PASSWORD_FULFILL = 'CHANGE_PASSWORD_FULFILL';
export const CHANGE_PASSWORD_REJECT = 'CHANGE_PASSWORD_REJECT';
export const CHANGE_PASSWORD_UPDATE = 'CHANGE_PASSWORD_UPDATE';
export const CHANGE_PASSWORD_COMPLETED = 'CHANGE_PASSWORD_COMPLETED';

export const SORTAREA_EXPAND = 'SORTAREA_EXPAND';

export const ADYEN_INITIALIZE = 'ADYEN_INITIALIZE';
export const ADYEN_CARD_DETAILS_UPDATE = 'ADYEN_CARD_DETAILS_UPDATE';
export const ADYEN_DROPIN = 'ADYEN_DROPIN';
export const ADYEN_PAYLOAD = 'ADYEN_PAYLOAD';
export const ADYEN_PSP_REFERENCE = 'ADYEN_PSP_REFERENCE';

export const STRIPE_INITIALIZE = 'STRIPE_INITIALIZE';
export const STRIPE_CARDELEMENT = 'STRIPE_CARDELEMENT';

export const SELECTED_PAYMENT_METHOD_UPDATE = 'SELECTED_PAYMENT_METHOD_UPDATE';
export const SELECTED_TERM_HITACHI_UPDATE = 'SELECTED_TERM_HITACHI_UPDATE';
export const AVAILABLE_TERMS_HITACHI_UPDATE = 'AVAILABLE_TERMS_HITACHI_UPDATE';
export const SELECTED_TERM_PAYBYFINANCE_UPDATE = 'SELECTED_TERM_PAYBYFINANCE_UPDATE';
export const AVAILABLE_TERMS_PAYBYFINANCE_UPDATE = 'AVAILABLE_TERMS_PAYBYFINANCE_UPDATE';
export const SELECTED_TERM_V12FINANCE_UPDATE = 'SELECTED_TERM_V12FINANCE_UPDATE';
export const AVAILABLE_TERMS_V12FINANCE_UPDATE = 'AVAILABLE_TERMS_V12FINANCE_UPDATE';
export const PAYMENT_METHODS_UPDATE = 'PAYMENT_METHODS_UPDATE';


export const STORE_LOCATOR_TOGGLE_STORE_SEARCH_AREA = 'STORE_LOCATOR_TOGGLE_STORE_SEARCH_AREA';
export const STORE_LOCATOR_UPDATE_SEARCH_QUERY = 'STORE_LOCATOR_UPDATE_SEARCH_QUERY';
export const STORE_LOCATOR_RESET = 'STORE_LOCATOR_RESET';

export const ORDER_HISTORY_PENDING = 'ORDER_HISTORY_PENDING';
export const ORDER_HISTORY_FULFILL = 'ORDER_HISTORY_FULFILL';
export const ORDER_HISTORY_REJECT = 'ORDER_HISTORY_REJECT';
export const ORDER_HISTORY_UPDATE = 'ORDER_HISTORY_UPDATE';


export const MYQUOTES_PENDING = 'MYQUOTES_PENDING';
export const MYQUOTES_FULFILL = 'MYQUOTES_FULFILL';
export const MYQUOTES_REJECT = 'MYQUOTES_REJECT';
export const MYQUOTES_UPDATE = 'MYQUOTES_UPDATE';
export const MYQUOTES_REDIRECT = 'MYQUOTES_REDIRECT';


  
   

export const DISPLAY_GENERIC_ERROR = 'DISPLAY_GENERIC_ERROR';
export const HIDE_GENERIC_ERROR = 'HIDE_GENERIC_ERROR';



export const CONTACTOPTIONS_UPDATE = 'CONTACTOPTIONS_UPDATE';
export const SHAREMYDATA_UPDATE = 'SHAREMYDATA_UPDATE';