import { DISPLAYDIMMED_UPDATE } from '../action-types';

const initialState = {
    active: false,
    solid: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case DISPLAYDIMMED_UPDATE:
        return Object.assign({}, state, {
            active: action.display,
            solid: action.solid
        });
    default:
        return state;
    }
}
