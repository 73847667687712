import store from '../Shared/store';

// Only invoke the callback if the returned state has changed from the previous invokation.
// It passes the returned state as first argument and the returned state from the
// previous invokation as second argument. Then you have the ability to check diffs by
// compering those objects.
export function invokeWhenUpdated(getCurrentState) {
    let previous = getCurrentState(store.getState());
    return callback => () => {
        const current = getCurrentState(store.getState());
        if (current !== previous) {
            callback(current, previous);
            previous = current;
        }
    };
}

window.store = store;

