import React, { useState, useEffect } from 'react';
import Megamenu from './megamenu';
import * as styles from './megamenu.scss';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { MEDIUM } from 'Shared/breakpoints';
import { Icon } from 'Shared/icon';
import { translate } from 'Shared/translate';
import * as ga4types from 'Shared/GA4/constants';
import { GA4HeaderClick } from 'Shared/GA4/site-wide';
import { siteTheme, isTheme } from 'Shared/customfunctions';

const debounceMs = 300;

const NavMenu = ({ menuIsOpen, breakpoint, handleToggleMenu, handleToggleOverlay, menuItems }) => {
    const [activeItem, setActiveItem] = useState(0);
    const [level, setLevel] = useState(0);
    const [height, setHeight] = useState('initial');
    const [isMobile, setIsMobile] = useState(breakpoint < MEDIUM);
    const [essentialValue, setEssentialValue] = useState(false);
    const [offers, setOffers] = useState(false);
    const [levels, setLevels] = useState(['step1', 'step2', 'step3']);
    let currentTimeout;

    useEffect(() => {
        const newIsMobile = breakpoint < MEDIUM;
        if (isMobile !== newIsMobile) {
            resetLevel();
            setIsMobile(newIsMobile);
            if (newIsMobile) {
                hideMenu();
            } else {
                closeMenu();
            }
        }
    }, [breakpoint]);

    const hideMenu = () => {
        clearTimeout(currentTimeout);
        closeMenu();
    };

    const openMenu = () => {
        if (!menuIsOpen) {
            handleToggleMenu(true);
        }
    };

    const closeMenu = () => {
        if (menuIsOpen) {
            handleToggleMenu(false);
        }
    };

    const isDevice = () => {
        return navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i) ? true : false;
    };

    const changeActiveItem = (e, activeitem, itemHasChildren) => {
        if (!itemHasChildren && !menuIsOpen) {
            handleToggleOverlay(false);
            return;
        }
        if (!itemHasChildren && menuIsOpen) {
            hideMenu();
            return;
        }
        const isDev = isDevice();
        if ((isDev && (!menuIsOpen || activeItem !== activeitem)) || isMobile) {
            e.preventDefault();
            document.activeElement.blur();
        }
        if (!isMobile) {
            clearTimeout(currentTimeout);
            currentTimeout = setTimeout(() => {
                if (activeItem !== activeitem) {
                    setActiveItem(activeitem);
                }
                openMenu();
            }, debounceMs);
        } else {
            setActiveItem(activeitem);
        }
    };

    const nextLevel = (itemcnt) => {
        setLevel(level + 1);
        updateHeight(itemcnt);
    };

    const prevLevel = () => {
        const newlevel = level - 1;
        setLevel(newlevel);
        if (newlevel === 0) {
            handleToggleMenu();
        }
        updateHeight();
    };

    const resetLevel = () => {
        setLevel(0);
        updateHeight();
    };

    const updateHeight = (itemcnt) => {
        const activeitem = menuItems[activeItem];
        const itemheight = 50;
        switch (level) {
            case 0:
                itemcnt = menuItems.length;
                break;
            case 1:
                itemcnt = activeitem.columnList.length;
                break;
        }
        setHeight(breakpoint < MEDIUM ? itemcnt * itemheight : 'auto');
    };

    const itemHasChildren = (index) => {
        return (
            menuItems[index].columnList.length > 0 ||
            menuItems[index].brandList.length > 0 ||
            menuItems[index].campaignList.length > 0
        );
    };

    const isWindowMobile = isMobile;

    const handleClickToggle = (e) => {
        var contentHeader = e.currentTarget;
        var content = e.currentTarget.nextElementSibling;
        contentHeader.classList.toggle([styles.active]);
        if (content) {
            if (content.style.display == 'flex') {
                content.style.display = 'none';
            } else {
                content.style.display = 'flex';
            }
        }
    };

    const handleLiClickToggle = (e) => {
        let essential_valueAccordionMainHeader = document.getElementById('essential_valueAccordionMainHeader');
        let offersAccordionMainHeader = document.getElementById('offersAccordionMainHeader');
        if (e.currentTarget.id == 'essential-value') {
            setEssentialValue(essential_valueAccordionMainHeader.classList.toggle('active'));
        }
        if (e.currentTarget.id == 'offers') {
            setOffers(offersAccordionMainHeader.classList.toggle('active'));
        }
    };

    return (
        <React.Fragment>
            <div id={`navigationwrapper`} className={classnames(styles.navigationwrapper, styles.newMenuOn)}>
                <ul
                    className={classnames(styles.mainMenu, styles[levels[level]], 'mainMenu--loaded', 'mainMenu--content')}
                    {...!isWindowMobile && { onMouseLeave: hideMenu }}
                >
                    {menuItems
                        ? menuItems.map((item, index) => {
                            const hasChildren = itemHasChildren(index);
                            return (
                                <li
                                    onClick={(e) => handleLiClickToggle(e)}
                                    id={item.linkText
                                        .toLowerCase()
                                        .replace(/(?!\w|\s)./g, '')
                                        .replace(/\s+/g, ' ')
                                        .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2')
                                        .replace(/\s/g, '-')}
                                    className={classnames(
                                        {
                                            [styles.menuisactive]: activeItem === index && menuIsOpen,
                                            menuisactive: activeItem === index && menuIsOpen,
                                        },
                                        styles.menuItem
                                    )}
                                    style={{
                                        backgroundColor: item.linkTextBackgroundColor != null && !isWindowMobile ? item.linkTextBackgroundColor : '',
                                        paddingLeft: item.linkTextBackgroundColor != null && !isWindowMobile ? '10px' : '',
                                        paddingRight: item.linkTextBackgroundColor != null && !isWindowMobile ? '10px' : '',
                                        borderRadius: item.linkTextBackgroundColor != null && !isWindowMobile ? '5px' : '',
                                    }}
                                    key={item.linkText + index}
                                    {...!isWindowMobile && { onMouseEnter: (e) => changeActiveItem(e, index, hasChildren) }}
                                >
                                    <div
                                        id={item.linkText
                                            .toLowerCase()
                                            .replace(/(?!\w|\s)./g, ' ')
                                            .replace(/\s+/g, ' ')
                                            .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2')
                                            .replace(/\s/g, '_') + `AccordionMainHeader`}
                                        {...((isWindowMobile || isDevice) && hasChildren && { onClick: handleClickToggle })}
                                        className={classnames('ga_menuitem', 'accordionHeader accordionMainHeader', styles.accordionHeader, styles.menulink, styles.accordionMainHeader, {
                                            [styles.nonexticon]: !hasChildren,
                                            [styles.essentialValueActive]: item.linkText
                                                .toLowerCase()
                                                .replace(/(?!\w|\s)./g, ' ')
                                                .replace(/\s+/g, ' ')
                                                .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2')
                                                .replace(/\s/g, '-') == 'essential-value',
                                            [styles.offersActive]: item.linkText
                                                .toLowerCase()
                                                .replace(/(?!\w|\s)./g, ' ')
                                                .replace(/\s+/g, ' ')
                                                .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2')
                                                .replace(/\s/g, '-') == 'offers',
                                        })}
                                    >
                                        {!isWindowMobile && (
                                            <a
                                                href={item.linkUrl || item.allLinkUrl}
                                                style={{ color: item.linkTextColor != null ? item.linkTextColor : '' }}
                                                onClick={(e) => {
                                                    GA4HeaderClick(item.linkText, ga4types.LABEL_HEADER_LEVEL2);
                                                }}
                                                className={classnames('ga_menuitem', styles.menulink, {
                                                    [styles.nonexticon]: !hasChildren,
                                                })}
                                            >
                                                {item.linkText}
                                            </a>
                                        )}
                                        {isWindowMobile && hasChildren && (
                                            <p onClick={(e) => GA4HeaderClick(item.linkText, ga4types.LABEL_HEADER_LEVEL2)} style={{ marginBottom: '0px' }}>
                                                {item.linkText}
                                            </p>
                                        )}
                                        {isWindowMobile && !hasChildren && (
                                            <a
                                                href={item.linkUrl || item.allLinkUrl}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    GA4HeaderClick(item.linkText, ga4types.LABEL_HEADER_LEVEL2);
                                                }}
                                                style={{ marginBottom: '0px', width: '100%' }}
                                            >
                                                {item.linkText}
                                            </a>
                                        )}
                                    </div>
                                    {hasChildren && (activeItem === index || isMobile) && (
                                        <div className={styles.bottomarea}>
                                            <Megamenu
                                                menuItem={menuItems[index]}
                                                linkText={item.linkText}
                                                nextLevel={(itemcnt) => nextLevel(itemcnt)}
                                                prevLevel={prevLevel}
                                                breakpoint={breakpoint}
                                                essentialValue={essentialValue}
                                                offers={offers}
                                            />
                                        </div>
                                    )}
                                </li>
                            );
                        })
                        : 'There are no items in the menu...'}
                </ul>
            </div>
        </React.Fragment>
    );
};

export default connect((state) => ({ breakpoint: state.breakpoint }), null)(NavMenu);