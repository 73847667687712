import {
    COMPLAINTSFORM_PENDING,
    COMPLAINTSFORM_FULFILL,
    COMPLAINTSFORM_ERROR,

} from './action-types';
const initialState = {
    success: false,
    status: 'FULFILL',
    links: window.COMPLAINTSFORM && window.COMPLAINTSFORM.links,
    blockData: window.COMPLAINTSFORM && window.COMPLAINTSFORM.blockData,
    text: window.COMPLAINTSFORM && window.COMPLAINTSFORM.text,
    message: ''
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case COMPLAINTSFORM_PENDING: {
            return Object.assign({}, state, {
                status: 'PENDING',
            });
        }
        case COMPLAINTSFORM_ERROR: {
            return Object.assign({}, state, {
                status: 'ERROR',
                message: action.data
            });
        }
        case COMPLAINTSFORM_FULFILL: {
            return Object.assign({}, state, {
                status: 'FULFILL',
                success: action.success,
                message: action.message
            });
        }
        default:
            return state;
    }
}
