import {
    LOGIN_PENDING,
    LOGIN_FULFILL,
    LOGIN_REJECT,
    LOGIN_UPDATE,
    DISPLAYDIMMED_UPDATE,
    PROFILEPAGE_USER_IS_AUTHENTICATED,
} from 'Shared/action-types';

import {
    DISPLAY_LOGIN_POPUP,
    SUCCESS_LOGIN,
    DISPLAY_LOGIN_MODAL,
    GET_PAGES_URL,
    ONCHANGE_EMAIL_INPUT,
    ONCHANGE_PASSWORD_INPUT,
    UPDATE_ERROR,
    DISPLAY_ANONYMOUS_LOGIN_MODAL,
} from './action-types';


import { post, json, text, getJson, postJson, get } from '../Shared/server';
import { translate } from '../Shared/translate';
import { getAllWishList } from '../WishList/ProductPage/actions';
import { resetCartCache } from '../Cart/Browser/Components/cartHelper';

export function login(url, body) {
    body = {
        'FormModel.email': body["MainArea.FormModel.Email"],
        'FormModel.password': body["MainArea.FormModel.Password"],
        'FormModel.returnurl': body["MainArea.FormModel.ReturnUrl"],
        '__RequestVerificationToken': body["__RequestVerificationToken"]
    };
    return dispatch => {
        dispatch({ type: LOGIN_PENDING });

        const loginButton = document.querySelector(".jsLoginButton");
        loginButton.disabled = true;
        loginButton.classList.add('loadingSpinner');

        postJson(url, body)
            .then(
                data => {
                    if (!data.loginSuccess) {
                        dispatch({
                            type: UPDATE_ERROR,
                            error: true,
                            isMigratedUser: data.isMigratedUser,
                            message: data.message,
                            invalid: data.invalid
                        });

                        var msg = data.isMigratedUser ? translate("/Login/Form/Error/MigratedUser") : data.invalid ? translate("/Login/Form/Error/WrongPasswordOrEmail") : data.message

                        document.getElementById('errorMessage').innerHTML = msg;
                        document.getElementById('errorHider').classList.add('displayMessage')

                        loginButton.classList.remove('loadingSpinner');
                        loginButton.disabled = false;
                    } else if (data.formModel && data.formModel.returnUrl) {
                        dispatch({
                            type: PROFILEPAGE_USER_IS_AUTHENTICATED,
                            isAuthenticated: data.loginSuccess,
                        });
                        document.location = data.formModel.returnUrl;
                    }
                },
                () => dispatch({ type: LOGIN_REJECT })
            )
    };
}

export const ajaxLogin = (url, body, isCheckout, isRegistration, isNewsLetter) => {
    return (dispatch, getState) => {
        dispatch({ type: LOGIN_PENDING });

        postJson(url, body).then(data => {
            if (data.loginSuccess) {
                dispatch({
                    type: PROFILEPAGE_USER_IS_AUTHENTICATED,
                    isAuthenticated: data.loginSuccess,
                    successLogin: localStorage.setItem('successLogin', 'true')
                });

                const isPopupVisible = getState().login.visible;
                const isOpenLoginModal = getState().login.isOpenLoginModal;
                getAllWishList().then((res) => {
                    if (res.success && res.products.length > 0 && !isNewsLetter) {
                        localStorage.setItem('_wishList', JSON.stringify(res.products));
                        location.reload();
                    }
                    else if (res.success && !isNewsLetter) {
                        location.reload();
                    }
                });

                window.location.replace("/my-pages/profile");
                if (data.contactCustomerSupportPageUrl) {
                    location.href = data.contactCustomerSupportPageUrl;
                }
                if(isNewsLetter){
                    window.location.href = data.contactPreferencePageUrl;
                }
                else if (isRegistration) {
                    location.href = window.location.href;
                }
                else if (isCheckout) {
                    location.reload();
                } else {
                    dispatch({ type: LOGIN_FULFILL });

                    dispatch({
                        type: SUCCESS_LOGIN,
                        success: data.loginSuccess,
                        isAnonymousLogin: false,
                        message: data.message
                    });

                    if (isPopupVisible) {
                        dispatch({
                            type: DISPLAY_LOGIN_POPUP,
                            visible: !isPopupVisible,
                        });

                        dispatch({
                            type: DISPLAYDIMMED_UPDATE,
                            display: false,
                        });
                    }

                    if (isOpenLoginModal) {
                        dispatch({
                            type: DISPLAY_LOGIN_MODAL,
                            isOpen: !isOpenLoginModal,
                        });
                    }

                }
            } else {
                dispatch({
                    type: UPDATE_ERROR,
                    error: true,
                    isMigratedUser: data.isMigratedUser,
                    message: data.message,
                    successLogin: localStorage.setItem('successLogin', 'false'),
                    invalid: data.invalid
                });
            }
        },

            () => dispatch({ type: LOGIN_REJECT })
        )
    };
}


export const toggleLogin = (visible) => {
    return (dispatch) => {
        dispatch({
            type: DISPLAY_LOGIN_POPUP,
            visible,
        });
    };
}

export const toggleLoginModal = (isOpen, isCheckout) => {
    return dispatch => {
        dispatch({
            type: DISPLAY_LOGIN_MODAL,
            isOpen,
            isCheckout,
        });
    }
}

export const logout = () => {
    return dispatch => {
        get('/login/signout', true).then(() => {
            var wish_list = localStorage.getItem('_wishList');
            var wishList = wish_list !== 'undefined' && wish_list !== null ? JSON.parse(wish_list) : null;
            if (wishList) {
                localStorage.removeItem('_wishList');
            }
            resetCartCache();
            dispatch({
                type: PROFILEPAGE_USER_IS_AUTHENTICATED,
                isAuthenticated: false,
            });

            dispatch({
                type: SUCCESS_LOGIN,
                success: false,
            });
        })
    }
}

export const getPagesUrl = () => {
    return (dispatch, getState) => {
        const { forgotPasswordPageUrl, registrationPageUrl, accountPageUrl } = getState().login;
        if (!forgotPasswordPageUrl && !registrationPageUrl && !accountPageUrl) {
            getJson('/loginBlock/GetLoginPagesAndNavigation', true).then(data => {
                dispatch({
                    type: GET_PAGES_URL,
                    forgotPasswordPageUrl: data.forgotPasswordPageUrl,
                    registrationPageUrl: data.registrationPageUrl,
                    accountPageUrl: data.accountPageUrl,
                    navigation: data.navigation,
                })
            })
        }
    }
}

export const changeEmail = email => ({ type: ONCHANGE_EMAIL_INPUT, email });
export const changePassword = password => ({ type: ONCHANGE_PASSWORD_INPUT, password });

// Used in - QuoteSearch, Register, Appointments, Newsletter, Checkout
export function checkIfEmailExist(email, checkout, newsletter) {
    const data = { Email: email };
    return dispatch => {
        dispatch({
            type: DISPLAY_ANONYMOUS_LOGIN_MODAL,
            showSpinner: true   // Used in QuoteSearch only
        });

        return new Promise((resolve, reject) => {
            postJson('/Login/CheckIfEmailExist', data).then(response => {
                // If error is encountered
                if (response.error != "") {
                    dispatch({
                        type: DISPLAY_ANONYMOUS_LOGIN_MODAL,
                        isOpen: false,
                        errorMsg: response.error,   // Used in QuoteSearch only
                        showSpinner: false
                    });
                }
                // If duplicate customer found, redirect url is returned
                else if (response.emailExists && response.redirectUrl != "") {
                    window.location.href = response.redirectUrl;
                }
                // Show login modal
                else if (response.emailExists && !response.newLinkSent) {
                    dispatch({
                        type: DISPLAY_ANONYMOUS_LOGIN_MODAL,
                        isOpen: true,
                        isCheckout: checkout,
                        isAnonymousLogin: true,
                        isMigratedUser: false,
                        isNewsLetter: newsletter,
                        showSpinner: false
                    });
                }
                // show popup that new recover link sent
                else if (response.emailExists && response.newLinkSent) {
                    dispatch({
                        type: DISPLAY_ANONYMOUS_LOGIN_MODAL,
                        isOpen: true,
                        isCheckout: checkout,
                        isAnonymousLogin: true,
                        isMigratedUser: true,
                        showSpinner: false
                    });
                }
                // Show link to register page
                else if (!response.emailExists && !response.newLinkSent) {
                    dispatch({
                        type: DISPLAY_ANONYMOUS_LOGIN_MODAL,
                        isOpen: false,
                        isCheckout: checkout,
                        isAnonymousLogin: false,
                        showRegisterMsg: true,   // Used in QuoteSearch only
                        showSpinner: false
                    });
                }
                resolve(response);
            })
        })

    }
}

export const RecoverAccept = () => {
    return (dispatch) => {
        dispatch({ type: LOGIN_PENDING });
        window.location.href = "/";
    }
}