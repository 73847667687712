import {
    SELECTED_PAYMENT_METHOD_UPDATE,
    SELECTED_TERM_HITACHI_UPDATE,
    AVAILABLE_TERMS_HITACHI_UPDATE,
    SELECTED_TERM_PAYBYFINANCE_UPDATE,
    AVAILABLE_TERMS_PAYBYFINANCE_UPDATE,
    SELECTED_TERM_V12FINANCE_UPDATE,
    AVAILABLE_TERMS_V12FINANCE_UPDATE,
    PAYMENT_METHODS_UPDATE,
} from '../../../../../../Shared/action-types';


export function updateSelectedMethod(method) {
    return {
        type: SELECTED_PAYMENT_METHOD_UPDATE,
        data: method,
    }
}

export function updateInterestFreeTerm(term) {
    return {
        type: SELECTED_TERM_PAYBYFINANCE_UPDATE,
        data: term,
    };
}

export function updateTerms(terms) {
    return {
        type: AVAILABLE_TERMS_HITACHI_UPDATE,
        data: terms,
    }
}

export function updatePaybyFinanceInterestFreeTerm(term) {
    return {
        type: SELECTED_TERM_PAYBYFINANCE_UPDATE,
        data: term,
    };
}

export function updatePaybyFinanceTerms(terms) {
    return {
        type: AVAILABLE_TERMS_PAYBYFINANCE_UPDATE,
        data: terms,
    }
}

export function updateV12FinanceInterestFreeTerm(term) {
    return {
        type: SELECTED_TERM_V12FINANCE_UPDATE,
        data: term,
    };
}

export function updateV12FinanceTerms(terms) {
    return {
        type: AVAILABLE_TERMS_V12FINANCE_UPDATE,
        data: terms,
    }
}

export function updatePaymentMethods(methods) {
    return {
        type: PAYMENT_METHODS_UPDATE,
        data: methods,
    }
}